<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Breadcrumbs main="Summary" title="default" />

  <div class="container-fluid p-0">
    <div class="row widget-grid">
      <Filter
        :stateProp="stateList"
        @filterHandle="filterHandle"
        :hasCustomDate="true"
        :hasCustomer="false"
        :hasPeriod="true"
        :periodNameProp="'2023 MP'"
      />
      <el-row class="m-0 width-100-p mt-3">
        <el-col class="d-flex align-item-center px-2" :span="24">
          <div class="row m-0 width-100-p">
            <div
              class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
            >
              <el-col :span="24">
                <div class="row m-0">
                  <el-col
                    class="pl-0"
                    :span="$vuetify.display.width >= 450 ? 14 : 24"
                    :class="
                      $vuetify.display.width < 450
                        ? 'x-center p-0 order-3 mt-2'
                        : ''
                    "
                  >
                    <el-input
                      size="large"
                      v-model="search"
                      placeholder="Type to search"
                      :prefix-icon="Search"
                      class="table-search"
                    />
                  </el-col>
                  <el-col
                    class="p-0"
                    :span="$vuetify.display.width >= 450 ? 10 : 24"
                    :class="
                      $vuetify.display.width < 450
                        ? 'x-center pr-1 order-1 width-100-p'
                        : 'd-flex justify-content-end'
                    "
                  >
                    <el-button
                      @click="handleExportFile"
                      color="#0D3937"
                      class="height-40 border-color-light rounded-3 mr-1"
                    >
                      <i class="text-white fa fa-cloud-download"></i>
                    </el-button>
                    <el-button
                      @click.stop="handleReload"
                      color="#ddf0ac"
                      class="height-40 rounded-3 ml-1"
                      :class="$vuetify.display.width < 450 ? 'width-100-p' : ''"
                    >
                      <svg class="stroke-icon width-20 height-20">
                        <use
                          href="@/assets/svg/icon-sprite.svg#stroke-refresh"
                        ></use>
                      </svg>
                      <span class="me-primary pl-3"> Apply </span>
                    </el-button>
                  </el-col>
                </div>
              </el-col>
              <DataTable
                :dataProp="dataList"
                :loadingProp="loading"
                :totalRowProp="total_items"
                @tableAction="handleTableAction"
              />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import UserService from "../../../services/user.service";
import DataTable from "./dataTable.vue";
import Filter from "../common/filter.vue";
import XLSX from "xlsx";

export default {
  props: {},
  components: {
    DataTable,
    Filter,
  },
  watch: {},
  computed: {
    stateList() {
      return this.states;
    },
  },
  data() {
    return {
      current_page: 1,
      perPageCount: 50,
      total_items: 100,
      search: "",
      dataList: [],
      loading: false,
      stateFilter: null,
      filter: {
        customer_id: null,
        startDate: "2023-01-01",
        endDate: "2023-12-31",
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let body = {
        customer_id: this.filter.customer_id,
        start_date: this.filter.startDate,
        end_date: this.filter.endDate,
        search: this.search,
      };
      this.loading = true;
      UserService.GetAllInOne(body, this.current_page, this.perPageCount)
        .then(
          (response) => {
            this.dataList = response.data.data;
            this.total_items = response.data.total_record;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    handleReload() {
      this.fetchData();
    },
    handleTableAction(event) {
      if (event.kind == "changePage") {
        this.current_page = event.index;
        this.handleReload();
      } else if (event.kind == "perPageChange") {
        this.current_page = 1;
        this.perPageCount = event.index;
        this.handleReload();
      }
    },
    async handleExportFile() {
      this.excelDataList = this.dataList;
      if (this.excelDataList.length > 0) {
        const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
          header: Object.keys(this.excelDataList[0]),
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "SummaryList.xlsx");
      }
    },
    filterHandle(event) {
      this.filter = event;
      this.fetchData();
    },
  },
};
</script>

<style></style>
