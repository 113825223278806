<!-- eslint-disable vue/no-unused-vars -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-fluid">
    <Breadcrumbs main="Participants" :path="breadcrumbPath" />
    <el-row class="m-0 width-100-p mt-3">
      <el-col class="d-flex align-item-center px-2" :span="24">
        <div class="row m-0 width-100-p px-2">
          <div
            class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
          >
            <el-col class="mb-2" :span="24">
              <div class="row m-0">
                <el-col
                  v-if="$vuetify.display.width < 700"
                  :span="24"
                  class="d-flex align-item-center"
                >
                  <el-button
                    color="#ddf0ac"
                    @click.stop="handleReload"
                    size="small"
                    class="height-40 rounded-2 m-0 ml-2"
                    :class="
                      $store.state.auth.user != null &&
                      !$store.state.auth.user.is_auditor
                        ? 'width-70-p'
                        : 'width-40-p'
                    "
                  >
                    <i class="fa fa-refresh pr-2"></i>
                    <span class="me-primary"> Apply </span>
                  </el-button>
                  <el-button
                    v-if="
                      $store.state.auth.user != null &&
                      !$store.state.auth.user.is_auditor
                    "
                    color="#ddf0ac"
                    size="small"
                    @click.stop="handleNew"
                    class="height-40 width-40-p rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-plus pr-2 me-primary"></i>
                    <span class="me-primary"> New </span>
                  </el-button>
                  <el-button
                    class="height-40 rounded-2 m-0 ml-2"
                    :class="
                      $store.state.auth.user != null &&
                      !$store.state.auth.user.is_auditor
                        ? 'width-30-p'
                        : 'width-20-p'
                    "
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
                <el-col
                  v-if="$vuetify.display.width < 700"
                  :span="24"
                  class="d-flex align-item-center mt-3"
                >
                  <el-input
                    size="large"
                    v-model="search"
                    placeholder="Type to search by site name, charger ID, port ..."
                    :prefix-icon="Search"
                    class="table-search"
                  />
                </el-col>
                <el-col
                  v-if="$vuetify.display.width >= 700"
                  :span="$vuetify.display.width >= 1200 ? 14 : 24"
                  class="d-flex align-item-center"
                  :class="
                    $vuetify.display.width < 750
                      ? 'x-center mt-2'
                      : 'justify-start'
                  "
                >
                  <el-input
                    size="large"
                    v-model="search"
                    placeholder="Type to search by site name, charger ID, port ..."
                    :prefix-icon="Search"
                    class="table-search"
                    :class="$vuetify.display.width >= 1200 ? 'width-400' : ''"
                  />
                  <el-button
                    color="#ddf0ac"
                    @click.stop="handleReload"
                    size="small"
                    class="height-40 rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-refresh pr-2"></i>
                    <span class="me-primary"> Apply </span>
                  </el-button>
                  <el-button
                    v-if="
                      $vuetify.display.width < 1200 &&
                      $store.state.auth.user != null &&
                      !$store.state.auth.user.is_auditor
                    "
                    color="#ddf0ac"
                    size="small"
                    @click.stop="handleNew"
                    class="height-40 rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-plus pr-2 me-primary"></i>
                    <span class="me-primary"> New </span>
                  </el-button>
                  <el-button
                    v-if="$vuetify.display.width < 1200"
                    class="height-40 rounded-2 m-0 ml-2"
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
                <el-col
                  :span="10"
                  v-if="$vuetify.display.width >= 1200"
                  class="d-flex align-item-center justify-end"
                >
                  <el-button
                    v-if="
                      $store.state.auth.user != null &&
                      !$store.state.auth.user.is_auditor
                    "
                    color="#ddf0ac"
                    size="small"
                    @click.stop="handleNew"
                    class="height-40 rounded-2 m-0"
                  >
                    <i class="fa fa-plus pr-2 me-primary"></i>
                    <span class="me-primary"> New </span>
                  </el-button>
                  <el-button
                    class="height-40 rounded-2 m-0 ml-2"
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
              </div>
            </el-col>

            <DataTable
              :loadingProp="loading"
              :usersDataProp="filter_participant"
              :totalRowProp="total_items"
              @tableAction="handleTableAction"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <ShowDetail
      :open_modal="show_modal"
      :data_modal="data_modal"
      @close_modal="close_modal"
      @confirm_modal="confirm_modal"
    />
  </div>
</template>

<script>
import Breadcrumbs from "../../components/bread_crumbs.vue";
import XLSX from "xlsx";
import UserService from "../../services/user.service";
import DataTable from "./dataTable.vue";

// import Filter from "./filter.vue";
import ShowDetail from "./dialogDetail.vue";
import { ElMessageBox } from "element-plus";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "participants",
  components: {
    Breadcrumbs,
    // Filter,
    ShowDetail,
    DataTable,
  },
  data() {
    return {
      breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "Participants",
          disabled: false,
          href: "/participants",
          active: true,
        },
      ],

      show: true,
      participantDataList: [],
      search: "",
      current_page: 1,
      page_size: 50,
      total_items: 100,
      perPageCount: 50,
      loading: false,
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      filterOpen: false,
    };
  },
  watch: {
    // current_page: function (newVal) {
    //   this.fetchParticipantData(newVal);
    // },
  },
  computed: {
    filter_participant() {
      let list = this.participantDataList;
      // let list = this.participantDataList.filter((item) => {
      //   return (
      //     (item.duid === undefined ? "" : item.duid)
      //       .toLowerCase()
      //       .indexOf(this.search.toLowerCase()) > -1 ||
      //     (item.gensetid === undefined ? "" : item.gensetid)
      //       .toLowerCase()
      //       .indexOf(this.search.toLowerCase()) > -1 ||
      //     (item.station_name === undefined ? "" : item.station_name)
      //       .toLowerCase()
      //       .indexOf(this.search.toLowerCase()) > -1 ||
      //     (item.participant === undefined ? "" : item.participant)
      //       .toLowerCase()
      //       .indexOf(this.search.toLowerCase()) > -1 ||
      //     (item.region === undefined ? "" : item.region)
      //       .toLowerCase()
      //       .indexOf(this.search.toLowerCase()) > -1 ||
      //     (item.dispatch_type === undefined ? "" : item.dispatch_type)
      //       .toLowerCase()
      //       .indexOf(this.search.toLowerCase()) > -1 ||
      //     (item.dispatch_type === undefined ? "" : item.dispatch_type)
      //       .toLowerCase()
      //       .indexOf(this.search.toLowerCase()) > -1 ||
      //     (item.fuel_source_primary === undefined
      //       ? ""
      //       : item.fuel_source_primary
      //     )
      //       .toLowerCase()
      //       .indexOf(this.search.toLowerCase()) > -1 ||
      //     (item.technology_type_descriptor === undefined
      //       ? ""
      //       : item.technology_type_descriptor
      //     )
      //       .toLowerCase()
      //       .indexOf(this.search.toLowerCase()) > -1
      //   );
      // });
      //// eslint-disable-next-line vue/no-side-effects-in-computed-properties
      ////this.total_items = list.length;
      //// let paginated = this.paginate(list, this.page_size, this.current_page);
      return list;
    },
  },
  mounted() {
    this.fetchParticipantData(this.current_page, this.search);
  },
  methods: {
    changePage(page) {
      console.log(page);
      this.fetchParticipantData(page, this.search);
    },
    fetchParticipantData(page, search) {
      UserService.getParticipants(page, search, this.perPageCount)
        .then(
          (response) => {
            //console.log(typeof response.data);
            this.total_items = response.data.total_record;
            this.participantDataList = response.data.participants;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    DeleteParticipant(user, index) {
      UserService.deleteParticipant(user.id)
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.participantDataList.splice(index, 1);
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // eslint-disable-next-line no-unused-vars
    handleRowClick(row, column, event) {
      // console.log(row, column, event);
    },
    // eslint-disable-next-line no-unused-vars
    close_modal(event) {
      this.show_modal = false;
    },

    confirm_modal(event) {
      if (this.participantDataList.length == this.current_row_index)
        this.participantDataList.push(event);
      else this.participantDataList[this.current_row_index] = event;
      this.show_modal = false;
    },

    handleShow(index, data) {
      this.current_row_index = index;
      this.data_modal = JSON.stringify(data);
      this.show_modal = true;
    },

    handleExportFile() {
      if (this.filter_participant.length > 0) {
        const myHeader = [
          "country",
          "dispatch_int",
          "charger_type",
          "fuel_emission_intensity",
          "afec",
          "mpg",
          "charger_efficiency",
          "tech_improvement_rate",
          "fuel_energy_density_MJ_gal",
          "fuel_energy_density_MJ_kWh",
        ];
        const ws = XLSX.utils.json_to_sheet(this.filter_participant, {
          header: myHeader,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "ParticipantList.xlsx");
      }
    },

    reset_filter(event) {
      this.current_page = 1;
      this.search = "";
      this.filter = event.filter;
    },

    handleTableAction(event) {
      if (event.kind == "edit") {
        this.handleShow(event.index, event.data);
      } else if (event.kind == "delete") {
        this.deleteRow(event.data, event.index);
      } else if (event.kind == "changePage") {
        this.current_page = event.index;
        this.fetchParticipantData(this.current_page, this.search);
      } else if (event.kind == "perPageChange") {
        this.current_page = 1;
        this.perPageCount = event.index;
        this.handleReload();
      }
    },
    handleReload() {
      this.fetchParticipantData(this.current_page, this.search);
    },
    handleNew() {
      let data = {
        first_name: "",
        last_name: "",
        email: "",
        is_staff: false,
        is_superuser: false,
        is_active: true,
        participant_id: null,
        id: 0,
      };
      this.current_row_index = this.participantDataList.length;
      this.data_modal = JSON.stringify(data);
      this.show_modal = true;
    },
    filterSearch(event) {
      this.search = event.search;
      this.fetchParticipantData(1, this.search);
    },

    deleteRow(event, index) {
      console.log(event, index);
      ElMessageBox.confirm("Are you sure to delete this?", "info", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "Confirm Delete",
      })
        .then(() => {
          this.DeleteParticipant(event, index);
          // ElMessage({
          //   type: "success",
          //   message: "Delete completed",
          // });
        })
        .catch(() => {
          // ElMessage({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
  },
};
</script>

<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-8);
}
.el-table .error-row {
  --el-table-tr-bg-color: var(--el-color-error-light-8);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.cell {
  text-align: center !important;
}

.starter-main .card-body ul {
  list-style-type: none !important;
}

.el-pager {
  -webkit-user-select: none !important;
  user-select: none !important;
  list-style: none !important;
  font-size: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}

.el-upload-list {
  margin: 10px 0 0 !important;
  padding: 0 !important;
  list-style: none !important;
  position: relative !important;
}
</style>
