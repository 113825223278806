export const chargerPie = {
    series: [],
    chartOptions: {
        chart: {
            type: 'pie',
        },
        labels: [],
        legend: {
            show: true,
            position: 'right',
            horizontalAlign: 'center',
            labels: {
                colors: '#ffffff', // Set the legend text color to white
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
            }
        }],
    },
}
export const chargerDonut = {
    series: [],
    chartOptions: {
        chart: {
            type: 'donut',
        },
        labels: [],
        legend: {
            show: true,
            position: 'right',
            horizontalAlign: 'center',
            labels: {
                colors: '#ffffff', // Set the legend text color to white
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
            }
        }]
    },
}

export const locationBarChart = {
    series: [{
        name: 'Charger Count',
        type: 'column',
        data: [],
        color: "#DDF0AC",
    }, ],
    chartOptions: {
        chart: {
            type: 'line',
            stacked: false,
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            width: [0, 4],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        labels: [],
        markers: {
            size: 0
        },
        xaxis: {
            title: {
                text: "Charger by location",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                rotate: -45,
                rotateAlways: false,
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            title: {
                text: "Number Of Chargers",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        legend: {
            position: "top",
            labels: {
                colors: "#FFFFFF",
            }
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }
        }
    },
}




export const capacityChart = {
    series: [{
        name: 'Charger Count',
        type: 'column',
        data: [],
        color: "#DDF0AC",
    }, ],
    chartOptions: {
        chart: {
            type: 'line',
            stacked: false,
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            width: [0, 4],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        labels: [],
        markers: {
            size: 0
        },
        xaxis: {
            title: {
                text: "Charger Capacity (kW)",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            title: {
                text: "Number Of Chargers",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        legend: {
            position: "top",
            labels: {
                colors: "#FFFFFF",
            }
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }
        }
    },
}