<!-- eslint-disable vue/no-unused-vars -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-fluid">
    <Breadcrumbs main="Supports" :path="breadcrumbPath" />

    <NewMessage @filterAction="filterAction" />
    <el-row class="m-0 px-0 width-100-p mt-3">
      <el-col class="d-flex align-item-center px-0" :span="24">
        <div class="row m-0 width-100-p px-0">
          <div
            class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
          >
            <Filter @filterAction="filterAction" @filterSearch="filterSearch" />

            <DataTable
              :loadingProp="loading"
              :usersDataProp="filter_support"
              :totalRowProp="total_items"
              @tableAction="handleTableAction"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Breadcrumbs from "../../components/bread_crumbs.vue";
import XLSX from "xlsx";
import UserService from "../../services/user.service";
import Filter from "./filter.vue";
import moment from "moment";
import DataTable from "./dataTable.vue";
import NewMessage from "./new.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "tickets",
  components: {
    Breadcrumbs,
    Filter,
    DataTable,
    NewMessage,
  },
  data() {
    return {
      breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "Supports",
          disabled: false,
          href: "/tickets",
          active: true,
        },
      ],
      show: true,
      supportDataList: [],
      search: "",
      current_page: 1,
      page_size: 50,
      total_items: 100,
      loading: false,
      register_serial_id: "",
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      fromDate: "",
      toDate: "",
      statusRow: 0,
      filterOpen: false,
      filter: {
        fromDate: "",
        toDate: "",
        statusRow: 0,
        search: "",
      },
    };
  },
  watch: {},
  computed: {
    filter_support() {
      return this.supportDataList;
    },
  },
  mounted() {
    this.fetchSupportData(this.current_page);
  },
  methods: {
    changePage(page) {
      this.fetchSupportData(page);
    },
    fetchSupportData(page) {
      this.filter.fromDate = this.fromDate;
      this.filter.toDate = this.toDate;
      this.filter.statusRow = this.statusRow;
      this.filter.search = this.search;
      UserService.getCustomerSupport(null, this.filter, page)
        .then(
          (response) => {
            this.supportDataList = response.data.supports;
            this.total_items = response.data.total_record;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    handleRowClick(row, column, event) {
      console.log(row, column, event);
    },
    isValidDate(dateString) {
      const dateRegex = /^(\d{4})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
      if (dateRegex.test(dateString)) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    formattedDate(date) {
      return moment(date).format("YYYY/MM/DD HH:mm:ss");
    },
    maxDate(arr) {
      let maxDate = new Date(
        Math.max(
          ...arr.map((element) => {
            return new Date(element.created_at);
          })
        )
      );
      return maxDate;
    },
    // eslint-disable-next-line no-unused-vars
    close_modal(event) {
      this.show_modal = false;
    },

    confirm_modal(event) {
      this.supportDataList[this.current_row_index] = event;
      this.show_modal = false;
    },

    handleShow(index, data) {
      this.current_row_index = index;
      this.data_modal = JSON.stringify(data);
      this.$router.replace({
        path: "/ticket_details",
        // query: { supportData: this.data_modal },
        query: { support_id: data.id },
      });
    },

    current_change_click() {},

    paginate(array, page_size, page_number) {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },

    handleExportFile() {
      if (this.filter_support.length > 0) {
        const myHeader = [
          "subject",
          "support_date",
          "support_time",
          "status_id",
          "last_follow_up",
          "conversations_count",
        ];
        const ws = XLSX.utils.json_to_sheet(this.filter_support, {
          header: myHeader,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "SupportList.xlsx");
      }
    },

    refresh_filter(event) {
      this.filter = event.filter;
      this.current_page = 1;
      this.fromDate = this.filter.fromDate;
      this.toDate = this.filter.toDate;
      this.statusRow = this.filter.statusRow;
      this.fetchSupportData(this.current_page);
    },

    reset_filter(event) {
      this.current_page = 1;
      this.fromDate = "";
      this.toDate = "";
      this.search = "";
      this.statusRow = 1;
      this.filter = event.filter;
      this.fetchSupportData(this.current_page);
    },

    handleTableAction(event) {
      if (event.kind == "edit") {
        this.handleShow(event.index, event.data);
      } else if (event.kind == "changePage") {
        this.current_page = event.index;
        this.fetchSupportData(this.current_page);
      } else if (event.kind == "perPageChange") {
        this.current_page = 1;
        this.perPageCount = event.index;
        this.handleReload();
      }
    },

    filterAction(event) {
      console.log(event);
      if (event.kind == "refresh") {
        this.refresh_filter(event);
      } else if (event.kind == "reset") {
        this.reset_filter(event);
      } else if (event.kind == "excel") {
        this.handleExportFile();
      } else if (event.kind == "reload") {
        this.handleReload();
      } else if (event.kind == "new") {
        this.handleNew();
      } else if (event.kind == "supportReg") {
        // this.handleNew();
        this.handleReload();
      }
    },
    handleReload() {
      this.fetchSupportData(this.current_page);
    },
    handleNew() {},
    filterSearch(event) {
      this.search = event.search;
    },
  },
};
</script>

<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-8);
}
.el-table .error-row {
  --el-table-tr-bg-color: var(--el-color-error-light-8);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.cell {
  text-align: center !important;
}

.starter-main .card-body ul {
  list-style-type: none !important;
}

.el-pager {
  -webkit-user-select: none !important;
  user-select: none !important;
  list-style: none !important;
  font-size: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}

.el-upload-list {
  margin: 10px 0 0 !important;
  padding: 0 !important;
  list-style: none !important;
  position: relative !important;
}
</style>
