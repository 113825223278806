<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Breadcrumbs main="Chargers" title="default" />

  <div class="container-fluid p-0">
    <div class="row widget-grid">
      <Filter
        :stateProp="stateList"
        @filterHandle="filterHandle"
        :hasLocation="true"
        :hasCustomDate="true"
      />
      <el-row class="m-0 width-100-p mt-3">
        <!-- Charger by State -->
        <el-col
          :span="
            $vuetify.display.width >= 1500
              ? 8
              : $vuetify.display.width < 700
              ? 24
              : 12
          "
          class="width-100-p p-2 min-h-350"
        >
          <div
            class="row m-0 p-2 bg-me-primary border-color-light rounded-4 min-h-350 height-100-p"
          >
            <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">Charger by location</h5>
            </el-col>
            <el-col
              :span="24"
              class="mt-2"
              v-loading="loadingChart"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              element-loading-background="rgba(122, 122, 122, 0.8)"
            >
              <div
                id="chart"
                v-if="!loadingChart && chargerByState.length <= locationLimit"
              >
                <apexchart
                  type="donut"
                  :options="chargerPieOptions"
                  :series="chargerPieSeries"
                ></apexchart>
              </div>
              <div
                id="chart"
                v-else-if="
                  !loadingChart && chargerByState.length > locationLimit
                "
              >
                <apexchart
                  type="line"
                  :options="chargerLocationBarOptions"
                  :series="chargerLocationBarSeries"
                ></apexchart>
              </div>
            </el-col>
          </div>
        </el-col>
        <!-- Capacity Distribution -->
        <el-col
          :span="
            $vuetify.display.width >= 1500
              ? 8
              : $vuetify.display.width < 700
              ? 24
              : 12
          "
          class="width-100-p p-2 min-h-350"
        >
          <div
            class="row m-0 p-2 bg-me-primary border-color-light rounded-4 min-h-350 height-100-p"
          >
            <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">Capacity Distribution</h5>
            </el-col>
            <el-col
              :span="24"
              class="mt-2"
              v-loading="loadingChart"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              element-loading-background="rgba(122, 122, 122, 0.8)"
            >
              <div id="chart" v-if="!loadingChart">
                <apexchart
                  type="line"
                  :options="chargerCapacityOptions"
                  :series="chargerCapacitySeries"
                ></apexchart>
              </div>
            </el-col>
          </div>
        </el-col>
        <!-- Chargers Distribution -->
        <el-col
          :span="$vuetify.display.width >= 1500 ? 8 : 24"
          class="width-100-p p-2"
        >
          <Cards :dataProp="chargingInfo" />
        </el-col>
        <!-- Top performing charging sites -->
      </el-row>
      <el-row class="m-0 width-100-p mt-3">
        <ChargerDistribution
          :stateProp="filter.state == 'All' ? '' : filter.state"
          :filterProp="filter"
        />
        <PerformingSite :filterProp="filter" ref="performingDiv" />
      </el-row>
      <!-- DataTable -->
      <ChargerTable :filterProp="filter" />
    </div>
  </div>
</template>

<script>
import Filter from "../common/filter.vue";
import Cards from "./chargerCard";
import ChargerTable from "./chargersTable.vue";
import ChargerDistribution from "./chargerDistribution.vue";
import PerformingSite from "./performingSite.vue";
import UserService from "../../../services/user.service";
import { chargerDonut, locationBarChart, capacityChart } from "./config.js";
export default {
  props: {},
  components: {
    Filter,
    ChargerTable,
    ChargerDistribution,
    PerformingSite,
    Cards,
  },
  watch: {
    // "$vuetify.display.width"() {
    //   this.mapHeight = this.$refs.performingDiv.$el.clientHeight;
    //   console.log(this.$refs.performingDiv);
    // },
  },
  computed: {
    stateList() {
      return this.states;
    },
  },
  data() {
    return {
      loadingChart: false,
      locationLimit: 7,
      chargerPieSeries: chargerDonut.series,
      chargerPieOptions: chargerDonut.chartOptions,

      chargerLocationBarSeries: locationBarChart.series,
      chargerLocationBarOptions: locationBarChart.chartOptions,

      chargerCapacitySeries: capacityChart.series,
      chargerCapacityOptions: capacityChart.chartOptions,

      // performingChargingSites: [],
      chargerDistribution: [],
      capacityDistribution: [],
      chargerByState: [],
      chargingInfo: {
        site_count: 0,
        charger_count: 0,
        port_count: 0,
      },
      states: [],
      filter: {
        state: "",
        startDate: null,
        endDate: null,
        customer_id: null,
      },
      loading: false,
      // mapHeight: 400,
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    fillForm() {
      this.fillChargerByState();
    },
    fillChargerByState() {
      this.loadingChart = true;
      this.chargerPieSeries = [];
      this.chargerPieOptions.labels = [];
      // this.states = [];
      if (this.chargerByState.length <= this.locationLimit) {
        this.chargerByState.forEach((value) => {
          // this.states.push(value.charging_site__state);
          this.chargerPieOptions.labels.push(value.charging_site__state);
          this.chargerPieSeries.push(value.charger_count);
        });
      } else {
        this.chargerLocationBarSeries[0].data = [];
        this.chargerLocationBarOptions.labels = [];
        this.chargerByState.forEach((value) => {
          this.chargerLocationBarOptions.labels.push(
            value.charging_site__state
          );
          this.chargerLocationBarSeries[0].data.push(value.charger_count);
        });
      }
      this.chargerCapacitySeries[0].data = [];
      this.chargerCapacityOptions.labels = [];
      this.capacityDistribution.forEach((value) => {
        this.chargerCapacitySeries[0].data.push(value.charger_count);
        this.chargerCapacityOptions.labels.push(value.charger_capacity_kW);
      });
      setTimeout(() => {
        this.loadingChart = false;
      }, 300);
    },
    async fetchData() {
      let body = null;
      body = {
        customer_id: this.filter.customer_id,
        start_date: this.filter.startDate,
        end_date: this.filter.endDate,
        state: this.filter.state == "All" ? "" : this.filter.state,
      };
      this.loading = true;
      await UserService.DashboardChargersData(body)
        .then(
          (response) => {
            this.chargerByState = response.data.data.charger_by_state;
            this.capacityDistribution =
              response.data.data.capacity_distribution;
            this.chargingInfo = response.data.data.charging_info;
            // this.chargerDistribution = response.data.data.charger_distribution;
            // this.performingChargingSites = response.data.data.performing_charging_sites;
            this.states = response.data.data.states.map(
              (charger) => charger.state
            );
            this.fillForm();
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    filterHandle(event) {
      console.log("Charger : ", event);
      this.filter = event;
      this.fetchData();
    },
  },
};
</script>

<style></style>
