<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-dialog
    v-model="show"
    width="600"
    :fullscreen="$vuetify.display.width <= 600 ? true : false"
    class="public-style"
    destroy-on-close
    align-center
    @close="close"
  >
    <el-form
      ref="ruleFormRef"
      :rules="rules"
      :model="charger_data"
      :style="[
        $vuetify.display.width >= 600 ? 'height: 265px;' : 'height: 550;',
      ]"
      :label-position="`top`"
    >
      <el-space fill style="width: 100%">
        <el-alert type="info" show-icon :closable="false">
          <p class="ma-0">Sessions :</p>
        </el-alert>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="site_name" label="Site Name: ">
              <el-input v-model="charger_data.site_name" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item prop="charger_id" label="Charger Name: ">
              <el-input v-model="charger_data.charger_id" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item prop="port_id" label="Port ID: ">
              <el-input v-model="charger_data.port_id" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item prop="start_time" label="Start Date: ">
              <el-input v-model="charger_data.start_time" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item prop="end_time" label="End Date: ">
              <el-input v-model="charger_data.end_time" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item prop="usage_kWh" label="Usage KW: ">
              <el-input v-model="charger_data.usage_kWh" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-space>
    </el-form>
    <!-- </el-row> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button
          size="medium"
          class="me-primary-light"
          @click.stop="confirm"
          color="#0D3937"
        >
          <i class="fa fa-check me-2 me-primary-light"></i> Confirm
        </el-button>
        <el-button
          size="medium"
          class="me-primary"
          @click.stop="close"
          color="#ddf0ac"
        >
          <i class="fa fa-times me-2 me-primary"></i> Cancel
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// import "animate.css";
export default {
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    data_modal: {
      type: Object,
      // eslint-disable-next-line no-unused-vars
      default(rawProps) {
        return rawProps;
      },
    },
  },
  data() {
    return {
      show: this.open_modal,
      rules: {
        site_name: {
          required: true,
          message: "Please enter site name.",
          trigger: "blur",
        },
        charger_id: {
          required: true,
          message: "Please enter charger name.",
          trigger: "blur",
        },
        start_time: [
          {
            type: "date",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isValidDate(value)) {
                  reject("start time must be valid date"); // reject with error message
                } else {
                  resolve();
                }
              });
            },
          },
          {
            required: true,
            message: "Please enter start time.",
            trigger: "blur",
          },
        ],
        end_time: [
          {
            type: "date",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isValidDate(value)) {
                  reject("end time must be valid date"); // reject with error message
                } else {
                  resolve();
                }
              });
            },
          },
          {
            required: true,
            message: "Please enter end time.",
            trigger: "blur",
          },
        ],

        usage_kWh: [
          {
            type: "number",
            // message: "year added must be digit",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("usage kWh must be digit"); // reject with error message
                } else {
                  resolve();
                }
              });
            },
          },
          {
            required: true,
            message: "Please enter usage kWh.",
            trigger: "blur",
          },
        ],
        port_id: {
          required: true,
          message: "Please enter port id.",
          trigger: "blur",
        },
      },
      charger_data: {
        site_name: "",
        charger_id: "",
        start_time: "",
        end_time: "",
        usage_kWh: "",
        port_id: "",
      },
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    open_modal: function (newVal, oldVal) {
      this.show = newVal;
      this.charger_data = JSON.parse(this.data_modal);
      console.log(this.charger_data);
    },
  },
  methods: {
    close() {
      this.show = false;
      this.$emit("close_modal", false);
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isValidDate(dateString) {
      // const dateRegex = /^\d{4}\/\d{2}\/\d{2}$/;
      const dateRegex = /^(\d{4})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
      if (dateRegex.test(dateString)) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    async confirm() {
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid) {
          console.log("submit!");
          this.charger_data.error_message = [];
          this.$emit("confirm_modal", this.charger_data);
        } else {
          console.log("error submit!", fields);
        }
      });
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 30px 10px;
}
.el-input__inner {
  padding: 0 10px !important;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.el-form-item__label {
  font-size: 11px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.el-form-item__content > .el-checkbox {
  margin-top: 16px;
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
