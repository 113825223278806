<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Breadcrumbs main="Nemotion Validation" title="default" />

  <div class="container-fluid p-0">
    <div class="row widget-grid">
      <Filter
        @filterHandle="filterHandle"
        :hasCustomDate="true"
        :hasCustomer="false"
      />
      <el-row class="m-0 width-100-p mt-3">
        <el-col class="d-flex align-item-center p-2" :span="24">
          <div
            class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">
                Total daily emission intensity calculation error
              </h5>
            </el-col>
            <el-col
              :span="24"
              v-loading="loading"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              element-loading-background="rgba(122, 122, 122, 0.8)"
            >
              <div id="chart" v-if="!loadingChart">
                <apexchart
                  type="line"
                  height="350"
                  :options="intensityChartOptions"
                  :series="intensitySeries"
                ></apexchart>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
      <el-row class="m-0 width-100-p mt-3">
        <el-col class="d-flex align-item-center p-2" :span="24">
          <div
            class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">
                Total daily sent-out generation calculation error
              </h5>
            </el-col>
            <el-col
              :span="24"
              v-loading="loading"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              element-loading-background="rgba(122, 122, 122, 0.8)"
            >
              <div id="chartSent" v-if="!loadingChart">
                <apexchart
                  type="line"
                  height="350"
                  :options="sentOutChartOptions"
                  :series="sentOutSeries"
                ></apexchart>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
      <el-row class="m-0 width-100-p mt-3">
        <el-col class="d-flex align-item-center p-2" :span="24">
          <div
            class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">
                Total daily emission calculation error
              </h5>
            </el-col>
            <el-col
              :span="24"
              v-loading="loading"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              element-loading-background="rgba(122, 122, 122, 0.8)"
            >
              <div id="chartEmissions" v-if="!loadingChart">
                <apexchart
                  type="line"
                  height="350"
                  :options="emissionsChartOptions"
                  :series="emissionsSeries"
                ></apexchart>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Filter from "../common/filter.vue";
import {
  errorIntensityChart,
  errorSentOutChart,
  errorEmissionsChart,
} from "./config.js";
import UserService from "../../../services/user.service";

export default {
  props: {
    propCardData: {
      type: Object,
      default: null,
    },
    propCardDataList: {
      type: Array,
      default: null,
    },
  },
  components: {
    Filter,
  },
  watch: {},
  data() {
    return {
      loadingChart: false,
      loading: false,
      selectedPeriod: "All",
      timeOfDayBy: [],
      intensitySeries: errorIntensityChart.series,
      intensityChartOptions: errorIntensityChart.chartOptions,
      sentOutSeries: errorSentOutChart.series,
      sentOutChartOptions: errorSentOutChart.chartOptions,
      emissionsSeries: errorEmissionsChart.series,
      emissionsChartOptions: errorEmissionsChart.chartOptions,
      filter: {
        customer_id: null,
        startDate: null,
        endDate: null,
      },
      timeOfDay: [],
      uniqueDates: [],
      uniqueRegions: [],
    };
  },
  mounted() {
    this.getDailyEmission();
  },
  methods: {
    filterHandle(event) {
      // console.log(event);
      this.filter = event;
      this.getDailyEmission();
    },
    async getDailyEmission() {
      let body = null;
      body = {
        customer_id: this.filter.customer_id,
        start_date: this.filter.startDate,
        end_date: this.filter.endDate,
      };
      this.loading = true;
      await UserService.GetCompareDailyEmissions(body)
        .then(
          (response) => {
            console.log(response.data);
            this.timeOfDay = response.data.data;
            this.uniqueDates = response.data.dates;
            this.uniqueRegions = response.data.regions;
            this.fillForm();
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    fillForm() {
      this.loadingChart = true;
      this.intensitySeries = [];
      this.sentOutSeries = [];
      this.emissionsSeries = [];
      this.uniqueRegions.forEach((reg) => {
        this.intensitySeries.push({ name: reg, data: [] });
        this.sentOutSeries.push({ name: reg, data: [] });
        this.emissionsSeries.push({ name: reg, data: [] });
      });
      this.intensityChartOptions.xaxis.categories = this.uniqueDates;
      this.sentOutChartOptions.xaxis.categories = this.uniqueDates;
      this.emissionsChartOptions.xaxis.categories = this.uniqueDates;

      this.uniqueDates.forEach((date) => {
        // intensity
        this.intensitySeries.forEach((region) => {
          const item = this.timeOfDay.find(
            (p) => p.id === date + "-" + region.name
          );
          if (item != undefined && item != null) {
            region.data.push(item.error_intensity);
          } else {
            region.data.push(0);
          }
        });
        // sentOut
        this.sentOutSeries.forEach((region) => {
          const item = this.timeOfDay.find(
            (p) => p.id === date + "-" + region.name
          );
          if (item != undefined && item != null) {
            region.data.push(item.error_sent_out);
          } else {
            region.data.push(0);
          }
        });
        // emissions
        this.emissionsSeries.forEach((region) => {
          const item = this.timeOfDay.find(
            (p) => p.id === date + "-" + region.name
          );
          if (item != undefined && item != null) {
            region.data.push(item.error_emissions);
          } else {
            region.data.push(0);
          }
        });
      });
      setTimeout(() => {
        this.loadingChart = false;
      }, 300);
    },
  },
};
</script>

<style></style>
