<template>
  <!-- <LoginFrm /> -->
  <router-view />
</template>

<script>
// import LoginFrm from "./auth/login.vue";

export default {
  name: "App",
  components: {
    // LoginFrm,
  },
};
</script>
