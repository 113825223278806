<template>
  <div class="page-title bg-me-primary-1 text-white">
    <div class="row">
      <div class="col-4">
        <h3>{{ main }}</h3>
      </div>
      <div class="col-8">
        <el-breadcrumb class="pa-0 float-right breadcrumb text-white">
          <el-breadcrumb-item :to="{ path: '/dashboard' }">
            <i class="fa fa-home f-16 p-l-1 p-r-1 text-white"> </i>
          </el-breadcrumb-item>
          <el-breadcrumb-item
            v-for="item in path"
            :key="item"
            :to="item.disabled ? `` : { path: item.href }"
            class="b-item-style"
          >
            {{ item.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      default: "home",
    },
    main: {
      default: "",
    },
    path: {
      default: [],
    },
  },
  method: {
    home() {
      console.log("as");
      this.$router.push({ path: "/dashboard" });
    },
  },
};
</script>

<style>
/* .el-breadcrumb__item:last-child {
  color: var(--el-color-primary) !important;
} */
.b-item-style > span {
  color: white !important;
}
</style>
