<template>
  <div class="container-fluid">
    <Breadcrumbs main="Support Details" :path="breadcrumbPath" />
    <div class="row starter-main">
      <el-row gutter="10" v-loading="loading">
        <div class="card mb-5 width-100-p">
          <div class="card-header bg-me-primary-light text-white py-2 px-3">
            <span class="font-16-b line-clamp-1">
              <i class="icofont icofont-law-document font-18-b pr-2 w-80-p"></i>
              {{ "Subject : " + support_data.subject }}</span
            >
          </div>
          <div class="card-body bg-me-primary rounded-bottom-3 text-white p-3">
            <el-row gutter="10">
              <el-col class="pa-3 pt-0" :span="12">
                <span class="text-white"> Ticket Serial : </span>
                <span class="font-warning pl-2">
                  {{ "#" + support_data.id }}
                </span>
              </el-col>
              <el-col class="pa-3 pt-0 float-right" :span="12">
                <span class="text-white">Tracking Code : </span>
                <span class="pl-2">
                  {{ createBtoa("#Serial: " + support_data.id) }}
                </span>
              </el-col>
            </el-row>
            <el-row gutter="10">
              <el-col class="pa-3 pt-0" :span="12">
                <span class="text-white">Status : </span>
                <span
                  class="badge width-120"
                  :class="
                    support_data.status_id === '1'
                      ? 'badge-primary'
                      : support_data.status_id === '2'
                      ? 'badge-warning'
                      : support_data.status_id === '3'
                      ? 'badge-secondary'
                      : support_data.status_id === '4'
                      ? 'badge-info'
                      : support_data.status_id === '5'
                      ? 'badge-success'
                      : 'badge-dark'
                  "
                >
                  {{
                    support_data.status_id === "1"
                      ? "Processing"
                      : support_data.status_id === "2"
                      ? "Doing"
                      : support_data.status_id === "3"
                      ? "AnsweredAdmin"
                      : support_data.status_id === "4"
                      ? "AnsweredUser"
                      : support_data.status_id === "5"
                      ? "Done"
                      : "Closed"
                  }}
                </span>
              </el-col>
              <el-col class="pa-3 pt-0 float-right" :span="12">
                <span class="text-white">Created At : </span>
                <span class="pl-2">
                  {{ formattedDate(support_data.created_at) }}
                </span>
              </el-col>
            </el-row>
            <el-row gutter="10">
              <el-col class="pa-3 pt-0" :span="24">
                <div v-html="support_data.description"></div>
              </el-col>
            </el-row>
            <div class="row ma-0 float-right text-right">
              <el-button
                v-if="
                  support_data.file_path != null && support_data.file_path != ''
                "
                @click="handleDownload(support_data)"
                class="me-primary rounded-3 width-100 px-2"
                color="#ddf0ac"
                size="small "
              >
                <i class="fa fa-paperclip pr-2"></i>
                Attach File
              </el-button>
            </div>
          </div>
        </div>
        <div
          class="card mb-5 width-100-p"
          v-for="(item, index) in support_data.support_details"
          :key="index"
          :class="
            $store.state.auth.user != null &&
            $store.state.auth.user.id == item.user_id
              ? 'mr-15'
              : 'ml-15'
          "
        >
          <div
            class="card-header py-2"
            :class="
              $store.state.auth.user != null &&
              $store.state.auth.user.id == item.user_id
                ? 'bg-me-secondary-bright'
                : 'bg-me-secondary'
            "
          >
            <el-row>
              <el-col :span="$vuetify.display.width < 600 ? 12 : 16">
                <i
                  v-if="
                    $store.state.auth.user != null &&
                    $store.state.auth.user.id != item.user_id
                  "
                  class="icofont icofont-users-alt-4 pr-1 f-18"
                ></i>
                <i v-else class="icofont icofont-business-man pr-1 f-18"></i>
                <span class="mb-0 f-14">
                  {{ " : " + item.user_full_name }}
                </span>
              </el-col>
              <el-col
                v-if="$vuetify.display.width >= 600"
                :span="8"
                class="text-right"
              >
                <i class="fa fa-calendar pr-2 f-16"></i>
                <span class="text-right f-12 mb-0">
                  {{ formattedDate(item.created_at) }}
                </span>
              </el-col>
            </el-row>
          </div>
          <div
            class="card-body bg-me-primary text-white rounded-bottom-3 pb-3 pt-0"
          >
            <el-row gutter="10">
              <el-col class="pa-3" :span="24">
                <div v-html="item.description"></div>
              </el-col>
            </el-row>
            <div class="row ma-0 float-right text-right">
              <el-button
                v-if="item.file_path != null && item.file_path != ''"
                @click="handleDownload(item)"
                class="me-primary rounded-3 width-100 px-2"
                color="#ddf0ac"
                size="small "
              >
                <i class="fa fa-paperclip pr-2"></i>
                Attach File
              </el-button>
            </div>
          </div>
        </div>
        <el-row class="width-100-p pa-2">
          <el-col :span="24" class="mt-8">
            <label class="text-white"> Description: </label>
            <ckeditor
              :editor="editor"
              v-model="description"
              :config="editorConfig"
              @blur="handleBlur"
            ></ckeditor>
            <div v-if="hasDescription" class="font-10" style="color: #f56c6c">
              Please enter description.
            </div>
          </el-col>
          <el-col :span="24" class="mt-10">
            <el-upload
              ref="upload"
              class="upload-demo"
              :limit="1"
              :on-change="onChange"
              :auto-upload="false"
            >
              <template #trigger>
                <el-button class="me-primary-light" color="#0D3937">
                  <i class="fa fa-paperclip pr-2"></i>
                  select file
                </el-button>
              </template>
              <template #tip>
                <div class="el-upload__tip text-warning">
                  limit 1 file, new file will cover the old file
                </div>
              </template>
            </el-upload>
          </el-col>
          <el-col class="mb-5" :span="24">
            <el-button
              size="medium"
              class="me-primary float-right mt-5"
              @click.stop="handleBack"
              color="#ddf0ac"
            >
              <i class="fa fa-mail-reply me-2"></i> Back
            </el-button>
            <el-button
              size="medium"
              class="float-right mt-5 mr-2 me-primary-light"
              @click.stop="handleRegister"
              color="#0D3937"
            >
              <i class="fa fa-check me-2"></i> Register
            </el-button>
          </el-col>
        </el-row>
      </el-row>
    </div>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UserService from "../../services/user.service";
import { useToast } from "vue-toastification";
import moment from "moment";

export default {
  name: "SupportDetails",
  props: ["errors"],
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "Supports",
          disabled: false,
          href: "/supports",
          active: true,
        },
        {
          title: "Support Details",
          disabled: false,
          href: "/supportDetails",
          active: true,
        },
      ],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            // "|",
            // "imageUpload",
            "|",
            "undo",
            "redo",
          ],
        },
      },
      description: "",
      hasDescription: false,
      selectedFile: null,
      support_data: {
        id: "10",
        subject: "Change Charger",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.",
        status_id: "",
        created_at: "",
        support_date: "",
        file_path: "",
        support_details: [],
      },
    };
  },
  async mounted() {
    // let data = this.$route.query.supportData;
    // if (data !== undefined) this.support_data = JSON.parse(data);
    console.log(this.$route.query.support_id);
    await UserService.getCustomerSupport(this.$route.query.support_id)
      .then(
        (response) => {
          // this.supportDataList = response.data.support;
          this.support_data = response.data.support;
        },
        (error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        }
      )
      .catch((error) => {
        this.loading = false;
        console.log(error);
        if (error.response.status == 401) {
          this.$router.push({
            path: "/",
          });
        }
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isValidDate(dateString) {
      const dateRegex = /^\d{4}\/\d{2}\/\d{2}$/;
      if (dateRegex.test(dateString)) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    formattedDate(date) {
      return moment(date).format("YYYY/MM/DD HH:mm:ss");
    },
    createBtoa(item) {
      return btoa(item);
    },
    handleBack() {
      this.$router.replace("/tickets");
    },
    handleRegister() {
      if (this.description === "") {
        this.hasDescription = true;
      } else {
        this.hasDescription = false;
      }
      if (!this.hasDescription) {
        let formData = new FormData();
        formData.append("attach_file", this.selectedFile);
        formData.append("description", this.description);
        formData.append("support_id", this.support_data.id);
        UserService.SetSupportDetail(formData)
          .then(
            (response) => {
              this.support_data.support_details.push(
                response.data.support_detail
              );
              this.description = "";
              this.selectedFile = null;
              this.$refs.upload.clearFiles();
              useToast().success(
                " Your answer has been successfully registered.",
                {
                  icon: "fa fa-check",
                  position: "bottom-right",
                  timeout: 2000,
                }
              );
            },
            (error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
              useToast().error(
                " An error occurred in the response registration.",
                {
                  icon: "fa fa-times",
                  position: "bottom-right",
                  timeout: 2000,
                }
              );
            }
          )
          .catch((error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
            useToast().error(
              " An error occurred in the response registration.",
              {
                icon: "fa fa-times",
                position: "bottom-right",
                timeout: 2000,
              }
            );
          })
          .finally(() => (this.loading = false));
      }
    },
    onChange(event, fileList) {
      this.loading = true;
      const file = event.raw ? event.raw : null;
      if (file.size / 1024 / 1024 > 1) {
        this.loading = false;
        this.$message.error(`file can not be greater than 1MB!`);
        fileList.pop();
      } else {
        if (file) {
          this.selectedFile = file;
        }
      }
    },
    handleBlur() {
      if (this.description === "") {
        this.hasDescription = true;
      } else {
        this.hasDescription = false;
      }
    },
    handleDownload(item) {
      let url = UserService.getURL() + "media/" + item.file_path;
      window.open(url, "_blank");
    },
  },
};
</script>

<style>
.el-form-item__label {
  font-size: 11px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.el-form-item__content > .el-checkbox {
  margin-top: 16px;
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.w-80-p {
  width: 80% !important;
}

.ck-content {
  height: 300px;
}
</style>
