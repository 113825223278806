<template>

    <div class="container-fluid p-0 ">
        <div class="row widget-grid">

                <!-- Input Summary table -->
                <div >

                    <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                        <h5 class="text-white mt-3 ml-5">Input summary</h5>

                        <el-table :data="tableData" stripe style="width: 95%" class="mt-3 mb-3 ml-5 data-table">
                            <el-table-column prop="location" label="Location"  />
                            <el-table-column prop="charging_type" label="Charging type"  />
                            <el-table-column prop="annual_usage" label="Annual usage (MWh)"  />
                            <el-table-column prop="annual_growth_in_usage" label="Annual growth in usage (%)"  />
                            <el-table-column prop="grid_emission_intensity" label="Grid emission intensity (tCO2-e/MWh)"  />
                            <el-table-column prop="annual_emission_intensity_improvement" label="Annual emission intensity improvement (%)"  />
                        </el-table>

                    </div>

                </div>
                <!-- Input Summary table END -->

                <!-- chart -->
                <div >

                    <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                        <h5 class="text-white mt-3 ml-5">Totals</h5>

                        <apexchart
                            ref="chart"
                            type="bar"
                            height="350"
                            :options="chartOptions"
                            :series="series"
                            ></apexchart>

                    </div>

                </div>
                <!-- chart END-->

                <!-- Totals table -->
                <div >

                    <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                        <h5 class="text-white mt-3 ml-5">Totals</h5>

                        <el-table :data="totalsTableData" stripe style="width: 95%" class="mt-3 mb-3 ml-5 data-table">
                            <el-table-column prop="location" label="Location" sortable />
                            <el-table-column prop="year" label="Year" sortable />
                            <el-table-column prop="abatement" label="Abatement(tCO2-e)" sortable />
                            <el-table-column prop="project_emission" label="Project Emission (tCO2-e)" sortable />
                            <el-table-column prop="baseline_emission" label="Baseline Emission (tCO2-e)" sortable />
                            <el-table-column prop="abatement_per_mwh" label="Abatement per MWh" sortable />
                        </el-table>

                    </div>

                </div>
                <!-- Totals  table END -->


                <!-- two button  -->
                <div >
                    <div>

                    <el-row class="m-0 width-100-p mt-3">
                        <el-col class="d-flex align-item-center px-2" :span="3">
                            <!-- <button><span>Apply</span></button> -->
                            <el-button
                                color="#ddf0ac"
                                class="height-40 rounded-3 mr-1"
                            >
                                <svg class="stroke-icon width-20 height-20">
                                <use
                                    href="@/assets/svg/icon-sprite.svg#estimator_pdf"
                                ></use>
                                </svg>
                                <span class="me-primary pl-3"> Export to PDF </span>
                            </el-button>
                        </el-col>

                        <el-col class="d-flex align-item-center px-2" :span="2">
                            <!-- <button><span>Reset</span></button> -->
                            <el-button @click="resetForm"
                                color="#0D3937"
                                class="height-40 border-color-light rounded-3 ml-1"
                                >
                                <svg class="stroke-icon width-20 height-20">
                                    <use
                                        href="@/assets/svg/icon-sprite.svg#estimator_reset_btn"
                                        ></use>
                                </svg>
                                    <span class="text-white pl-3"> Reset </span>
                            </el-button> 
                        </el-col>
                    </el-row>

                    </div>

                </div>
                 <!-- two button  -->

              


        </div>
    </div>

</template>

<script>

import { estimatorChart } from "./config.js";


export default{


    data(){
        return{
            tableData: [
                {
                    location: "syd",
                    charging_type: "ac",
                    annual_usage: 25,
                    annual_growth_in_usage: 0.2,
                    grid_emission_intensity: 0.5,
                    annual_emission_intensity_improvement: 0.3

                },
                {
                    location: "mel",
                    charging_type: "dc",
                    annual_usage: 23,
                    annual_growth_in_usage: 0.1,
                    grid_emission_intensity: 0.2,
                    annual_emission_intensity_improvement: 0.5

                },
                {
                    location: "mel",
                    charging_type: "dc",
                    annual_usage: 23,
                    annual_growth_in_usage: 0.1,
                    grid_emission_intensity: 0.2,
                    annual_emission_intensity_improvement: 0.5

                },
                {
                    location: "mel",
                    charging_type: "dc",
                    annual_usage: 23,
                    annual_growth_in_usage: 0.1,
                    grid_emission_intensity: 0.2,
                    annual_emission_intensity_improvement: 0.5

                },

                
            ],

            totalsTableData:[
                {
                    location: "mel",
                    year: 2023,
                    abatement: 23,
                    project_emission: 24,
                    baseline_emission: 0.2,
                    abatement_per_mwh: 0.3
                },
                {
                    location: "act",
                    year: 2021,
                    abatement: 21,
                    project_emission: 14,
                    baseline_emission: 0.8,
                    abatement_per_mwh: 0.1
                },
                {
                    location: "qld",
                    year: 2022,
                    abatement: 22,
                    project_emission: 45,
                    baseline_emission: 0.9,
                    abatement_per_mwh: 0.2
                },

            ],

            series: estimatorChart.series,
            chartOptions: estimatorChart.chartOptions,

            }
            },

            methods: {
                resetForm() {
                    this.$emit('resetForm');
                }
            }
 

}
</script>

<style>

</style>