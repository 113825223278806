<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-dialog
    v-model="show"
    width="800"
    :fullscreen="$vuetify.display.width <= 800 ? true : false"
    class="public-style"
    destroy-on-close
    align-center
    @close="close"
  >
    <!-- <span>This is a message</span> -->
    <!-- <el-row :gutter="20"> -->
    <el-form
      class="frm-class"
      ref="ruleFormRef"
      :rules="rules"
      :model="charger_data"
      :style="[
        $vuetify.display.width >= 800 ? 'height: 450px;' : 'height: 550px;',
      ]"
      :label-position="`top`"
    >
      <el-space fill style="width: 100%">
        <el-alert type="info" show-icon :closable="false">
          <p class="animate__fadeInDown ma-0">Charging Site :</p>
        </el-alert>

        <el-form-item prop="site_name" label="Site Name: ">
          <el-input v-model="charger_data.site_name" />
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Country: ">
              <el-input v-model="charger_data.country" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="lat" label="Lat: ">
              <el-input v-model="charger_data.lat" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="lng" label="Lng: ">
              <el-input v-model="charger_data.lng" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="address_comment" label="Address Comment: ">
              <el-input
                v-model="charger_data.address_comment"
                type="textarea"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-space>
      <el-space fill style="width: 100%">
        <el-alert type="info" show-icon :closable="false">
          <p class="ma-0">Charger :</p>
        </el-alert>
        <el-row :gutter="20">
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item prop="charger_id" label="Charger ID: ">
              <el-input v-model="charger_data.charger_id" />
            </el-form-item>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item label="">
              <el-checkbox
                v-model="charger_data.onsite_renewable"
                label="OnSite Renewable"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item label="">
              <el-checkbox
                v-model="charger_data.onsite_battery"
                label="OnSite Battery"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item>
              <el-checkbox
                v-model="charger_data.is_included"
                label="Is Included"
                size="large"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item prop="year_added" label="Year Added: ">
              <el-input v-model="charger_data.year_added" />
            </el-form-item>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item prop="install_date" label="Install Date: ">
              <el-input v-model="charger_data.install_date" />
            </el-form-item>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item prop="operational_date" label="Operational Date: ">
              <el-input v-model="charger_data.operational_date" />
            </el-form-item>
          </el-col>
          <el-col
            v-if="$vuetify.display.width >= 650 || $vuetify.display.width < 500"
            :span="$vuetify.display.width < 500 ? 12 : 6"
          >
            <el-form-item prop="charger_capacity_kW" label="Capacity KW: ">
              <el-input v-model="charger_data.charger_capacity_kW" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item prop="certificate_number" label="Certificate Num: ">
              <el-input v-model="charger_data.certificate_number" />
            </el-form-item>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item prop="make" label="Make">
              <el-input v-model="charger_data.make" />
            </el-form-item>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item prop="model" label="Model: ">
              <el-input v-model="charger_data.model" />
            </el-form-item>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 12
            "
          >
            <el-form-item prop="charger_type" label="Type: ">
              <el-input v-model="charger_data.charger_type" />
            </el-form-item>
          </el-col>
          <el-col
            v-if="$vuetify.display.width >= 500 && $vuetify.display.width < 650"
            :span="$vuetify.display.width >= 500 ? 8 : 12"
          >
            <el-form-item prop="charger_capacity_kW" label="Capacity KW: ">
              <el-input v-model="charger_data.charger_capacity_kW" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="description" label="Description: ">
              <el-input v-model="charger_data.description" type="textarea" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-space>
      <el-space fill style="width: 100%">
        <el-alert type="info" show-icon :closable="false">
          <p class="ma-0">Charger Port :</p>
        </el-alert>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="port_id" label="Port ID: ">
              <el-input v-model="charger_data.port_id" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="port_type" label="Port Type: ">
              <el-input v-model="charger_data.port_type" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-space>
    </el-form>
    <!-- </el-row> -->
    <template #footer>
      <span class="dialog-footer">
        <!-- <el-button @click="close">Cancel</el-button>
        <el-button type="primary" @click="confirm"> Confirm </el-button> -->
        <el-button
          size="medium"
          class="me-primary-light"
          @click.stop="confirm"
          color="#0D3937"
        >
          <i class="fa fa-check me-2 me-primary-light"></i> Confirm
        </el-button>
        <el-button
          size="medium"
          class="me-primary"
          @click.stop="close"
          color="#ddf0ac"
        >
          <i class="fa fa-times me-2 me-primary"></i> Cancel
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// import "animate.css";
export default {
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    data_modal: {
      type: Object,
      // eslint-disable-next-line no-unused-vars
      default(rawProps) {
        return rawProps;
        // return {
        //   title: "",
        //   country: "",
        //   lat: "",
        //   lng: "",
        //   address_comment: "",
        //   charger_name: "",
        //   year_added: "",
        //   install_date: "",
        //   operational_date: "",
        //   certificate_number: "",
        //   make: "",
        //   model: "",
        //   charger_type: "",
        //   charger_capacity_kW: "",
        //   onsite_renewable: false,
        //   is_included: false,
        //   onsite_battery: false,
        //   description: "",
        //   port_id: "",
        //   port_type: "",
        // };
      },
    },
  },
  data() {
    return {
      show: this.open_modal,
      rules: {
        site_title: {
          required: true,
          message: "Please enter site title.",
          trigger: "blur",
        },
        lat: {
          type: "number",
          //   message: "lat must be digit",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
              if (!this.isNumeric(value)) {
                reject("lat must be digit"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
        lng: {
          type: "number",
          //   message: "lng must be digit",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            console.log(value, Number.isInteger(value));
            return new Promise((resolve, reject) => {
              if (!this.isNumeric(value)) {
                reject("lng must be digit"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
        charger_id: {
          required: true,
          message: "Please enter charger id.",
          trigger: "blur",
        },
        year_added: [
          {
            type: "number",
            // message: "year added must be digit",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              // console.log(
              //   value,
              //   !(value >= 2000 && value <= new Date().getFullYear())
              // );
              return new Promise((resolve, reject) => {
                if (!(value >= 2000 && value <= new Date().getFullYear())) {
                  reject("year added must be between 2000 and current year "); // reject with error message
                } else if (!this.isNumeric(value)) {
                  reject("year added must be digit"); // reject with error message
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        install_date: {
          type: "date",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
              if (!this.isValidDate(value)) {
                reject("install date must be valid date"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
        operational_date: [
          {
            type: "date",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isValidDate(value)) {
                  reject("operational date must be valid date"); // reject with error message
                } else {
                  resolve();
                }
              });
            },
          },
          {
            required: true,
            message: "Please enter operational_date.",
            trigger: "blur",
          },
        ],
        charger_type: {
          type: "enum",
          enum: [`AC`, "DC", "ac", "Ac", "aC", "dc", "Dc", "dC", ""],
          message: "charger_type must be Ac or DC",
          trigger: "blur",
        },
        charger_capacity_kW: {
          type: "integer",
          //   message: "charger capacity kW must be digit",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            console.log(value);
            return new Promise((resolve, reject) => {
              if (!(value >= 1 && value <= 1000)) {
                reject("charger capacity kW must be between 1 and 1000"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
        port_id: {
          required: true,
          message: "Please enter port id.",
          trigger: "blur",
        },
        port_type: {
          required: true,
          message: "Please enter port type.",
          trigger: "blur",
        },
      },
      charger_data: {
        site_title: "",
        country: "",
        lat: "",
        lng: "",
        address_comment: "",
        charger_id: "",
        year_added: "",
        install_date: "",
        operational_date: "",
        certificate_number: "",
        make: "",
        model: "",
        charger_type: "",
        charger_capacity_kW: "",
        onsite_renewable: false,
        is_included: false,
        onsite_battery: false,
        description: "",
        port_id: "",
        port_type: "",
        error_message: "",
      },
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    open_modal: function (newVal, oldVal) {
      this.show = newVal;
      //console.log(this.data_modal);
      this.charger_data = JSON.parse(this.data_modal);
      this.charger_data.onsite_battery =
        this.charger_data.onsite_battery.toUpperCase() == "YES" ? true : false;
      this.charger_data.onsite_renewable =
        this.charger_data.onsite_renewable.toUpperCase() == "YES"
          ? true
          : false;
      console.log("After : ", this.charger_data);
    },
  },
  methods: {
    close() {
      this.show = false;
      this.$emit("close_modal", false);
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isValidDate(dateString) {
      const dateRegex = /^\d{4}\/\d{2}\/\d{2}$/;
      if (dateRegex.test(dateString)) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    async confirm() {
      // this.charger_data.error_message = [];
      // this.$emit("confirm_modal", this.charger_data);
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid) {
          // console.log("submit!");
          this.charger_data.error_message = [];
          this.$emit("confirm_modal", this.charger_data);
        } else {
          console.log("error submit!", fields);
        }
      });
    },
  },
};
</script>

<style>
.frm-class {
  /* height: 450px; */
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 5px;
}

.el-dialog__body {
  padding: 30px 10px;
}
.el-input__inner {
  padding: 0 10px !important;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.el-form-item__label {
  font-size: 11px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.el-form-item__content > .el-checkbox {
  margin-top: 16px;
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
