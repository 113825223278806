<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Breadcrumbs main="Time of Day" title="default" />

  <div class="container-fluid p-0">
    <div class="row widget-grid">
      <Filter
        @filterHandle="filterHandle"
        :hasGroupBy="true"
        :hasCustomDate="true"
        :timeOfDayGroupByListProp="timeOfDayBy"
      />
      <el-row class="m-0 width-100-p mt-3">
        <el-col
          class="d-flex align-item-center p-2"
          :span="$vuetify.display.width >= 750 ? 12 : 24"
        >
          <div
            class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">Number Of Session</h5>
            </el-col>
            <el-col
              :span="24"
              v-loading="loading"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              element-loading-background="rgba(122, 122, 122, 0.8)"
            >
              <div id="chart" v-if="!loadingChart">
                <apexchart
                  type="bar"
                  height="350"
                  :options="numberChartOptions"
                  :series="numberSeries"
                ></apexchart>
              </div>
            </el-col>
          </div>
        </el-col>
        <el-col
          class="d-flex align-item-center p-2"
          :span="$vuetify.display.width >= 750 ? 12 : 24"
        >
          <div
            class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">Hourly usage (MWh)</h5>
            </el-col>
            <el-col
              :span="24"
              v-loading="loading"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              element-loading-background="rgba(122, 122, 122, 0.8)"
            >
              <div id="chart" v-if="!loadingChart">
                <apexchart
                  type="bar"
                  height="350"
                  :options="usageChartOptions"
                  :series="usageSeries"
                ></apexchart>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Filter from "../common/filter.vue";
import { numberSessionChart, usageSessionChart } from "./config.js";
import UserService from "../../../services/user.service";

export default {
  props: {
    propCardData: {
      type: Object,
      default: null,
    },
    propCardDataList: {
      type: Array,
      default: null,
    },
  },
  components: {
    Filter,
  },
  watch: {},
  data() {
    return {
      loadingChart: false,
      loading: false,
      selectedPeriod: "All",
      timeOfDayBy: [],
      numberSeries: numberSessionChart.series,
      numberChartOptions: numberSessionChart.chartOptions,
      usageSeries: usageSessionChart.series,
      usageChartOptions: usageSessionChart.chartOptions,
      filter: {
        groupBy: "all",
      },
      timeOfDay: [],
    };
  },
  mounted() {
    this.getTimeOfDay();
  },
  methods: {
    filterHandle(event) {
      // console.log(event);
      this.filter = event;
      this.getTimeOfDay();
    },
    async getTimeOfDay() {
      let body = null;
      body = {
        customer_id: this.filter.customer_id,
        start_date: this.filter.startDate,
        end_date: this.filter.endDate,
        by: this.filter.groupBy,
        group_by_item: this.filter.groupByItem,
      };
      this.loading = true;
      await UserService.GetSessionTimeOfDayList(body)
        .then(
          (response) => {
            console.log(response.data);
            this.timeOfDay = response.data.data;
            this.fillForm();
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    fillForm() {
      this.loadingChart = true;
      this.numberSeries[0].data = [];
      this.usageSeries[0].data = [];
      this.numberChartOptions.labels = [];
      this.usageChartOptions.labels = [];
      if (this.filter.groupByItem == null || this.filter.groupByItem == "All") {
        this.timeOfDayBy = this.timeOfDay.byItems;
      }

      this.timeOfDay.result.forEach((value) => {
        // if (
        //   this.filter.groupByItem == null ||
        //   this.filter.groupByItem == "All"
        // ) {
        //   if (!this.timeOfDayBy.includes(value.by)) {
        //     this.timeOfDayBy.push(value.by);
        //   }
        // }
        if (!this.numberChartOptions.labels.includes(value.hour.toString())) {
          this.numberChartOptions.labels.push(value.hour.toString());
          this.numberSeries[0].data.push(value.total_sessions);
        } else {
          let i = this.numberChartOptions.labels.indexOf(value.hour.toString());
          this.numberSeries[0].data[i] =
            this.numberSeries[0].data[i] + value.total_sessions;
        }

        if (!this.usageChartOptions.labels.includes(value.hour.toString())) {
          this.usageChartOptions.labels.push(value.hour.toString());
          this.usageSeries[0].data.push(value.total_usage_MWh);
        } else {
          let i = this.usageChartOptions.labels.indexOf(value.hour.toString());
          this.usageSeries[0].data[i] =
            this.usageSeries[0].data[i] + value.total_usage_MWh;
        }
      });

      setTimeout(() => {
        this.loadingChart = false;
      }, 300);
    },
  },
};
</script>

<style></style>
