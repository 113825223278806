<template>
  <el-col :span="24">
    <div
      class="ma-0"
      v-loading="loading"
      :element-loading-spinner="svg"
      element-loading-svg-view-box="-10, -10, 50, 50"
      element-loading-background="rgba(122, 122, 122, 0.8)"
    >
      <el-table
        :data="filterSession"
        :fit="true"
        height="300"
        :row-class-name="tableRowClassName"
        @row-click="handleRowClick"
        class="my-2 data-table"
        scrollbar-always-on
      >
        <el-table-column
          :label="'Customer'"
          :prop="'customer_name'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Site Name'"
          :prop="'site_name'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Charger ID'"
          :prop="'charger_name'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Country'"
          :prop="'country'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'State'"
          :prop="'state'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Port ID'"
          :prop="'port_id'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Start Time'"
          :prop="'start_time'"
          :width="$vuetify.display.width <= 1600 ? 180 : ''"
          sortable
        >
          <template #default="scope">
            {{ formatDate(scope.row.start_time, "YYYY/MM/DD HH:MM:DD") }}
          </template>
        </el-table-column>
        <el-table-column
          :label="'End Time'"
          :prop="'end_time'"
          :width="$vuetify.display.width <= 1600 ? 180 : ''"
          sortable
        >
          <template #default="scope">
            {{ formatDate(scope.row.end_time, "YYYY/MM/DD HH:MM:DD") }}
          </template>
        </el-table-column>
        <el-table-column
          :label="'Usage (KWh)'"
          :prop="'usage_kWh'"
          :width="$vuetify.display.width <= 1600 ? 160 : ''"
          sortable
        />
      </el-table>

      <el-row class="row-bg" justify="space-between">
        <el-col
          :span="5"
          class="d-flex justify-content-start align-items-center"
        >
          <el-select
            v-model="perPageCount"
            placeholder="Select"
            size="small"
            style="width: 70px"
          >
            <el-option label="10" :value="'10'" />
            <el-option label="25" :value="'25'" />
            <el-option label="50" :value="'50'" />
            <el-option label="100" :value="'100'" />
          </el-select>
        </el-col>
        <el-col
          :span="8"
          class="d-flex align-items-center justify-content-center"
        >
          <el-pagination
            small
            background
            layout="prev, pager, next"
            :total="total_items"
            v-model:page-size="page_size"
            v-model:current-page="current_page"
            @current-change="changePage"
            class="my-4"
          />
        </el-col>
        <el-col :span="5" class="d-flex justify-content-end align-items-center">
          <span class="text-white">
            {{ (current_page - 1) * perPageCount + 1 }}-{{
              current_page * perPageCount > total_items
                ? total_items
                : current_page * perPageCount
            }}
            of {{ total_items }}
          </span>
        </el-col>
      </el-row>
    </div>
  </el-col>
</template>

<script>
// import { ElMessageBox } from "element-plus";
// import ShowDetail from "./dialogDetail.vue";
// import UserService from "../../services/user.service";
import moment from "moment";
export default {
  props: {
    loadingProp: {
      type: Boolean,
      default: false,
    },
    usersDataProp: {
      type: Array,
      default(rawProps) {
        return rawProps;
      },
    },
    totalRowProp: {
      type: Number,
    },
  },
  components: {
    // ShowDetail,
  },
  data() {
    return {
      show: true,
      filterSession: [],
      search: "",
      current_page: 1,
      page_size: 50,
      total_items: 100,
      perPageCount: 50,
      loading: false,
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      filterOpen: false,
    };
  },
  watch: {
    loadingProp: {
      handler: function (newValue) {
        this.loading = newValue;
      },
      deep: true,
    },
    usersDataProp: {
      handler: function (newValue) {
        this.filterSession = newValue;
      },
      deep: true,
    },
    totalRowProp: {
      handler: function (newValue) {
        this.total_items = newValue;
        console.log(this.total_items);
      },
      deep: true,
    },
    perPageCount: function (newVal) {
      this.current_page = 1;
      this.page_size = parseInt(newVal);
      this.$emit("tableAction", {
        index: this.page_size,
        data: null,
        kind: "perPageChange",
      });
    },
  },
  methods: {
    handleRowClick(row, column, event) {
      console.log(row, column, event);
    },
    handleShow(index, data) {
      this.$emit("tableAction", { index: index, data: data, kind: "edit" });
    },
    deleteRow(event, index) {
      this.$emit("tableAction", { index: index, data: event, kind: "delete" });
    },
    changePage(page) {
      this.$emit("tableAction", {
        index: page,
        data: null,
        kind: "changePage",
      });
    },
    formatDate(date, format) {
      if (date != null && date != "") return moment(date).format(format);
      else return null;
    },
  },
};
</script>
