import axios from "./axios";

class AuthService {
    login(user) {
        return (
            axios
            .post("api/login", {
                email: user.username,
                password: user.password,
            })
            .then((response) => {
                if (response.data.user != null) {
                    localStorage.setItem("token", JSON.stringify(response.data.token));
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                }
                return response.data;
            })
        );
    }

    register(user) {
        return (
            axios
            .post("api/register", {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                password: user.password,
            })
            .then((response) => {
                if (response.data.user != null) {
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                }
                return response.data;
            }).catch((response) => {
                console.log(response.data);
                return response;
            })
        );
    }

    forgetPass(user) {
        return (
            axios
            .post("api/users/reset_password/", {
                email: user.email
            })
        );
    }

    resetPass(user) {
        return (
            axios
            // .post("api/users/reset_password_confirm/", {
            .post("api/users/reset_password/confirm/", {
                // uid: user.uid,
                token: user.token,
                password: user.password,
                re_new_password: user.confirm_password,
            })
        );
    }

    updateProfile(user) {
        let body = {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone,
            country: user.country,
        };
        return (
            axios
            .post("api/update-user", body)
            .then((response) => {
                if (response.data.user != null) {
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                }
                return response.data;
            }).catch((response) => {
                console.log(response.data);
                return response;
            })
        );
    }
}

export default new AuthService();