<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-dialog
    v-model="show"
    width="600"
    :fullscreen="$vuetify.display.width <= 600 ? true : false"
    class="public-style"
    destroy-on-close
    align-center
    @close="close"
  >
    <el-form
      ref="ruleFormRef"
      :rules="rules"
      :model="user_data"
      :style="[$vuetify.display.width < 600 ? 'height: 550;' : '']"
      :label-position="`top`"
    >
      <el-space fill style="width: 100%">
        <el-alert type="info" class="alert-style" show-icon :closable="false">
          <p class="ma-0 text-white">User :</p>
        </el-alert>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="first_name"
              label="First Name: "
            >
              <el-input v-model="user_data.first_name" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="last_name"
              label="Last Name: "
            >
              <el-input v-model="user_data.last_name" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item class="label-style" prop="email" label="Email: ">
              <el-input v-model="user_data.email" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="password"
              label="Password: "
            >
              <el-input v-model="user_data.password" type="password" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 24 : 24">
            <el-form-item
              class="label-style"
              prop="customer_id"
              label="Customer: "
            >
              <!-- <el-input v-model="user_data.customer_id" /> -->
              <el-select
                v-model="user_data.customer_id"
                placeholder="Select"
                filterable
                class="w-100"
              >
                <el-option
                  v-for="item in customers"
                  :key="item.id"
                  :label="item.full_name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.full_name }}</span>
                  <!-- <span style="text-align: center">{{ item.country }}</span> -->
                  <span
                    style="
                      float: right;
                      color: var(--el-text-color-secondary);
                      font-size: 13px;
                    "
                  >
                    {{ item.phone }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item label="">
              <el-checkbox
                class="text-white"
                v-model="user_data.is_active"
                label="Active"
                size="large"
              />
            </el-form-item>
          </el-col>
          <template
            v-if="
              $store.state.auth.user != null &&
              $store.state.auth.user.is_superuser
            "
          >
            <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
              <el-form-item label="">
                <el-checkbox
                  class="text-white"
                  v-model="user_data.is_superuser"
                  label="Is SuperUser"
                  size="large"
                />
              </el-form-item>
            </el-col>
            <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
              <el-form-item label="">
                <el-checkbox
                  class="text-white"
                  v-model="user_data.is_admin"
                  label="Is Admin"
                  size="large"
                  :disabled="user_data.is_superuser"
                />
              </el-form-item>
            </el-col>
            <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
              <el-form-item label="">
                <el-checkbox
                  class="text-white"
                  v-model="user_data.is_staff"
                  label="Is Staff"
                  size="large"
                />
              </el-form-item>
            </el-col>
            <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
              <el-form-item label="">
                <el-checkbox
                  class="text-white"
                  v-model="user_data.is_auditor"
                  label="Is Auditor"
                  size="large"
                  :disabled="user_data.is_superuser"
                />
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-space>
    </el-form>
    <!-- </el-row> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button
          size="medium"
          class="me-primary-light"
          @click.stop="confirm"
          color="#234B49"
        >
          <i class="fa fa-check me-2 me-primary-light"></i> Confirm
        </el-button>
        <el-button
          size="medium"
          class="me-primary"
          @click.stop="close"
          color="#ddf0ac"
        >
          <i class="fa fa-times me-2 me-primary"></i> Cancel
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// import "animate.css";
import UserService from "../../services/user.service";
import { useToast } from "vue-toastification";
export default {
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    data_modal: {
      type: Object,
      // eslint-disable-next-line no-unused-vars
      default(rawProps) {
        return rawProps;
      },
    },
  },
  data() {
    return {
      show: this.open_modal,
      rules: {
        last_name: {
          required: true,
          message: "Please enter last name.",
          trigger: "blur",
        },
        email: [
          {
            required: true,
            message: "Please enter email.",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter your password.",
            trigger: "blur",
            validator: (rule, value, callback) => {
              console.log(this.isNewUser());
              if (this.isNewUser()) {
                if (value && value.length >= 8) {
                  callback();
                } else {
                  callback(
                    new Error("Password must be more than 8 characters")
                  );
                }
              } else {
                callback();
              }
            },
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
            message:
              "The password must contain at least one uppercase and lowercase letter and one number.",
            trigger: "blur",
          },
        ],
      },
      user_data: {
        first_name: "",
        last_name: "",
        email: "",
        is_staff: false,
        is_superuser: false,
        is_admin: false,
        is_auditor: false,
        is_active: true,
        customer_id: null,
        id: 0,
      },
      customers: [],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    open_modal: function (newVal, oldVal) {
      this.show = newVal;
      // console.log(this.data_modal);
      if (newVal == true) {
        this.user_data = JSON.parse(this.data_modal);
        this.fetchCustomerData();
      }
    },
  },
  methods: {
    isNewUser() {
      if (this.user_data.id == 0) return true;
      else return false;
    },
    close() {
      this.show = false;
      this.$emit("close_modal", false);
    },
    async fetchCustomerData() {
      await UserService.getCustomers(null)
        .then(
          (response) => {
            this.customers = response.data.customers.filter(
              (item) =>
                item.has_user == false || item.id == this.user_data.customer_id
            );
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    async confirm() {
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid) {
          if (this.user_data.is_superuser) {
            this.user_data.is_admin = true;
            this.user_data.is_auditor = false;
          }
          UserService.SetUser(this.user_data)
            .then(
              (response) => {
                this.$emit("confirm_modal", response.data.user);
              },
              (error) => {
                this.loading = false;
                console.log(error);
                if (error.response.status == 401) {
                  // this.$router.push({
                  //   path: "/",
                  // });
                } else if (error.response.status == 409) {
                  useToast().error(error.response.data.error, {
                    icon: "fa fa-times",
                    position: "bottom-right",
                    timeout: 2000,
                  });
                }
              }
            )
            .catch((error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                // this.$router.push({
                //   path: "/",
                // });
              } else if (error.response.status == 409) {
                useToast().error(error.response.data.error, {
                  icon: "fa fa-times",
                  position: "bottom-right",
                  timeout: 2000,
                });
              }
            })
            .finally(() => (this.loading = false));
        } else {
          console.log("error submit!", fields);
        }
      });
    },
    isAdminHandle(data) {
      console.log(data);
      if (this.user_data.is_superuser) this.user_data.is_admin = true;
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 30px 10px;
}
.el-input__inner {
  padding: 0 10px !important;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.el-form-item__label {
  font-size: 11px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.el-form-item__content > .el-checkbox {
  margin-top: 16px;
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
