export default function auth({
  next,
  router
}) {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user.is_admin) {
    return router.push({
      path: '/dashboard'
    });
  }
  if (!localStorage.getItem('token')) {
    return router.push({
      path: '/'
    });
  }

  return next();
}