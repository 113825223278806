<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="row m-0">
    <!-- GroupBy -->
    <el-col
      :span="$vuetify.display.width >= 1200 ? 16 : 24"
      :class="$vuetify.display.width < 750 ? 'x-center' : ''"
      v-if="hasGroupBy"
    >
      <el-radio-group
        v-if="$vuetify.display.width >= 650"
        v-model="selectedGroupBy"
        text-color="#ffff"
        fill="#234B49"
        class="width-100-p mb-2"
        :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
      >
        <el-radio-button class="year-period width-10-p text-center" label="all">
          All
        </el-radio-button>
        <el-radio-button
          class="month-period width-15-p text-center"
          label="suburb"
        >
          By Suburb
        </el-radio-button>
        <el-radio-button
          class="month-period width-30-p text-center"
          label="elec_region"
        >
          By Electricity Region
        </el-radio-button>
        <el-radio-button
          class="month-period width-25-p text-center"
          label="charging_site"
        >
          By Charging Site
        </el-radio-button>
        <el-radio-button
          class="week-period width-20-p text-center"
          label="post_code"
        >
          By Postcode
        </el-radio-button>
      </el-radio-group>

      <el-dropdown
        v-else
        trigger="click"
        class="filter-drop width-100-p"
        @command="handleGroupBy"
      >
        <el-button
          color="#0D3937"
          class="height-40 border-color-light rounded-3 width-100-p ml-1"
        >
          <svg class="stroke-icon width-20 height-20">
            <use
              href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
            ></use>
          </svg>
          <span class="text-white pl-3">
            {{ showGroupBy == "All" ? showGroupBy : "Group " + showGroupBy }}
          </span>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="(item, index) in groupByItems"
              :key="index"
              :command="item.key"
            >
              {{ item.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-col>
    <!-- Period -->
    <el-col
      :span="$vuetify.display.width >= 750 ? 12 : 24"
      :class="$vuetify.display.width < 750 ? 'x-center' : ''"
      v-if="hasPeriod"
    >
      <el-radio-group
        v-model="selectedPeriod"
        text-color="#ffff"
        fill="#234B49"
        :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
        style="min-width: 320px"
      >
        <el-radio-button
          v-if="periodNameProp == null || periodNameProp == 'All'"
          class="year-period width-25-p text-center"
          label="All"
        >
          All
        </el-radio-button>
        <el-radio-button
          v-else
          class="year-period width-25-p text-center"
          label="2023 MP"
        >
          {{ periodNameProp }}
        </el-radio-button>
        <el-radio-button
          class="month-period width-30-p text-center"
          label="Year"
        >
          12 months
        </el-radio-button>
        <el-radio-button
          class="month-period width-25-p text-center"
          label="Month"
        >
          30 days
        </el-radio-button>
        <el-radio-button
          class="week-period width-20-p text-center"
          label="Week"
        >
          7 days
        </el-radio-button>
      </el-radio-group>
    </el-col>

    <el-col
      :span="
        hasGroupBy &&
        timeOfDayGroupByListProp != null &&
        timeOfDayGroupByListProp.length > 0
          ? $vuetify.display.width >= 1200
            ? 8
            : 24
          : $vuetify.display.width >= 750
          ? 12
          : 24
      "
      class="d-flex align-item-center"
      :class="
        $vuetify.display.width < 750
          ? 'x-center mt-2'
          : hasPeriod == true
          ? 'justify-end'
          : ''
      "
    >
      <div class="m-0" v-if="$vuetify.display.width >= 750 && !hasGroupBy">
        <!-- Custom filter -->
        <el-button
          v-if="hasCustomDate"
          color="#0D3937"
          class="height-40 border-color-light rounded-3"
          @click="customDate = !customDate"
        >
          <svg class="stroke-icon width-20 height-20">
            <use href="@/assets/svg/icon-sprite.svg#stroke-date"></use>
          </svg>
          <span class="text-white pl-3"> Custom filter </span>
        </el-button>
        <!-- State -->
        <el-dropdown
          v-if="hasLocation"
          trigger="click"
          class="filter-drop"
          @command="handleFilterState"
        >
          <el-button
            color="#0D3937"
            class="height-40 border-color-light rounded-3 ml-1"
          >
            <svg class="stroke-icon width-20 height-20">
              <use
                href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
              ></use>
            </svg>
            <span
              class="text-white pl-3"
              v-if="
                $vuetify.display.width >= 1007 && $vuetify.display.width <= 1012
              "
            >
              {{
                " " +
                (filter.state == "" || filter.state == "All"
                  ? "Filter by"
                  : filter.state)
              }}
            </span>
            <span class="text-white pl-3" v-else>
              Filter by
              {{
                " " +
                (filter.state == "" || filter.state == "All"
                  ? "location"
                  : filter.state)
              }}
            </span>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, index) in states"
                :key="index"
                :command="item"
              >
                {{ item }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- GroupBy Selected -->
        <el-dropdown
          v-if="
            hasGroupBy &&
            timeOfDayGroupByListProp != null &&
            timeOfDayGroupByListProp.length > 0
          "
          trigger="click"
          class="filter-drop"
          @command="handleGroupBySelected"
        >
          <el-button
            color="#0D3937"
            class="height-40 border-color-light rounded-3 ml-1"
          >
            <svg class="stroke-icon width-20 height-20">
              <use
                href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
              ></use>
            </svg>
            <span class="text-white pl-3">
              Items :
              {{
                " " +
                (filter.groupByItem == "" || filter.groupByItem == null
                  ? "All"
                  : filter.groupByItem)
              }}
            </span>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, index) in byList"
                :key="index"
                :command="item"
              >
                {{ item }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <el-row class="m-0 p-0 width-100-p" v-else>
        <!-- Custom filter -->
        <el-col
          :span="$vuetify.display.width >= 500 ? 12 : 24"
          :class="$vuetify.display.width >= 500 ? 'pr-2' : 'width-100-p'"
          v-if="hasCustomDate"
        >
          <el-button
            @click="customDate = !customDate"
            color="#0D3937"
            class="x-y-center height-40 border-color-light rounded-3 width-100-p"
          >
            <svg class="stroke-icon width-20 height-20">
              <use href="@/assets/svg/icon-sprite.svg#stroke-date"></use>
            </svg>
            <span class="text-white pl-3"> Custom filter </span>
          </el-button>
        </el-col>
        <!-- Filter by -->
        <el-col
          :span="$vuetify.display.width >= 500 ? 12 : 24"
          :class="$vuetify.display.width >= 500 ? 'pl-2' : 'mt-2 width-100-p'"
          v-if="hasLocation"
        >
          <el-dropdown
            trigger="click"
            class="filter-drop width-100-p"
            @command="handleFilterState"
          >
            <el-button
              color="#0D3937"
              class="height-40 border-color-light rounded-3 width-100-p ml-1"
            >
              <svg class="stroke-icon width-20 height-20">
                <use
                  href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
                ></use>
              </svg>
              <span class="text-white pl-3">
                Filter by
                {{
                  " " +
                  (filter.state == "" || filter.state == "All"
                    ? "location"
                    : filter.state)
                }}
              </span>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(item, index) in states"
                  :key="index"
                  :command="item"
                >
                  {{ item }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
        <!-- Group By Items -->
        <el-col
          :span="$vuetify.display.width >= 500 ? 12 : 24"
          :class="$vuetify.display.width >= 500 ? 'pl-2' : 'mt-2 width-100-p'"
          v-if="
            hasGroupBy &&
            timeOfDayGroupByListProp != null &&
            timeOfDayGroupByListProp.length > 0
          "
        >
          <el-dropdown
            trigger="click"
            class="filter-drop width-100-p"
            @command="handleGroupBySelected"
            max-height="100"
          >
            <el-button
              color="#0D3937"
              class="height-40 width-100-p border-color-light rounded-3 ml-1"
            >
              <svg class="stroke-icon width-20 height-20">
                <use
                  href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
                ></use>
              </svg>
              <span class="text-white pl-3 overflow-h">
                Items :
                {{
                  " " +
                  (filter.groupByItem == "" || filter.groupByItem == null
                    ? "All"
                    : filter.groupByItem)
                }}
              </span>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu class="filter-drop-down">
                <el-dropdown-item
                  v-for="(item, index) in byList"
                  :key="index"
                  :command="item"
                >
                  {{ item }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
      </el-row>
    </el-col>
    <!-- </div> -->
  </div>

  <el-row v-if="customDate" class="y-center m-0 p-2">
    <div
      class="row y-center m-0 mt-2 mx-4 p-2 bg-me-primary border-color-light rounded-4 width-100-p"
      :class="$vuetify.display.width >= 700 ? 'height1-100' : ''"
    >
      <el-form :label-position="`top`" class="text-center">
        <el-space
          class="mt-2"
          fill
          style="width: 100%"
          v-if="
            hasCharger ||
            ($store.state.auth.user != null && $store.state.auth.user.is_admin)
          "
        >
          <el-row gutter="20">
            <el-col
              class="pl-0"
              :span="
                $vuetify.display.width >= 1400
                  ? 8
                  : $vuetify.display.width <= 500
                  ? 24
                  : 12
              "
              :offset="$vuetify.display.width >= 1400 ? 4 : 0"
              v-if="
                hasCustomer &&
                $store.state.auth.user != null &&
                $store.state.auth.user.is_admin
              "
            >
              <el-form-item
                class="label-style"
                prop="customer"
                label="Customer : "
              >
                <el-select
                  clearable
                  v-model="filter.customer_id"
                  placeholder="Select Customer"
                  class="w-100"
                >
                  <el-option
                    v-for="item in customers"
                    :key="item.id"
                    :label="item.full_name"
                    :value="item.id"
                  >
                    <span style="float: left">{{ item.full_name }}</span>
                    <span
                      style="
                        float: right;
                        color: var(--el-text-color-secondary);
                        font-size: 13px;
                      "
                    >
                      {{ item.phone }}
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              v-if="hasCharger"
              class="pr-0"
              :class="
                $store.state.auth.user == null ||
                !$store.state.auth.user.is_admin
                  ? 'pl-0'
                  : ''
              "
              :span="
                $vuetify.display.width >= 1400
                  ? 8
                  : $vuetify.display.width <= 500
                  ? 24
                  : 12
              "
            >
              <el-form-item
                class="label-style"
                prop="charger"
                label="Charger : "
              >
                <el-select
                  v-model="filter.charger_name"
                  class="w-100"
                  filterable
                  remote
                  :remote-method="fetchChargerData"
                  clearable
                  :loading="chargerLoading"
                  placeholder="Select Charger"
                >
                  <el-option
                    v-for="item in chargers"
                    :key="item.charger_name"
                    :label="item.charger_name"
                    :value="item.charger_name"
                  >
                    <span style="float: left">{{ item.charger_name }}</span>
                    <span
                      style="
                        float: right;
                        color: var(--el-text-color-secondary);
                        font-size: 13px;
                      "
                    >
                      {{ item.site_name }}
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-space>
        <el-space fill style="width: 100%">
          <el-row gutter="20">
            <el-col
              class="pl-0"
              :span="
                $vuetify.display.width >= 1400
                  ? 8
                  : $vuetify.display.width <= 500
                  ? 24
                  : 12
              "
              :offset="$vuetify.display.width >= 1400 ? 4 : 0"
            >
              <el-form-item class="label-style mb-1" label="Start Date : ">
                <el-date-picker
                  v-model="filter.startDate"
                  type="date"
                  placeholder="Pick a day"
                  size="default"
                  class="w-100"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
            </el-col>
            <el-col
              class="pr-0"
              :span="
                $vuetify.display.width >= 1400
                  ? 8
                  : $vuetify.display.width <= 500
                  ? 24
                  : 12
              "
            >
              <el-form-item class="label-style mb-1" label="End Date : ">
                <el-date-picker
                  v-model="filter.endDate"
                  type="date"
                  placeholder="Pick a day"
                  size="default"
                  class="w-100"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-space>
      </el-form>
      <el-row gutter="20" class="float-right mt-5">
        <el-col
          :span="$vuetify.display.width >= 1400 ? 16 : 24"
          :offset="$vuetify.display.width >= 1400 ? 4 : 0"
          class="px-1 y-center height-50"
          :class="$vuetify.display.width < 700 ? 'py-2 x-end' : 'height-100-p'"
        >
          <el-button
            color="#ddf0ac"
            @click="handleCustomDate"
            class="height-40 rounded-3 width-100"
            :class="$vuetify.display.width < 700 ? 'x-end' : 'x-y-center'"
          >
            <svg class="stroke-icon width-20 height-20">
              <use href="@/assets/svg/icon-sprite.svg#stroke-refresh"></use>
            </svg>
            <span class="me-primary pl-3"> Apply </span>
          </el-button>
        </el-col>
      </el-row>
    </div>
  </el-row>
</template>
<script>
import moment from "moment";
import UserService from "../../../services/user.service";
export default {
  props: {
    propCardData: {
      type: Object,
      default: null,
    },
    stateProp: {
      type: Array,
      default: null,
    },
    timeOfDayGroupByListProp: {
      type: Array,
      default: null,
    },
    hasGroupBy: {
      type: Boolean,
      default: false,
    },
    hasPeriod: {
      type: Boolean,
      default: false,
    },
    hasCustomDate: {
      type: Boolean,
      default: false,
    },
    hasLocation: {
      type: Boolean,
      default: false,
    },
    hasCharger: {
      type: Boolean,
      default: false,
    },
    hasCustomer: {
      type: Boolean,
      default: true,
    },
    periodNameProp: {
      type: String,
      default: "All",
    },
  },

  watch: {
    selectedPeriod: {
      handler: function (newValue) {
        this.customDate = false;
        let date = this.dateCalc(newValue);
        this.filter.startDate = date.start_date;
        this.filter.endDate = date.end_date;
        this.$emit("filterHandle", this.filter);
      },
      deep: true,
    },
    selectedGroupBy: {
      handler: function (newValue) {
        this.byList = ["All"];
        this.filter.groupByItem = null;
        this.filter.groupBy = newValue;
        this.$emit("filterHandle", this.filter);
      },
      deep: true,
    },
    stateProp: {
      handler: function (newValue) {
        this.states = ["All"];
        // this.states.push("All");
        this.states.push(...newValue);
      },
      deep: true,
    },
    timeOfDayGroupByListProp: {
      handler: function (newValue) {
        if (this.selectedGroupBy != "all") {
          this.byList = ["All"];
        } else {
          this.byList = [];
        }
        // this.byList.push("All");
        this.byList.push(...newValue);
      },
      deep: true,
    },
  },

  data() {
    return {
      selectedPeriod: this.periodNameProp,
      selectedGroupBy: "all",
      showGroupBy: "All",
      // selectedState: "",
      customDate: false,
      states: ["All"],
      customers: [],
      chargers: [],
      filter: {
        customer_id: null,
        state: "",
        startDate: null,
        endDate: null,
        groupBy: null,
        groupByItem: null,
        charger_name: null,
      },
      groupByItems: [
        { key: "all", title: "All" },
        { key: "suburb", title: "By Suburb" },
        { key: "elec_region", title: "By Electricity Region" },
        { key: "charging_site", title: "By Charging Site" },
        { key: "post_code", title: "By Postcode" },
      ],
      byList: [],
      chargerLoading: false,
    };
  },
  mounted() {
    // this.selectedPeriod =
    if (
      this.$store.state.auth.user != null &&
      this.$store.state.auth.user.is_admin &&
      this.hasCustomDate
    )
      this.fetchCustomerData();
    if (this.periodNameProp == "2023 MP") {
      let date = this.dateCalc(this.periodNameProp);
      this.filter.startDate = date.start_date;
      this.filter.endDate = date.end_date;
    }
    // console.log(this.periodNameProp);
    // console.log(this.periodNameProp == "2023 MP");
  },
  methods: {
    async fetchCustomerData() {
      await UserService.getCustomers(null)
        .then(
          (response) => {
            this.customers = response.data.customers;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    async fetchChargerData(search) {
      console.log(search);
      if (search.length > 0) {
        this.chargerLoading = true;
        let body = {
          customer_id: this.filter.customer_id,
          search: search,
        };
        await UserService.GetChargerSelection(body)
          .then(
            (response) => {
              this.chargers = response.data.chargers;
            },
            (error) => {
              this.chargerLoading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
            }
          )
          .catch((error) => {
            this.chargerLoading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          })
          .finally(() => (this.chargerLoading = false));
      }
    },
    handleFilterState(event) {
      this.filter.state = event;
      this.$emit("filterHandle", this.filter);
    },
    handleGroupBy(event) {
      switch (event) {
        case "all":
          this.showGroupBy = "All";
          break;
        case "suburb":
          this.showGroupBy = "By Suburb";
          break;
        case "elec_region":
          this.showGroupBy = "By Electricity Region";
          break;
        case "charging_site":
          this.showGroupBy = "By Charging Site";
          break;
        case "post_code":
          this.showGroupBy = "By Postcode";
          break;
      }
      this.filter.groupBy = event;
      this.$emit("filterHandle", this.filter);
    },
    handleGroupBySelected(event) {
      this.filter.groupByItem = event;
      this.$emit("filterHandle", this.filter);
    },
    handleCustomDate() {
      // this.filter.startDate = event;
      // this.filter.endDate = event;
      this.$emit("filterHandle", this.filter);
    },
    dateCalc(period) {
      let current = moment().format("yyyy-MM-DD");
      if (period == "Today") {
        return {
          start_date: current,
          end_date: current,
        };
      } else if (period == "Week") {
        return {
          start_date: moment(current).subtract(1, "w").format("yyyy-MM-DD"),
          end_date: current,
        };
      } else if (period == "Month") {
        return {
          start_date: moment(current).subtract(1, "M").format("yyyy-MM-DD"),
          end_date: current,
        };
      } else if (period == "Year") {
        return {
          start_date: moment(current).subtract(1, "y").format("yyyy-MM-DD"),
          end_date: current,
        };
      } else if (period == "All") {
        return {
          start_date: null,
          end_date: null,
        };
      } else if (period == "2023 MP") {
        return {
          start_date: "2023-01-01",
          end_date: "2023-12-31",
        };
      }
    },
  },
};
</script>

<style>
.year-period {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.week-period {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.el-radio-group {
  border: 1px solid #547477;
  border-radius: 8px;
}
.el-radio-group > .el-radio-button {
  padding: 4px;
  background-color: #0d3937;
  margin: 0;
}

.el-radio-group > .is-active > span {
  border-radius: 8px !important;
  width: 100% !important;
}

.el-radio-group > .el-radio-button > span {
  background-color: #0d3937;
  border: none !important;
  color: #ffff;
}

.el-dropdown-menu {
  background-color: #0d3937 !important;
  color: white !important;
}

.el-dropdown-menu__item {
  color: white !important;
}

.el-dropdown-menu__item:not(.is-disabled):focus {
  background-color: #547477 !important;
}

.label-color > label {
  color: white !important;
  width: 80px !important;
}
.label-color > div > div {
  height: 100% !important;
  display: flex !important;
  align-items: center;
}

.el-dropdown__list > .el-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.el-radio-button__inner {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
</style>
