/* eslint-disable no-unused-vars */
import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? {
    status: {
        loggedIn: true
    },
    user: user
} : {
    status: {
        loggedIn: false
    },
    user: null
};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({
            commit
        }, user) {
            return AuthService.login(user).then(
                (data) => {
                    if (data.user != null) {
                        commit("loginSuccess", data);
                        return Promise.resolve(user);
                    } else {
                        commit("loginFailure");
                        // eslint-disable-next-line no-undef
                        return Promise.reject(data);
                    }
                },
                (error) => {
                    commit("loginFailure");
                    return Promise.reject(error);
                }
            );
        },
        register({
            commit
        }, user) {
            return AuthService.register(user).then(
                (data) => {
                    if (data.user != null) {
                        commit("registerSuccess", data);
                        return Promise.resolve(user);
                    } else {
                        commit("registerFailure");
                        // eslint-disable-next-line no-undef
                        return Promise.reject(error);
                    }
                },
                (error) => {
                    commit("registerFailure");
                    return Promise.reject(error);
                }
            );
        },

        forgetPass({
            commit
        }, request) {
            return AuthService.forgetPass(request).then(
                (data) => {
                    return Promise.resolve(request);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },

        resetPass({
            commit
        }, request) {
            return AuthService.resetPass(request).then(
                (data) => {
                    return Promise.resolve(request);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },

        logout({
            commit
        }) {
            // AuthService.logout();
            commit("logout");
        },

        update_profile({
            commit
        }, user) {
            return AuthService.updateProfile(user).then(
                (data) => {
                    if (data.user != null) {
                        commit("updateSuccess", data);
                        return Promise.resolve(user);
                    }
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user.user;
            // console.log(state.user.);
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        updateSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user.user;
            // console.log(state.user.);
        },
        // registerSuccess(state) {
        //   state.status.loggedIn = false;
        // },
        // registerFailure(state) {
        //   state.status.loggedIn = false;
        // },
    },
};