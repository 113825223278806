<template>
  <el-col
    class="y-center"
    :span="
      $vuetify.display.width >= 1650
        ? 8
        : $vuetify.display.width >= 1500 && $vuetify.display.width < 1650
        ? 24
        : $vuetify.display.width >= 1300
        ? 8
        : 24
    "
  >
    <div class="width-100-p">
      <el-row class="m-0">
        <el-col
          class="p-2"
          :span="
            $vuetify.display.width >= 1650
              ? 24
              : $vuetify.display.width >= 1500 && $vuetify.display.width < 1650
              ? 12
              : $vuetify.display.width >= 1300 && $vuetify.display.width < 1500
              ? 24
              : $vuetify.display.width >= 700
              ? 12
              : 24
          "
        >
          <div class="row m-0 width-100-p height-80 bg-me-light-blue rounded-4">
            <el-col :span="6" class="x-y-center">
              <div class="width-50 height-50 bg-white x-y-center rounded-4">
                <li class="fa fa-user font-18"></li>
              </div>
            </el-col>
            <el-col :span="18" class="y-center">
              <div class="width-100-p p-2">
                <h6 class="me-primary mb-1">
                  {{ abatementData.abatement_usage.title }}
                </h6>
                <p class="font-15-b me-primary mb-0">
                  {{ abatementData.abatement_usage.value.toLocaleString() }}
                </p>
              </div>
            </el-col>
          </div>
        </el-col>
        <el-col
          class="p-2"
          :span="
            $vuetify.display.width >= 1650
              ? 24
              : $vuetify.display.width >= 1500 && $vuetify.display.width < 1650
              ? 12
              : $vuetify.display.width >= 1300 && $vuetify.display.width < 1500
              ? 24
              : $vuetify.display.width >= 700
              ? 12
              : 24
          "
        >
          <div class="row m-0 width-100-p height-80 bg-me-light-blue rounded-4">
            <el-col :span="6" class="x-y-center">
              <div class="width-50 height-50 bg-white x-y-center rounded-4">
                <li class="fa fa-user font-18"></li>
              </div>
            </el-col>
            <el-col :span="18" class="y-center">
              <div class="width-100-p p-2">
                <h6 class="me-primary mb-1">
                  {{ abatementData.intensity.title }}
                </h6>
                <p class="font-15-b me-primary mb-0">
                  {{ abatementData.intensity.value.toLocaleString() }}
                </p>
              </div>
            </el-col>
          </div>
        </el-col>
        <el-col
          class="p-2"
          :span="
            $vuetify.display.width >= 1650
              ? 24
              : $vuetify.display.width >= 1500 && $vuetify.display.width < 1650
              ? 12
              : $vuetify.display.width >= 1300 && $vuetify.display.width < 1500
              ? 24
              : $vuetify.display.width >= 700
              ? 12
              : 24
          "
        >
          <div class="row m-0 width-100-p height-80 bg-me-light-blue rounded-4">
            <el-col :span="6" class="x-y-center">
              <div class="width-50 height-50 bg-white x-y-center rounded-4">
                <li class="fa fa-user font-18"></li>
              </div>
            </el-col>
            <el-col :span="18" class="y-center">
              <div class="width-100-p p-2">
                <h6 class="me-primary mb-1">
                  {{ abatementData.total_abatement.title }}
                </h6>
                <p class="font-15-b me-primary mb-0">
                  {{ abatementData.total_abatement.value.toLocaleString() }}
                </p>
              </div>
            </el-col>
          </div>
        </el-col>
        <el-col
          class="p-2"
          :span="
            $vuetify.display.width >= 1650
              ? 24
              : $vuetify.display.width >= 1500 && $vuetify.display.width < 1650
              ? 12
              : $vuetify.display.width >= 1300 && $vuetify.display.width < 1500
              ? 24
              : $vuetify.display.width >= 700
              ? 12
              : 24
          "
        >
          <div class="row m-0 width-100-p height-80 bg-me-light-blue rounded-4">
            <el-col :span="6" class="x-y-center">
              <div class="width-50 height-50 bg-white x-y-center rounded-4">
                <li class="fa fa-user font-18"></li>
              </div>
            </el-col>
            <el-col :span="18" class="y-center">
              <div class="width-100-p p-2">
                <h6 class="me-primary mb-1">
                  {{ abatementData.abatement_per.title }}
                </h6>
                <p class="font-15-b me-primary mb-0">
                  {{ abatementData.abatement_per.value.toLocaleString() }}
                </p>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-col>
</template>

<script>
export default {
  props: {
    dataProp: {
      type: Array,
      default(rawProps) {
        return rawProps;
      },
    },
  },
  components: {
    // ShowDetail,
  },
  data() {
    return {
      show: true,
      abatementData: {
        intensity: { title: "", value: "0" },
        total_abatement: { title: "", value: "0" },
        abatement_per: { title: "", value: "0" },
        abatement_usage: { title: "", value: "0" },
      },
    };
  },
  watch: {
    dataProp: {
      handler: function (newValue) {
        this.abatementData.total_abatement = newValue.abatement;
        this.abatementData.abatement_per = newValue.abatement_per_MWh;
        this.abatementData.abatement_usage = newValue.usage_mwh;
        this.abatementData.intensity = newValue.intensity;
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
