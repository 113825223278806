<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-dialog
    v-model="show"
    width="600"
    :fullscreen="$vuetify.display.width <= 600 ? true : false"
    class="public-style"
    destroy-on-close
    align-center
    @close="close"
  >
    <el-form
      ref="ruleFormRef"
      :rules="rules"
      :model="vehicles_data"
      :style="[$vuetify.display.width < 600 ? 'height: 550;' : '']"
      :label-position="`top`"
    >
      <el-space fill style="width: 100%">
        <el-alert type="info" class="alert-style" show-icon :closable="false">
          <p class="ma-0 text-white">Vehicles :</p>
        </el-alert>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="customer_id"
              label="Customer: "
            >
              <el-select
                v-model="vehicles_data.customer_id"
                @change="handleCustomerSelect"
                placeholder="Select"
                filterable
                class="w-100"
              >
                <el-option
                  v-for="item in customers"
                  :key="item.id"
                  :label="item.full_name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.full_name }}</span>
                  <!-- <span style="text-align: center">{{ item.country }}</span> -->
                  <span
                    style="
                      float: right;
                      color: var(--el-text-color-secondary);
                      font-size: 13px;
                    "
                  >
                    {{ item.phone }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="site_id"
              label="Charging Site: "
            >
              <!-- <el-input v-model="user_data.customer_id" /> -->
              <el-select
                v-model="vehicles_data.site_id"
                placeholder="Select"
                filterable
                class="w-100"
              >
                <el-option
                  v-for="item in charging_sites"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.title }}</span>
                  <span
                    style="
                      float: right;
                      color: var(--el-text-color-secondary);
                      font-size: 13px;
                    "
                  >
                    {{ item.city }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="vehicle_id"
              label="Vehicle ID: "
            >
              <el-input v-model="vehicles_data.vehicle_id" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="vehicle_type"
              label="Vehicle Type: "
            >
              <el-input v-model="vehicles_data.vehicle_type" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item class="label-style" prop="make" label="Make: ">
              <el-input v-model="vehicles_data.make" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item class="label-style" prop="model" label="Model: ">
              <el-input v-model="vehicles_data.model" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style" prop="series" label="Series: ">
              <el-input v-model="vehicles_data.series" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="manufacture_year"
              label="Manufacture Year: "
            >
              <el-input v-model="vehicles_data.manufacture_year" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="license_plate"
              label="License Plate: "
            >
              <el-input v-model="vehicles_data.license_plate" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="additional_vehicle_info"
              label="Additional Vehicle Info: "
            >
              <el-input v-model="vehicles_data.additional_vehicle_info" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="battery_capacity"
              label="Battery Capacity: "
            >
              <el-input v-model="vehicles_data.battery_capacity" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="operating_partner"
              label="Operating Partner: "
            >
              <el-input v-model="vehicles_data.operating_partner" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-space>
    </el-form>
    <!-- </el-row> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button
          size="medium"
          class="me-primary-light"
          @click.stop="confirm"
          color="#234B49"
        >
          <i class="fa fa-check me-2 me-primary-light"></i> Confirm
        </el-button>
        <el-button
          size="medium"
          class="me-primary"
          @click.stop="close"
          color="#ddf0ac"
        >
          <i class="fa fa-times me-2 me-primary"></i> Cancel
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// import "animate.css";
import UserService from "../../services/user.service";
// import moment from "moment";
export default {
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    data_modal: {
      type: Object,
      // eslint-disable-next-line no-unused-vars
      default(rawProps) {
        return rawProps;
      },
    },
  },
  data() {
    return {
      show: this.open_modal,
      rules: {
        customer_id: [
          {
            required: true,
            message: "Please select a customer.",
            trigger: "change",
          },
        ],
        site_id: [
          {
            required: true,
            message: "Please select a charging site.",
            trigger: "change",
          },
        ],
        vehicle_id: [
          {
            required: true,
            message: "Please enter vehicle id.",
            trigger: "blur",
          },
        ],
        make: [
          {
            required: true,
            message: "Please enter make.",
            trigger: "blur",
          },
        ],
        model: [
          {
            required: true,
            message: "Please enter model.",
            trigger: "blur",
          },
        ],
        vehicle_type: [
          {
            required: true,
            message: "Please enter vehicle type.",
            trigger: "blur",
          },
        ],
        license_plate: [
          {
            required: true,
            message: "Please enter license plate.",
            trigger: "blur",
          },
        ],
        manufacture_year: [
          {
            required: true,
            message: "Please enter manufacture year.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("manufacture year must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        battery_capacity: [
          {
            required: true,
            message: "Please enter battery capacity.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("battery capacity must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
      vehicles_data: {
        customer_id: 0,
        site_id: 0,
        charging_site_title: null,
        vehicle_id: "",
        make: "",
        model: "",
        vehicle_type: "",
        series: null,
        manufacture_year: 0,
        license_plate: "",
        additional_vehicle_info: null,
        battery_capacity: 0,
        operating_partner: null,
        created_at: null,
        updated_at: null,
        id: 0,
      },

      charging_sites: [],
      customers: [],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    open_modal: function (newVal, oldVal) {
      this.show = newVal;
      if (newVal == true) {
        this.vehicles_data = JSON.parse(this.data_modal);
        this.fetchCustomerData();
        if (
          this.vehicles_data.customer_id != null &&
          this.vehicles_data.customer_id != 0
        ) {
          this.fetchChargingSiteData();
        }
        // this.fetchChargingSiteData();
      }
    },
  },
  methods: {
    async fetchCustomerData() {
      await UserService.getCustomers(null)
        .then(
          (response) => {
            this.customers = response.data.customers;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    handleCustomerSelect(item) {
      // console.log(item);
      this.vehicles_data.customer_id = item;
      this.vehicles_data.charging_sites_id = null;
      this.fetchChargingSiteData();
    },
    async fetchChargingSiteData() {
      let body = {
        customer_id: this.vehicles_data.customer_id,
      };
      await UserService.getChargingSites(body, "", 1, 1000)
        .then(
          (response) => {
            this.charging_sites = response.data.charging_sites;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    isValidDate(dateString) {
      const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
      if (dateRegex.test(dateString)) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    isNewUser() {
      if (this.vehicles_data.id == 0) return true;
      else return false;
    },
    close() {
      this.show = false;
      this.$emit("close_modal", false);
    },
    async confirm() {
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid) {
          UserService.SetVehicles(this.vehicles_data)
            .then(
              (response) => {
                this.$emit("confirm_modal", response.data.vehicle);
              },
              (error) => {
                this.loading = false;
                console.log(error);
                if (error.response.status == 401) {
                  this.$router.push({
                    path: "/",
                  });
                }
              }
            )
            .catch((error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
            })
            .finally(() => (this.loading = false));
        } else {
          console.log("error submit!", fields);
        }
      });
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 30px 10px;
}
.el-input__inner {
  padding: 0 10px !important;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.el-form-item__label {
  font-size: 11px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.el-form-item__content > .el-checkbox {
  margin-top: 16px;
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
