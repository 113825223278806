import {
    createApp
} from 'vue'
// import Vue from 'vue';
import App from './App.vue'
import router from './router';
import store from './store'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@/assets/scss/app.scss'
import VueFeather from 'vue-feather';
// import vuetify from "./plugins/vuetify";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./assets/custom.css";
import "./assets/utils.css";
// import 'animate.css';

// import VueApexCharts from 'vue-apexcharts'
import VueApexCharts from "vue3-apexcharts";

import Breadcrumbs from './components/bread_crumbs';

// Vuetify
import 'vuetify/styles'
import {
    createVuetify
} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
})
// new Vuetify


import Toast, {
    // eslint-disable-next-line no-unused-vars
    POSITION,
    // eslint-disable-next-line no-unused-vars
    useToast
} from "vue-toastification";
import "vue-toastification/dist/index.css";

// import VueFusionCharts from 'vue-fusioncharts';
// import FusionCharts from 'fusioncharts';
// import Charts from 'fusioncharts/fusioncharts.charts';
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';


// import axios from "axios";
// import globalMixin from "./mixin.js";

// import en from './locales/en.json';
// import pt from './locales/fr.json';
// import fr from './locales/pt.json';
// import es from './locales/es.json';
// import {
//     defaultLocale,
//     localeOptions
// } from './constants/config';

// const messages = {
//     en: en,
//     es: es,
//     pt: pt,
//     fr: fr
// };
// const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;

// const resizeObserverErrFix = () => {
//     let resizeObserverErr = false;
//     const resizeObserverErrHandler = () => {
//         if (resizeObserverErr) return;
//         resizeObserverErr = true;
//         window.setTimeout(() => (resizeObserverErr = false), 500);
//     };
//     window.addEventListener('error', (e) => {
//         console.log(e.message)
//         if (e.message === 'ResizeObserver loop limit exceeded') {
//             e.stopImmediatePropagation();
//             resizeObserverErrHandler();
//         }
//     });
// };

// resizeObserverErrFix();

import {
    GlobalConstantMixin
} from '../mixin';

createApp(App)
    .use(router)
    .use(store)
    .mixin(GlobalConstantMixin)
    // .config.globalProperties.axios = axios
    .component(VueFeather.name, VueFeather)
    .component('Breadcrumbs', Breadcrumbs)
    .use(VueFusionCharts, FusionCharts, Column2D, FusionTheme)
    .use(Toast)
    .use(vuetify)
    .use(ElementPlus)
    .use(VueApexCharts)
    .mount('#app')