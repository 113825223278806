<!-- eslint-disable no-unused-vars -->
<template>
  <div class="container-fluid">
    <Breadcrumbs main="Fleet" :path="breadcrumbPath" />

    <div class="row mb-3 px-0 m-0">
      <!-- alert -->
      <el-row class="m-0 mb-3 px-0">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-alert
            v-if="missingColumns.length > 0"
            @close="missingAlertCloseHandle"
            class="ma-2"
            title="file has a wrong structure"
            type="error"
            show-icon
          >
            <p class="mb-0" style="color: #f56c6c">
              {{ "To send a session, these columns are needed in the file:" }}
            </p>
            <ul>
              <li v-for="(miss, i) in missingColumns" :key="i">
                {{ i + 1 + ". " + miss }}
              </li>
            </ul>
          </el-alert>
        </el-col>
      </el-row>
      <!-- uploader -->
      <el-row class="m-0 mb-3 px-0">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-upload
            ref="upload"
            class="upload-demo width-100-p"
            action="#"
            :limit="1"
            :auto-upload="false"
            drag
            :on-change="onChange"
            :on-remove="onRemove"
          >
            <i class="fa fa-cloud-upload f-46"></i>
            <div class="el-upload__text text-white">
              Drop file here or <em>click to upload</em>
            </div>
            <template #tip>
              <div class="el-upload__tip text-white">
                There is a maximum limit of 100MB for the upload process
              </div>
            </template>
          </el-upload>
        </el-col>
      </el-row>
      <!-- progress -->
      <el-row class="m-0 mb-3 px-0">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-progress
            v-if="hasLargeFile"
            :percentage="sendingFilePercent"
            stroke-width="10"
            :format="progressFormat"
            :status="sendingFilePercent == 100 ? 'success' : ''"
            class="progress-style"
          />
          <el-alert
            v-if="hasLargeFile && sendingFilePercent == 100"
            title="Send Success"
            :type="`success`"
            class="mt-5"
            show-icon
          >
            <p class="mb-0" style="color: #67c23a">
              {{
                "The file with a size of " +
                sessionsFileSize +
                " MB is received successfully. The result of this transaction, including valid and invalid sessions will be emailed to you shortly."
              }}
            </p>
          </el-alert>
        </el-col>
      </el-row>
      <!-- stepper -->
      <el-row class="m-0 mb-3 px-0" v-if="!hasLargeFile">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-steps
            :active="activeStep"
            finish-status="success"
            :process-status="successStep"
            simple
            class="step-style width-100-p"
          >
            <el-step title="Load Data" :description="stepLoadDesc" />
            <el-step
              @click="stepperChange(2)"
              style="cursor: pointer"
              title="Check Validate"
              :description="stepValidDesc"
            />
            <el-step
              @click="stepperChange(3)"
              style="cursor: pointer"
              title="Send Data"
              :description="stepSendDesc"
            />
          </el-steps>
        </el-col>
      </el-row>
      <!-- table -->
      <el-row class="m-0 mb-3 px-0" v-if="!hasLargeFile">
        <el-col
          :span="24"
          class="y-center"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <div class="row m-0 width-100-p">
            <div
              class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
            >
              <el-col :span="24" class="mb-2">
                <div class="row m-0">
                  <el-col
                    class="p-0"
                    :span="24"
                    :class="
                      $vuetify.display.width < 750
                        ? 'x-center width-100-p'
                        : 'd-flex justify-content-start'
                    "
                  >
                    <el-button
                      @click="handleExportFile"
                      color="#ddf0ac"
                      class="height-40 rounded-3"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <i class="fa fa-file-excel-o"></i>
                    </el-button>
                    <el-button
                      @click="handlePerv"
                      color="#ddf0ac"
                      class="height-40 rounded-3"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <i class="fa fa-angle-left"></i>
                      <span class="me-primary pl-3"> Prev </span>
                    </el-button>
                    <el-button
                      @click="handleNext"
                      color="#ddf0ac"
                      class="height-40 rounded-3"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <span class="me-primary pr-3"> Next </span>
                      <i class="fa fa-angle-right"></i>
                    </el-button>
                  </el-col>
                </div>
              </el-col>
              <el-col :span="24" class="p-0">
                <div
                  class="ma-0 width-100-p"
                  v-loading="loading"
                  :element-loading-spinner="svg"
                  element-loading-svg-view-box="-10, -10, 50, 50"
                  element-loading-background="rgba(122, 122, 122, 0.8)"
                >
                  <el-alert
                    v-if="responseAlert.visible"
                    :title="responseAlert.title"
                    :type="responseAlert.type"
                    show-icon
                  >
                    <p
                      class="mb-0"
                      style="color: #67c23a"
                      v-if="response_alert.success_count > 0"
                    >
                      {{ responseAlert.success_desc }}
                    </p>
                    <p
                      class="mb-0"
                      style="color: #f56c6c"
                      v-if="response_alert.pending_count > 0"
                    >
                      {{ responseAlert.pending_desc }}
                    </p>
                    <p
                      class="mb-0"
                      style="color: #e6a23c"
                      v-if="response_alert.duplicate_count > 0"
                    >
                      {{ responseAlert.duplicate_desc }}
                    </p>
                  </el-alert>
                  <el-table
                    :data="filter_excel_data"
                    :fit="true"
                    height="50vh"
                    class="my-2 data-table"
                    :row-class-name="tableRowClassName"
                    @row-click="handleRowClick"
                    scrollbar-always-on
                  >
                    <el-table-column type="expand">
                      <template #default="props">
                        <div
                          class="pa-5"
                          v-if="
                            props.row.error_message != null &&
                            props.row.error_message.length > 0
                          "
                        >
                          <span class="text-red"> Attention : </span>
                          <p
                            m="t-0 b-2"
                            class="mb-0"
                            v-for="(error, i) in props.row.error_message"
                            :key="i"
                          >
                            {{ i + 1 + " : " + error }}
                          </p>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="'Site Title'"
                      :prop="'site_title'"
                      :width="$vuetify.display.width <= 1810 ? 200 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Vehicle ID'"
                      :prop="'vehicle_id'"
                      :width="$vuetify.display.width <= 1810 ? 150 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Make'"
                      :prop="'make'"
                      :width="$vuetify.display.width <= 1810 ? 180 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Model'"
                      :prop="'model'"
                      :width="$vuetify.display.width <= 1810 ? 190 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Vehicle Type'"
                      :prop="'vehicle_type'"
                      :width="$vuetify.display.width <= 1810 ? 150 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Manufacture Year'"
                      :prop="'manufacture_year'"
                      :width="$vuetify.display.width <= 1810 ? 180 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Battery Capacity'"
                      :prop="'battery_capacity'"
                      :width="$vuetify.display.width <= 1810 ? 180 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'License Plate'"
                      :prop="'license_plate'"
                      :width="$vuetify.display.width <= 1810 ? 150 : ''"
                      sortable
                    />
                  </el-table>
                  <div class="d-flex justify-center">
                    <el-pagination
                      small
                      background
                      layout="prev, pager, next"
                      :total="total_items"
                      v-model:page-size="page_size"
                      v-model:current-page="current_page"
                      class="my-4"
                    />
                  </div>
                </div>
              </el-col>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <ShowDetail
      :open_modal="show_modal"
      :data_modal="data_modal"
      @close_modal="close_modal"
      @confirm_modal="confirm_modal"
    />
  </div>
</template>

<script>
import Breadcrumbs from "../../components/bread_crumbs.vue";
import XLSX from "xlsx";
import UserService from "../../services/user.service";
import ShowDetail from "./dialogDetail.vue";
import { useToast } from "vue-toastification";
import { ElMessageBox } from "element-plus";
import moment from "moment";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "fleet",
  components: {
    Breadcrumbs,
    ShowDetail,
  },
  data() {
    return {
      breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "Fleet",
          disabled: false,
          href: "/upload-fleet",
          active: true,
        },
      ],
      show: true,
      selectedFile: null,
      excelDataList: [],
      search: "",
      current_page: 1,
      page_size: 100,
      total_items: 100,
      excelData: [],
      beforeUploadValid: true,
      dupRowIndex: [],
      invalidRowIndex: [],
      data: null,
      activeStep: 0,
      successStep: "wait",
      loading: false,
      register_serial_id: "",
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      fromDate: "",
      toDate: "",
      statusRow: 1,
      filter: {
        fromDate: "",
        toDate: "",
        statusRow: 1,
      },
      hasResponse: true,
      response_alert: {
        all_rows_count: 0,
        success_count: 0,
        success_sum_kwh: 0,
        pending_count: 0,
        pending_sum_kwh: 0,
        error_count: 0,
        error_sum_kwh: 0,
        duplicate_count: 0,
      },
      correctFile: true,
      alertWrongFile: false,
      responseAlert: {
        visible: false,
        title: "",
        type: "info",
        success_desc: "",
        pending_desc: "",
        duplicate_desc: "",
      },
      removeInvalidRow: false,
      missingColumns: [],
      fileUpload: null,
      rows: [],
      isLoading: false,
      dataLoaded: false,
      currentRow: 0,
      sessionsFileSize: 0,
      hasLargeFile: false,
      sendingFilePercent: 0,
    };
  },
  created() {},
  watch: {
    search() {
      this.current_page = 1;
    },
    correctFile(new_val) {
      if (!new_val) {
        this.alertWrongFile = true;
        this.onRemove();
        this.$refs.upload.clearFiles();
      } else {
        this.alertWrongFile = false;
      }
    },
  },
  computed: {
    filter_excel_data() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-unused-vars
      let list = this.excelDataList.filter((item, index) => {
        //item.register_id = this.register_serial_id;
        //item.customer_id = this.$store.state.auth.user.id;
        return (
          ((item.site_title === undefined || item.site_title == null
            ? ""
            : item.site_title
          )
            .toString()
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1 ||
            (item.charger_id === undefined || item.charger_id == null
              ? ""
              : item.charger_id
            )
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1 ||
            (item.port_id === undefined || item.port_id == null
              ? ""
              : item.port_id
            )
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1) &&
          (this.statusRow == 1 ||
            (this.statusRow == 2 &&
              item.is_duplicate) /*this.dupRowIndex.includes(index)) ||*/ ||
            (this.statusRow == 3 &&
              Object.keys(item).includes("error_message") &&
              item.error_message.length > 0))
        );
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.total_items = list.length;
      let paginated = this.paginate(list, this.page_size, this.current_page);
      return paginated;
    },
  },
  methods: {
    onChange(event, fileList) {
      this.loading = true;
      this.successStep = "process";
      const file = event.raw ? event.raw : null;
      this.fileUpload = file;
      this.sessionsFileSize = file.size / 1024 / 1024;
      if (
        file.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        file.type !== "application/vnd.ms-excel"
      ) {
        this.loading = false;
        this.successStep = "wait";
        this.$message.error(`select an Excel format!`);
        fileList.pop();
      } else if (file.size / 1024 / 1024 > 100) {
        this.loading = false;
        this.successStep = "wait";
        this.$message.error(`file can not be greater than 100MB!`);
        fileList.pop();
      } else if (file) {
        this.selectedFile = event.raw;

        this.importExcel(this.sessionsFileSize);
      }
    },
    // eslint-disable-next-line no-unused-vars
    onRemove(event, fileList) {
      this.selectedFile = null;
      this.excelDataList = [];
      // this.primaryExcelDataList = [];
      this.excelData = [];
      this.removeInvalidRow = false;
      this.activeStep = 0;
      this.successStep = "wait";
      this.loading = false;
      this.hasLargeFile = false;
      this.sendingFilePercent = 0;
      this.sessionsFileSize = 0;
      this.response_alert = {
        all_rows_count: 0,
        success_count: 0,
        success_sum_kwh: 0,
        pending_count: 0,
        pending_sum_kwh: 0,
        error_count: 0,
        error_sum_kwh: 0,
        duplicate_count: 0,
      };
      this.correctFile = true;
      this.responseAlert = {
        visible: false,
        title: "",
        type: "info",
        success_desc: "",
        pending_desc: "",
        duplicate_desc: "",
      };
    },

    async reader() {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        this.excelData = XLSX.utils.sheet_to_json(worksheet, {
          defval: null,
        });
        const headers = [];
        for (let cell in worksheet) {
          if (cell !== "!ref" && cell[1] === "1") {
            headers.push(worksheet[cell].v);
          }
        }

        const requiredColumns = [
          "site_title",
          "vehicle_id",
          "make",
          "model",
          "vehicle_type",
          "manufacture_year",
          "battery_capacity",
          "license_plate",
        ];
        this.missingColumns = requiredColumns.filter(
          (column) => !headers.includes(column)
        );
        if (this.missingColumns.length > 0) {
          this.correctFile = false;
        } else {
          this.correctFile = true;
        }
        this.excelDataList = this.excelData;
        this.register_serial_id = btoa(
          this.$store.state.auth.user.id.toString() +
            "-" +
            this.excelDataList.length.toString() +
            "-" +
            Date.now().toString()
        );
        this.loading = false;
      };
      reader.readAsArrayBuffer(this.selectedFile);
      // console.log(4, Date.now().toString());
      this.activeStep = 1;
    },

    handleUpload() {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        console.log(range);

        for (
          let i = this.currentRow;
          i < Math.min(this.currentRow + 10, range.e.r + 1);
          i++
        ) {
          const row = {};
          for (let j = range.s.c; j <= range.e.c; j++) {
            const cellAddress = { c: j, r: i };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            const cellValue = sheet[cellRef] ? sheet[cellRef].v : null;
            row[`col_${j}`] = cellValue;
          }
          this.rows.push(row);
        }
        this.currentRow += 10;
      };
      // console.log(this.rows);
      reader.readAsArrayBuffer(this.fileUpload);
    },

    // eslint-disable-next-line no-unused-vars
    async importExcel(fileSize) {
      if (this.selectedFile) {
        // await this.handleUpload();
        // console.log(fileSize);
        // if (fileSize <= 5) {
        this.hasLargeFile = false;
        await this.reader();
        // } else {
        //   this.hasLargeFile = true;
        //   await this.handleFileUpload(this.fileUpload);
        // }
      } else {
        this.successStep = "error";
        this.$message.error("Please select an Excel file first.");
        this.loading = false;
      }
      this.response_alert = {
        success_count: 0,
        success_sum_kwh: 0,
        pending_count: 0,
        pending_sum_kwh: 0,
        error_count: 0,
        error_sum_kwh: 0,
        duplicate_count: 0,
      };
    },

    progressFormat() {
      return this.sendingFilePercent == 100
        ? "Sent"
        : this.sendingFilePercent.toString() + "%";
    },

    checkDuplicateRow(arrayData) {
      let user = this.$store.state.auth.user;
      this.successStep = "process";
      let uniqueObjects = new Map();
      let ctx = this;
      let validateStatus = true;
      this.dupRowIndex = [];
      this.invalidRowIndex = [];
      arrayData.forEach((obj, i) => {
        var key = `${obj.site_title}-${obj.vehicle_id}-${obj.make}-${obj.model}-${obj.vehicle_type}-${obj.manufacture_year}-${obj.battery_capacity}-${obj.license_plate}`;
        obj.error_message = null;
        if (uniqueObjects.has(key)) {
          ctx.dupRowIndex.push(i);
          obj.is_duplicate = true;
        } else {
          uniqueObjects.set(key, obj);
        }
        obj.register_id = this.register_serial_id;
        obj.customer_id = user.id;
        obj.error_message = ctx.validateData(obj);
        if (validateStatus && obj.error_message.length > 0) {
          validateStatus = false;
        }
      });
      if (validateStatus) {
        this.activeStep = 2;
        this.successStep = "process";
      } else {
        this.successStep = "error";
        this.removeInvalidRow = false;
        ElMessageBox.confirm(
          "Do you want to send rows without errors?",
          "confirm",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "Confirm Delete",
          }
        )
          .then(() => {
            this.removeInvalidRow = true;
            this.activeStep = 2;
            this.stepperChange(3);
          })
          .catch(() => {
            this.successStep = "error";
            this.removeInvalidRow = false;
          });
      }
    },
    // vehicle_id	make	model	vehicle_type manufacture_year	battery_capacity	license_plate

    validateData(item) {
      var error = [];
      // title
      if (item.site_title == null || item.site_title == "") {
        error.push("Site Title can not be null or empty.");
      }
      // vehicle_id
      if (item.vehicle_id == null || item.vehicle_id == "") {
        error.push("Vehicle ID can not be null or empty.");
      }
      // make
      if (item.make == null || item.make == "") {
        error.push("make can not be null or empty.");
      }
      // model
      if (item.model == null || item.model == "") {
        error.push("Model can not be null or empty.");
      }
      // end_time
      if (item.vehicle_type == null || item.vehicle_type == "") {
        error.push("Vehicle Type can not be null or empty.");
      }
      // license_plate
      if (item.license_plate == null || item.license_plate == "") {
        error.push("License Plate can not be null or empty.");
      }
      // manufacture_year
      if (item.manufacture_year == null || item.manufacture_year == "") {
        error.push("Manufacture Year can not be null or empty.");
      }
      if (!this.isNumeric(item.manufacture_year)) {
        error.push("Manufacture Year be of type number.");
      }
      // usage_kWh
      if (item.battery_capacity == null || item.battery_capacity == "") {
        error.push("Battery Capacity can not be null or empty.");
      }
      if (!this.isNumeric(item.battery_capacity)) {
        error.push("Battery Capacity be of type number.");
      }
      return error;
    },

    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      if (row.is_duplicate) {
        return "warning-row";
      } else if (
        Object.keys(row).includes("error_message") &&
        row.error_message.length > 0
      ) {
        return "error-row";
      }
      return "";
    },

    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },

    stepperChange(num) {
      if (num == 2 && this.excelDataList.length > 0 && this.activeStep == 1) {
        this.checkDuplicateRow(this.excelDataList);
      } else if (
        num == 3 &&
        this.excelDataList.length > 0 &&
        this.activeStep == 2
      ) {
        console.log(moment(new Date()).format("HH:mm:ss"));
        this.sendDate(this.excelDataList);
      }
    },

    sendDate(jsonData) {
      this.loading = true;
      jsonData = jsonData.filter((item) => {
        return (
          !item.is_duplicate &&
          (item.error_message == null || item.error_message.length == 0)
        );
      });
      if (jsonData.length == 0) {
        this.loading = false;
        useToast().error(" All rows have errors. There is no row to send.", {
          icon: "fa fa-times",
          position: "bottom-right",
          timeout: 2000,
        });
      } else {
        UserService.setFleet(jsonData)
          .then(
            // eslint-disable-next-line no-unused-vars
            (response) => {
              this.excelDataList = [];
              this.activeStep = 3;
              useToast().success(
                "The submitted file has been received successfully. The result will be emailed to you in a few minutes ",
                {
                  icon: "fa fa-check",
                  position: "bottom-right",
                  timeout: 2000,
                }
              );
              this.onRemove();
              this.$refs.upload.clearFiles();
            },
            (error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
              useToast().error(
                " Please check the details for each item sent.",
                {
                  icon: "fa fa-times",
                  position: "bottom-right",
                  timeout: 2000,
                }
              );
            }
          )
          .catch((error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
            useToast().error(" Please check the details for each item sent.", {
              icon: "fa fa-times",
              position: "bottom-right",
              timeout: 2000,
            });
          })
          .finally(() => (this.loading = false));
      }
    },

    // eslint-disable-next-line no-unused-vars
    handleRowClick(row, column, event) {
      console.log(row, column, event);
    },
    // eslint-disable-next-line no-unused-vars
    close_modal(event) {
      this.show_modal = false;
    },

    confirm_modal(event) {
      this.excelDataList[this.current_row_index] = event;
      this.dupRowIndex.splice(this.current_row_index, 1);
      event.is_duplicate = false;
      this.show_modal = false;
      this.activeStep = 1;
      this.successStep = "process";
    },

    handleShow(index, data) {
      this.current_row_index = index;
      this.data_modal = JSON.stringify(data);
      this.show_modal = true;
    },

    handlePerv() {
      if (this.activeStep == 1 && this.successStep == "error") {
        this.activeStep = 1;
        this.stepperChange(2);
      } else if (this.activeStep == 2) {
        this.activeStep = 1;
        this.successStep = "process";
      } else if (this.activeStep == 3) {
        this.stepperChange(2);
      }
    },

    handleNext() {
      if (this.activeStep == 1) {
        this.stepperChange(2);
      } else if (this.activeStep == 2 && this.successStep == "process") {
        this.stepperChange(3);
      }
    },

    current_change_click() {},

    paginate(array, page_size, page_number) {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },

    filterAction(event) {
      if (event.kind == "refresh") {
        this.refresh_filter(event);
      } else if (event.kind == "reset") {
        this.reset_filter(event);
      } else if (event.kind == "excel") {
        this.handleExportFile();
      } else if (event.kind == "prev") {
        this.handlePerv();
      } else if (event.kind == "next") {
        this.handleNext();
      }
    },

    filterSearch(event) {
      this.search = event.search;
    },

    refresh_filter(event) {
      this.filter = event.filter;
      this.current_page = 1;
      this.fromDate = this.filter.fromDate;
      this.toDate = this.filter.toDate;
      this.search = this.filter.search;
      this.statusRow = this.filter.statusRow;
      console.log(this.filter);
    },

    reset_filter(event) {
      this.current_page = 1;
      this.fromDate = "";
      this.toDate = "";
      this.search = "";
      this.statusRow = 1;
      this.filter = event.filter;
    },

    handleExportFile() {
      if (this.excelDataList.length > 0) {
        let modifiedArr = this.excelDataList.map((item) => {
          let {
            // eslint-disable-next-line no-unused-vars
            error_message,
            // eslint-disable-next-line no-unused-vars
            register_id,
            // eslint-disable-next-line no-unused-vars
            customer_id,
            // eslint-disable-next-line no-unused-vars
            is_duplicate,
            ...rest
          } = item;
          return rest;
        });
        const ws = XLSX.utils.json_to_sheet(modifiedArr, {
          header: Object.keys(modifiedArr[0]),
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "SessionList.xlsx");
      }
    },

    missingAlertCloseHandle() {
      this.alertWrongFile = false;
      this.missingColumns = [];
    },
  },
};
</script>

<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-8);
}
.el-table .error-row {
  --el-table-tr-bg-color: var(--el-color-error-light-8);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.w-80 {
  width: 80px !important;
}
.el-button--primary {
  color: white !important;
}
.el-button--primary :hover {
  color: black !important;
}
.cell {
  text-align: center !important;
}

.upload-demo > ul {
  top: 0 !important;
  left: 0 !important;
}

.session-tab > ul {
  top: 18px !important;
}

.progress-style > .el-progress__text {
  color: white;
  text-align: center;
}
</style>
