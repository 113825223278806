<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <el-col class="mb-2" :span="24">
    <div class="row m-0">
      <div class="m-0 px-0">
        <el-button
          @click.stop="filterOpen = !filterOpen"
          color="#0D3937"
          class="height-40 border-color-light rounded-3 ml-1"
        >
          <svg class="stroke-icon width-20 height-20">
            <use
              href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
            ></use>
          </svg>
          <span class="text-white pl-3"> Filter </span>
        </el-button>
      </div>
    </div>
  </el-col>
  <template v-if="filterOpen">
    <el-form
      :label-position="`top`"
      :style="[filterOpen ? '' : 'display:none;']"
    >
      <el-space fill style="width: 100%" class="pb-0">
        <el-row gutter="10" class="pb-0">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="pb-2">
            <el-form-item class="mb-1 label-style" label="From Date : ">
              <el-date-picker
                v-model="filter.fromDate"
                type="date"
                placeholder="Pick a day"
                size="default"
                class="w-100"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="pb-2">
            <el-form-item class="label-style mb-1" label="To Date : ">
              <el-date-picker
                v-model="filter.toDate"
                type="date"
                placeholder="Pick a day"
                size="default"
                class="w-100"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item class="mb-1 label-style" label="Search : ">
          <input
            class="form-control search-input"
            type="text"
            v-model="search"
            placeholder="Type to search "
          />
        </el-form-item>
      </el-space>
    </el-form>
    <el-row
      gutter="10"
      class="float-right mb-5 mt-3"
      :style="[filterOpen ? '' : 'display:none;']"
    >
      <el-button
        @click.stop="handleRefresh"
        color="#0D3937"
        class="height-40 border-color-light rounded-3 ml-1"
      >
        <i class="fa fa-refresh me-2 text-white"></i>
        <span class="text-white pl-3"> Apply </span>
      </el-button>
      <el-button
        size="large"
        class="me-primary rounded-3 float-right"
        color="#ddf0ac"
        @click.stop="handleReset"
      >
        <i class="icofont icofont-eraser me-2 me-primary"></i> Reset
      </el-button>
    </el-row>
  </template>
</template>

<script>
// import CKEditor from "@ckeditor/ckeditor5-vue";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import UserService from "../../services/user.service";
// import { useToast } from "vue-toastification";

export default {
  components: {
    // ckeditor: CKEditor.component,
  },
  data() {
    return {
      search: "",
      fromDate: "",
      toDate: "",
      // statusRow: 1,
      filterOpen: false,
      filter: {
        fromDate: "",
        toDate: "",
        // statusRow: 1,
      },
      // isNew: false,
      // selectedFile: null,
      // support: {
      //   subject: "",
      //   description: "",
      //   file: "",
      // },
      // editor: ClassicEditor,
      // editorConfig: {
      //   toolbar: {
      //     items: [
      //       "heading",
      //       "|",
      //       "bold",
      //       "italic",
      //       "|",
      //       "bulletedList",
      //       "numberedList",
      //       "|",
      //       "insertTable",
      //       // "|",
      //       // "imageUpload",
      //       "|",
      //       "undo",
      //       "redo",
      //     ],
      //   },
      // },
      // rules: {
      //   subject: {
      //     required: true,
      //     message: "Please enter subject.",
      //     trigger: "blur",
      //   },
      // },
      // descriptionBlur: false,
    };
  },
  watch: {
    search(val) {
      this.$emit("filterSearch", { search: val, kind: "search" });
    },
  },
  methods: {
    // filterExpand9() {
    //   this.isNew = false;
    //   this.filterOpen = !this.filterOpen;
    // },
    // handleBlur9() {
    //   if (this.support.description === "") {
    //     this.descriptionBlur = true;
    //   } else {
    //     this.descriptionBlur = false;
    //   }
    // },
    handleRefresh() {
      this.current_page = 1;
      this.fromDate = this.filter.fromDate;
      this.toDate = this.filter.toDate;
      // this.statusRow = this.filter.statusRow;
      this.$emit("filterAction", { filter: this.filter, kind: "refresh" });
    },
    handleReset() {
      this.current_page = 1;
      this.fromDate = "";
      this.toDate = "";
      this.search = "";
      // this.statusRow = 1;
      this.filter.fromDate = "";
      this.filter.toDate = "";
      // this.filter.statusRow = 1;
      this.$emit("filterAction", { filter: this.filter, kind: "reset" });
    },
    // handleReload9() {
    //   this.$emit("filterAction", { filter: this.filter, kind: "reload" });
    // },
    // handleNew9() {
    //   this.isNew = !this.isNew;
    //   this.filterOpen = false;
    //   this.$emit("filterAction", { filter: this.filter, kind: "new" });
    // },
    // handleExportFile9() {
    //   this.$emit("filterAction", { filter: this.filter, kind: "excel" });
    // },
    // handleSupportReset9() {
    //   this.support.subject = "";
    //   this.support.description = "";
    //   this.selectedFile = null;
    //   this.$refs.upload.clearFiles();
    // },
    // async handleSupportReg9() {
    //   if (this.support.description === "") {
    //     this.descriptionBlur = true;
    //   } else {
    //     this.descriptionBlur = false;
    //   }
    //   await this.$refs["ruleFormRef"].validate((valid, fields) => {
    //     if (valid && !this.descriptionBlur) {
    //       let formData = new FormData();
    //       formData.append("attach_file", this.selectedFile);
    //       formData.append("subject", this.support.subject);
    //       formData.append("description", this.support.description);
    //       UserService.SetSupport(formData)
    //         .then(
    //           (response) => {
    //             this.$emit("filterAction", {
    //               support: response,
    //               kind: "supportReg",
    //             });
    //             this.handleSupportReset();
    //             useToast().success(
    //               " The new ticket was successfully registered ",
    //               {
    //                 icon: "fa fa-check",
    //                 position: "bottom-right",
    //                 timeout: 2000,
    //               }
    //             );
    //           },
    //           (error) => {
    //             this.loading = false;
    //             console.log(error);
    //             if (error.response.status == 401) {
    //               this.$router.push({
    //                 path: "/",
    //               });
    //             }
    //             useToast().error(
    //               "There was an error in registering the ticket.",
    //               {
    //                 icon: "fa fa-times",
    //                 position: "bottom-right",
    //                 timeout: 2000,
    //               }
    //             );
    //           }
    //         )
    //         .catch((error) => {
    //           this.loading = false;
    //           console.log(error);
    //           if (error.response.status == 401) {
    //             this.$router.push({
    //               path: "/",
    //             });
    //           }
    //           useToast().error(
    //             "There was an error in registering the ticket.",
    //             {
    //               icon: "fa fa-times",
    //               position: "bottom-right",
    //               timeout: 2000,
    //             }
    //           );
    //         })
    //         .finally(() => (this.loading = false));
    //     } else {
    //       console.log("error submit!", fields);
    //     }
    //   });
    // },

    // onChange9(event, fileList) {
    //   this.loading = true;
    //   const file = event.raw ? event.raw : null;
    //   // console.log(event);
    //   // console.log(file);
    //   // console.log(fileList);
    //   if (file.size / 1024 / 1024 > 1) {
    //     this.loading = false;
    //     this.$message.error(`file can not be greater than 1MB!`);
    //     fileList.pop();
    //   } else {
    //     if (file) {
    //       this.selectedFile = file;
    //       // console.log(this.selectedFile);
    //     }
    //   }
    // },
  },
};
</script>

<style>
.action-pos {
  position: absolute !important;
  top: 15px !important;
  right: 20px !important;
}

.rotate-scale-up {
  -webkit-animation: rotate-scale-up 0.4s linear both;
  animation: rotate-scale-up 0.4s linear both;
}

@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(90deg);
    transform: scale(2) rotateZ(09deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(180deg);
    transform: scale(1) rotateZ(180deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(90deg);
    transform: scale(2) rotateZ(90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(180deg);
    transform: scale(1) rotateZ(180deg);
  }
}
</style>
