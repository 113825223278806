<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Breadcrumbs main="Session" title="default" />

  <div class="container-fluid p-0">
    <div class="row widget-grid">
      <Filter
        :stateProp="stateList"
        @filterHandle="filterHandle"
        :hasCustomDate="true"
        :hasPeriod="true"
        :hasLocation="true"
        :hasCharger="true"
      />
      <el-row class="m-0 width-100-p mt-3">
        <!-- Abatement -->
        <el-col
          class="d-flex align-item-center px-2"
          :span="$vuetify.display.width >= 1500 ? 16 : 24"
        >
          <div
            class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">Session</h5>
            </el-col>
            <!-- Abatement Chart -->
            <el-col
              :span="24"
              v-loading="loadingChart"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              element-loading-background="rgba(122, 122, 122, 0.8)"
            >
              <div id="chart" v-if="!loadingChart">
                <apexchart
                  ref="chart"
                  type="bar"
                  height="350"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </el-col>
          </div>
        </el-col>
        <!-- History -->
        <el-col
          class="d-flex align-item-center"
          :span="$vuetify.display.width >= 1500 ? 8 : 24"
          :class="$vuetify.display.width < 1500 ? 'mt-2' : ''"
        >
          <div class="row m-0 width-100-p">
            <el-col
              class="d-flex align-item-center p-2"
              :span="
                $vuetify.display.width >= 1500 || $vuetify.display.width < 750
                  ? 24
                  : 12
              "
            >
              <div
                class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
              >
                <el-col :span="17" class="px-3 y-center">
                  <div>
                    <div class="width-100-p p-2">
                      <p class="font-25-b text-white mb-0">
                        {{
                          SessionData == null || SessionData.total_count == null
                            ? 0
                            : SessionData.total_count.toLocaleString()
                        }}
                      </p>
                    </div>
                    <p class="font-16 p-2 mt-2 text-white mb-0">
                      Total number of sessions
                    </p>
                  </div>
                </el-col>
                <el-col :span="7" class="pl-0 x-y-center">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="
                      require('@/assets/images/dashboard/home/FuelAvoided.png')
                    "
                    fit="cover"
                  />
                </el-col>
              </div>
            </el-col>
            <el-col
              class="d-flex align-item-center p-2"
              :span="
                $vuetify.display.width >= 1500 || $vuetify.display.width < 750
                  ? 24
                  : 12
              "
            >
              <div
                class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
              >
                <el-col :span="17" class="px-3 y-center">
                  <div>
                    <div class="width-100-p p-2">
                      <p class="font-25-b text-white mb-0">
                        {{
                          SessionData == null || SessionData.total_usage == null
                            ? 0
                            : SessionData.total_usage.toLocaleString()
                        }}
                      </p>
                    </div>
                    <p class="font-16 p-2 mt-2 text-white mb-0">
                      Total usage (MWh)
                    </p>
                  </div>
                </el-col>
                <el-col :span="7" class="pl-0 x-y-center">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="
                      require('@/assets/images/dashboard/home/FuelAvoided.png')
                    "
                    fit="cover"
                  />
                </el-col>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
      <el-row class="m-0 width-100-p mt-3">
        <el-col class="d-flex align-item-center px-2" :span="24">
          <div class="row m-0 width-100-p">
            <div
              class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
            >
              <SessionTable :filterProp="filter" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Filter from "../common/filter.vue";
import { sessionChart } from "./config.js";
import UserService from "../../../services/user.service";
import SessionTable from "./sessionTable.vue";
export default {
  props: {},
  components: {
    Filter,
    SessionTable,
  },
  data() {
    return {
      selectedPeriod: "All",
      series: sessionChart.series,
      chartOptions: sessionChart.chartOptions,

      states: [],
      filter: {
        state: "",
        startDate: null,
        endDate: null,
      },
      SessionData: [],
      pendingSessionData: [],
      loading: false,
      loadingChart: false,
    };
  },
  watch: {},
  computed: {
    stateList() {
      return this.states;
    },
  },

  async mounted() {
    // console.log(this.$store.state.auth.user);
    await this.fetchData();
  },
  methods: {
    fillForm() {
      this.fillChargerByState();
    },
    fillChargerByState() {
      this.loadingChart = true;
      this.series[0].data = [];
      this.series[1].data = [];
      this.chartOptions.labels = [];
      this.SessionData.sessions.forEach((value) => {
        this.chartOptions.labels.push(value.group_by.toString());
        this.series[0].data.push(value.count_session);
        this.series[1].data.push(value.sum_usage);
      });
      setTimeout(() => {
        this.loadingChart = false;
      }, 300);
    },
    async fetchData() {
      let body = null;
      body = {
        start_date: this.filter.startDate,
        end_date: this.filter.endDate,
        customer_id: this.filter.customer_id,
        charger_name: this.filter.charger_name,
        state: this.filter.state == "All" ? "" : this.filter.state,
      };
      await UserService.DashboardSessionsData(body)
        .then(
          (response) => {
            // console.log(response);
            this.states = response.data.data.session_by_state.map(
              (session) => session.state
            );
            this.SessionData = response.data.data.session_data;
            this.fillForm();
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    filterHandle(event) {
      // console.log("Charger : ", event);
      this.filter = event;
      this.fetchData();
    },
  },
};
</script>

<style></style>
