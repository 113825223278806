<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="custom-popup">
    <div class="popup-header">
      <h3>{{ popupData.site_name }}</h3>
    </div>
    <div class="popup-body">
      <p>{{ popupData.state.toUpperCase() }}</p>
      <div class="availability">
        <div class="indicator"></div>
        <span>
          {{
            popupData.chargers_count == 1
              ? "1 Charger"
              : popupData.chargers_count + " Chargers"
          }}
          Available
        </span>
      </div>
    </div>
    <div class="chargers" v-if="showCharger" :v-loading="loading">
      <table class="styled-table w-100">
        <thead>
          <tr>
            <th>ChargerID</th>
            <th>Capacity</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in tableData" :key="i">
            <td>{{ item.charger_name }}</td>
            <td>{{ item.charger_capacity_kW }}</td>
            <td>{{ item.charger_type }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="popup-footer">
      <el-button
        @click="handleClick"
        class="view-more"
        link
        style="cursor: pointer"
      >
        {{ showCharger ? "View less" : "View more" }}
      </el-button>
    </div>
  </div>
</template>

<script>
import UserService from "../../../services/user.service";
export default {
  props: {
    popupData: Object,
  },
  data() {
    return {
      loading: false,
      showCharger: false,
      tableData: [],
    };
  },
  methods: {
    handleClick() {
      this.showCharger = !this.showCharger;
      if (this.showCharger) this.fetchAllChargingSite();
    },
    fetchAllChargingSite() {
      let filter = {
        charging_site_id: this.popupData.charger_site_id,
        customer_id: null,
      };
      this.loading = true;
      UserService.GetChargerList(filter, 1, 100)
        .then(
          (response) => {
            this.tableData = response.data.chargers;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup {
  margin-bottom: 65px !important;
  left: 40px;
}

.custom-popup {
  width: 250px;
  font-family: Arial, sans-serif;
}

.custom-popup .popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.custom-popup .popup-header h3 {
  margin: 0;
  color: #e74c3c;
  font-size: 18px;
}

.custom-popup .popup-body {
  padding: 10px;
}

.custom-popup .popup-body p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 14px;
}

.custom-popup .popup-body .amenities {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-popup .popup-body .amenities img {
  width: 24px;
  height: 24px;
}

.custom-popup .popup-body .availability {
  display: flex;
  align-items: center;
  gap: 5px;
}

.custom-popup .popup-body .availability .indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
}

.custom-popup .popup-footer {
  padding: 10px;
  text-align: right;
  border-top: 1px solid #ccc;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.custom-popup .popup-footer el-button {
  text-decoration: none;
  color: #0d3937;
  font-weight: bold;
}

.custom-popup .chargers {
  max-height: 150px;
  overflow-y: scroll;
}

.styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
}
.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 5px 5px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
