<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <el-col class="mb-6" :span="24">
    <div class="row m-0">
      <el-col :span="24" class="d-flex align-item-center px-0">
        <el-button
          color="#ddf0ac"
          @click.stop="frmNewShow = !frmNewShow"
          size="small"
          class="height-40 width-150 rounded-2 m-0"
        >
          <span class="me-primary"> New Message </span>
          <i class="fa fa-plus pl-2"></i>
        </el-button>
      </el-col>
      <el-col
        :span="24"
        class="d-flex align-item-center px-0 mt-3"
        v-if="frmNewShow"
      >
        <el-form
          class="width-100-p"
          ref="ruleFormRef"
          :rules="rules"
          :model="support"
          :label-position="`top`"
        >
          <el-form-item class="label-style" prop="subject" label="Subject: ">
            <el-input v-model="support.subject" />
          </el-form-item>
          <el-form-item
            class="label-style"
            prop="description"
            label="Description: "
          >
            <div class="w-100">
              <ckeditor
                height="100px"
                width="100%"
                :editor="editor"
                v-model="support.description"
                :config="editorConfig"
                class="w-100"
                @blur="handleBlur"
              ></ckeditor>
            </div>
            <div v-if="descriptionBlur" class="el-form-item__error">
              Please enter description.
            </div>
          </el-form-item>
          <form class="theme-form pt-3">
            <div class="mb-3">
              <el-upload
                ref="upload"
                class="upload-demo"
                :limit="1"
                :on-change="onChange"
                :auto-upload="false"
              >
                <template #trigger>
                  <el-button color="#0D3937" class="me-primary-light">
                    <i class="fa fa-paperclip pr-2"></i>
                    select file
                  </el-button>
                </template>
                <template #tip>
                  <div class="el-upload__tip text-warning">
                    limit 1 file, new file will cover the old file
                  </div>
                </template>
              </el-upload>
            </div>
          </form>
        </el-form>
      </el-col>
      <el-col
        :span="24"
        class="d-flex align-item-center px-0 mt-3"
        v-if="frmNewShow"
      >
        <el-row gutter="10" class="m-0">
          <el-button
            size="medium"
            class="me-primary float-right"
            @click.stop="handleSupportReg"
            color="#ddf0ac"
          >
            Submit <i class="fa fa-chevron-right ml-2 me-primary"></i>
          </el-button>
        </el-row>
      </el-col>
    </div>
  </el-col>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UserService from "../../services/user.service";
import { useToast } from "vue-toastification";

export default {
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      frmNewShow: false,
      // search: "",
      // fromDate: "",
      // toDate: "",
      // statusRow: 1,
      // filterOpen: false,
      // filter: {
      //   fromDate: "",
      //   toDate: "",
      //   statusRow: 1,
      // },
      // isNew: false,
      selectedFile: null,
      support: {
        subject: "",
        description: "",
        file: "",
      },
      editor: ClassicEditor,
      editorConfig: {
        uiColor: "#9AB8F3",
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            // "|",
            // "imageUpload",
            "|",
            "undo",
            "redo",
          ],
        },
      },
      rules: {
        subject: {
          required: true,
          message: "Please enter subject.",
          trigger: "blur",
        },
      },
      descriptionBlur: false,
    };
  },
  watch: {
    // search(val) {
    //   this.$emit("filterSearch", { search: val, kind: "search" });
    // },
  },
  methods: {
    // filterExpand9() {
    //   this.isNew = false;
    //   this.filterOpen = !this.filterOpen;
    // },
    handleBlur() {
      if (this.support.description === "") {
        this.descriptionBlur = true;
      } else {
        this.descriptionBlur = false;
      }
    },
    // handleRefresh9() {
    //   this.current_page = 1;
    //   this.fromDate = this.filter.fromDate;
    //   this.toDate = this.filter.toDate;
    //   this.statusRow = this.filter.statusRow;
    //   this.$emit("filterAction", { filter: this.filter, kind: "refresh" });
    // },
    // handleReset9() {
    //   this.current_page = 1;
    //   this.fromDate = "";
    //   this.toDate = "";
    //   this.search = "";
    //   this.statusRow = 1;
    //   this.filter.fromDate = "";
    //   this.filter.toDate = "";
    //   this.filter.statusRow = 1;
    //   this.$emit("filterAction", { filter: this.filter, kind: "reset" });
    // },
    // handleReload9() {
    //   this.$emit("filterAction", { filter: this.filter, kind: "reload" });
    // },
    // handleNew9() {
    //   this.isNew = !this.isNew;
    //   this.filterOpen = false;
    //   this.$emit("filterAction", { filter: this.filter, kind: "new" });
    // },
    // handleExportFile9() {
    //   this.$emit("filterAction", { filter: this.filter, kind: "excel" });
    // },
    handleSupportReset() {
      this.support.subject = "";
      this.support.description = "";
      this.selectedFile = null;
      this.$refs.upload.clearFiles();
    },
    async handleSupportReg() {
      if (this.support.description === "") {
        this.descriptionBlur = true;
      } else {
        this.descriptionBlur = false;
      }
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid && !this.descriptionBlur) {
          let formData = new FormData();
          formData.append("attach_file", this.selectedFile);
          formData.append("subject", this.support.subject);
          formData.append("description", this.support.description);
          UserService.SetSupport(formData)
            .then(
              (response) => {
                this.$emit("filterAction", {
                  support: response,
                  kind: "supportReg",
                });
                this.handleSupportReset();
                useToast().success(
                  " The new ticket was successfully registered ",
                  {
                    icon: "fa fa-check",
                    position: "bottom-right",
                    timeout: 2000,
                  }
                );
              },
              (error) => {
                this.loading = false;
                console.log(error);
                if (error.response.status == 401) {
                  this.$router.push({
                    path: "/",
                  });
                }
                useToast().error(
                  "There was an error in registering the ticket.",
                  {
                    icon: "fa fa-times",
                    position: "bottom-right",
                    timeout: 2000,
                  }
                );
              }
            )
            .catch((error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
              useToast().error(
                "There was an error in registering the ticket.",
                {
                  icon: "fa fa-times",
                  position: "bottom-right",
                  timeout: 2000,
                }
              );
            })
            .finally(() => (this.loading = false));
        } else {
          console.log("error submit!", fields);
        }
      });
    },

    onChange(event, fileList) {
      this.loading = true;
      const file = event.raw ? event.raw : null;
      // console.log(event);
      // console.log(file);
      // console.log(fileList);
      if (file.size / 1024 / 1024 > 1) {
        this.loading = false;
        this.$message.error(`file can not be greater than 1MB!`);
        fileList.pop();
      } else {
        if (file) {
          this.selectedFile = file;
          // console.log(this.selectedFile);
        }
      }
    },
  },
};
</script>

<style>
.action-pos {
  position: absolute !important;
  top: 15px !important;
  right: 20px !important;
}

.rotate-scale-up {
  -webkit-animation: rotate-scale-up 0.4s linear both;
  animation: rotate-scale-up 0.4s linear both;
}

@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(90deg);
    transform: scale(2) rotateZ(09deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(180deg);
    transform: scale(1) rotateZ(180deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(90deg);
    transform: scale(2) rotateZ(90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(180deg);
    transform: scale(1) rotateZ(180deg);
  }
}
</style>
