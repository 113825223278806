export const estimatorChart = {
    series: [
        {
            name: "Project Emission (tCO2-e)",
            group:"year",
            type: "bar",
            data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
            // data: [],
            color: "#DDF0AC",
        },
        {
            name: "Abatement (tCO2-e)",
            group:"year",
            type: "bar",
            data: [440, 505, 414, 671, 227, 413, 201, 352, 452, 320],
            // data: [],
            color: "#78BE20",
        },
        {
            name: "Abatement per MWh",
            group:"year",
            type: "line",
            data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
            // data: [],
            color: "#E73B30",
        },
    ],
    chartOptions: {
        chart: {
            type: 'line',
            height: 350,
            stacked: true,
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '35%',
                // endingShape: 'rounded',
                dataLabels : {
                    position: "top",
                },
            },
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [2],

        },
        stroke: {
            // show: true,
            width: [0, 0, 4],
            // colors: ['transparent']
        },
        labels: [
            "Year 1",
            "Year 2",
            "Year 3",
            "Year 4",
            "Year 5",
            "Year 6",
            "Year 7",
            "Year 8",
            "Year 9",
            "Year 10",
        ],
        // labels: [],
        xaxis: {
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,

                },
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
            },

        },
        yaxis: [{
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                title: {
                    text: "tCO2",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
            {
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                opposite: true,
                title: {
                    text: "tCO2/mwH",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
        ],
        legend: {
            position: "top",
            horizontalAlign: "left",
            labels: {
                colors: "#FFFFFF",
            },
            markers: {
                fillColors: ["#78BE20", "#DDF0AC", "#E73B30" ],
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: [{
                    formatter: function(val) {
                        return val
                    }
                },
                {
                    formatter: function(val) {
                        return val + " Kwh"
                    }
                }
            ]
        }
    },
}