<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    class="ribbon-wrapper card bg-me-primary"
    :style="[filterOpen ? '' : 'height:70px;', 'padding-top:55px !important']"
  >
    <div class="card-body bg-white">
      <div class="ribbon ribbon-clip ribbon-success me-primary-light">
        Filter
      </div>
      <el-row class="action-pos">
        <el-button
          class="mr-1"
          size="small"
          @click="handleExportFile"
          color="#ddf0ac"
        >
          <i class="fa fa-file-excel-o me-primary"></i>
        </el-button>
        <el-button-group>
          <el-button
            size="small"
            class="me-primary"
            @click="handlePerv"
            color="#ddf0ac"
          >
            <i class="fa fa-angle-left pr-2 me-primary"></i>
            Prev
          </el-button>
          <el-button
            size="small"
            class="me-primary"
            @click="handleNext"
            color="#ddf0ac"
          >
            Next
            <i class="fa fa-angle-right pl-2 me-primary"></i>
          </el-button>
        </el-button-group>
        <el-button
          class="ml-1 me-primary"
          size="small"
          @click="filterExpand"
          color="#ddf0ac"
        >
          <i v-if="filterOpen" class="fa fa-minus rotate-scale-up me-primary">
          </i>
          <i v-else class="fa fa-plus rotate-scale-up me-primary"></i>
        </el-button>
      </el-row>
      <el-form
        :label-position="`top`"
        :style="[filterOpen ? '' : 'display:none;']"
      >
        <el-space fill style="width: 100%">
          <el-row gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item class="mb-1" label="From Date : ">
                <el-date-picker
                  v-model="filter.fromDate"
                  type="date"
                  placeholder="Pick a day"
                  size="default"
                  class="w-100"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item class="mb-1" label="To Date : ">
                <el-date-picker
                  v-model="filter.toDate"
                  type="date"
                  placeholder="Pick a day"
                  size="default"
                  class="w-100"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item class="mb-1" label="Search : ">
            <input
              class="form-control"
              type="text"
              v-model="search"
              placeholder="Type to search  by site name, charger id, port search-input"
            />
          </el-form-item>
          <el-form-item class="mb-1" label="Status : ">
            <el-radio-group size="large" v-model="filter.statusRow">
              <el-radio size="large" :label="1">All</el-radio>
              <el-radio class="font-warning" size="large" :label="2">
                Warning
              </el-radio>
              <el-radio class="font-danger" size="large" :label="3">
                Error
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item class="mb-1 float-right"> -->

          <!-- </el-form-item> -->
        </el-space>
      </el-form>
      <el-row
        gutter="10"
        class="float-right"
        :style="[filterOpen ? '' : 'display:none;']"
      >
        <el-button
          size="medium"
          class="me-primary-light float-right"
          @click.stop="handleRefresh"
          color="#0D3937"
        >
          <i class="fa fa-refresh me-2 me-primary-light"></i> Apply
        </el-button>
        <el-button
          size="medium"
          class="me-primary float-right"
          @click.stop="handleReset"
          color="#ddf0ac"
        >
          <i class="icofont icofont-eraser me-2"></i> Reset
        </el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      fromDate: "",
      toDate: "",
      statusRow: 1,
      filterOpen: false,
      filter: {
        fromDate: "",
        toDate: "",
        statusRow: 1,
      },
    };
  },
  watch: {
    search(val) {
      this.$emit("filterSearch", { search: val, kind: "search" });
    },
  },
  methods: {
    filterExpand() {
      this.filterOpen = !this.filterOpen;
    },
    handleRefresh() {
      this.current_page = 1;
      // console.log(this.fromDate, this.toDate);
      this.fromDate = this.filter.fromDate;
      this.toDate = this.filter.toDate;
      this.statusRow = this.filter.statusRow;
      this.$emit("filterAction", { filter: this.filter, kind: "refresh" });
    },
    handleReset() {
      this.current_page = 1;
      // console.log(this.fromDate, this.toDate);
      this.fromDate = "";
      this.toDate = "";
      this.search = "";
      this.statusRow = 1;
      this.filter.fromDate = "";
      this.filter.toDate = "";
      this.filter.statusRow = 1;
      this.$emit("filterAction", { filter: this.filter, kind: "reset" });
    },
    handlePerv() {
      this.$emit("filterAction", { filter: this.filter, kind: "prev" });
    },

    handleNext() {
      this.$emit("filterAction", { filter: this.filter, kind: "next" });
    },
    handleExportFile() {
      this.$emit("filterAction", { filter: this.filter, kind: "excel" });
    },
  },
};
</script>

<style>
.action-pos {
  position: absolute !important;
  top: 15px !important;
  right: 20px !important;
}

.rotate-scale-up {
  -webkit-animation: rotate-scale-up 0.4s linear both;
  animation: rotate-scale-up 0.4s linear both;
}

@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(90deg);
    transform: scale(2) rotateZ(09deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(180deg);
    transform: scale(1) rotateZ(180deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(90deg);
    transform: scale(2) rotateZ(90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(180deg);
    transform: scale(1) rotateZ(180deg);
  }
}
</style>
