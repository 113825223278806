export const errorIntensityChart = {
    series: [
        // {
        //     name: "Session Duration",
        //     data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
        // },
        // {
        //     name: "Page Views",
        //     data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
        // },
        // {
        //     name: 'Total Visits',
        //     data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
        // }
    ],
    chartOptions: {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
        },
        dataLabels: {
            enabled: false
        },
        // stroke: {
        //     width: [
        //         4,
        //         // 4,
        //         // 4
        //     ],
        //     curve: 'straight',
        // },
        // title: {
        //     text: 'Total daily emission intensity calculation error',
        //     align: 'left',
        //     style: {
        //         color: "#FFFFFF",
        //         fontSize: "14px",
        //         fontWeight: "bold",
        //         fontFamily: "Helvetica, Arial, sans-serif",
        //     },
        // },
        legend: {
            tooltipHoverFormatter: function (val, opts) {
                return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
            },
            labels: {
                colors: "#FFFFFF",
            },
        },
        markers: {
            size: 0,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: [],
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        yaxis: [{
            decimalsInFloat: 0,
            tickAmount: 5,
            tickPlacement: "between",
            title: {
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
            axisBorder: {
                show: false,
                color: "#FFFFFF",
            },
            axisTicks: {
                show: false,
                color: "#FFFFFF",
            },
        }, ],
        tooltip: {
            y: [{
                    title: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                // {
                //     title: {
                //         formatter: function (val) {
                //             return val
                //         }
                //     }
                // },
                // {
                //     title: {
                //         formatter: function (val) {
                //             return val;
                //         }
                //     }
                // }
            ]
        },
        grid: {
            borderColor: '#f1f1f1',
        },

    },



}

export const errorSentOutChart = {
    series: [],
    chartOptions: {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            tooltipHoverFormatter: function (val, opts) {
                return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
            },
            labels: {
                colors: "#FFFFFF",
            },
        },
        markers: {
            size: 0,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: [],
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        yaxis: [{
            decimalsInFloat: 0,
            tickAmount: 5,
            tickPlacement: "between",
            title: {
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
            axisBorder: {
                show: false,
                color: "#FFFFFF",
            },
            axisTicks: {
                show: false,
                color: "#FFFFFF",
            },
        }, ],
        tooltip: {
            y: [{
                title: {
                    formatter: function (val) {
                        return val
                    }
                }
            }, ]
        },
        grid: {
            borderColor: '#f1f1f1',
        },
    },
}

export const errorEmissionsChart = {
    series: [],
    chartOptions: {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            tooltipHoverFormatter: function (val, opts) {
                return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
            },
            labels: {
                colors: "#FFFFFF",
            },
        },
        markers: {
            size: 0,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: [],
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        yaxis: [{
            decimalsInFloat: 0,
            tickAmount: 5,
            tickPlacement: "between",
            title: {
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
            axisBorder: {
                show: false,
                color: "#FFFFFF",
            },
            axisTicks: {
                show: false,
                color: "#FFFFFF",
            },
        }, ],
        tooltip: {
            y: [{
                title: {
                    formatter: function (val) {
                        return val
                    }
                }
            }, ]
        },
        grid: {
            borderColor: '#f1f1f1',
        },
    },
}