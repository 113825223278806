<template>
  <el-row class="m-0 width-100-p mt-3">
    <el-col
      class="d-flex align-item-center p-2"
      :span="
        $vuetify.display.width >= 1500
          ? 6
          : $vuetify.display.width >= 500
          ? 12
          : 24
      "
    >
      <div
        class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <el-col :span="24" class="px-3">
          <h5 class="text-white">{{ dashboardData.fuel_avoided.title }}</h5>
        </el-col>
        <el-col :span="6" class="x-y-center">
          <el-image
            :src="require('@/assets/images/dashboard/home/FuelAvoided.png')"
            fit="cover"
          />
        </el-col>
        <el-col :span="18" class="y-center">
          <div class="width-100-p p-2">
            <p class="font-20-b text-white mb-0">
              {{ dashboardData.fuel_avoided.value.toLocaleString() }}
            </p>
          </div>
        </el-col>
      </div>
    </el-col>
    <el-col
      class="d-flex align-item-center p-2"
      :span="
        $vuetify.display.width >= 1500
          ? 6
          : $vuetify.display.width >= 500
          ? 12
          : 24
      "
    >
      <div
        class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <el-col :span="24" class="px-3">
          <h5 class="text-white">{{ dashboardData.kilometer_driven.title }}</h5>
        </el-col>
        <el-col :span="6" class="x-y-center">
          <el-image
            :src="require('@/assets/images/dashboard/home/KilometerDriven.png')"
            fit="cover"
          />
        </el-col>
        <el-col :span="18" class="y-center">
          <div class="width-100-p p-2">
            <p class="font-20-b text-white mb-0">
              {{ dashboardData.kilometer_driven.value.toLocaleString() }}
            </p>
          </div>
        </el-col>
      </div>
    </el-col>
    <el-col
      class="d-flex align-item-center p-2"
      :span="
        $vuetify.display.width >= 1500
          ? 6
          : $vuetify.display.width >= 500
          ? 12
          : 24
      "
    >
      <div
        class="row m-0 py-4 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <el-col :span="24" class="px-3">
          <h5 class="text-white">
            {{ dashboardData.charger_count.title }}
          </h5>
        </el-col>
        <el-col :span="6" class="x-y-center">
          <el-image
            :src="require('@/assets/images/dashboard/home/NoxSox.png')"
            fit="cover"
          />
        </el-col>
        <el-col :span="18" class="y-center">
          <div class="width-100-p p-2">
            <p class="font-20-b text-white mb-0">
              {{ dashboardData.charger_count.value.toLocaleString() }}
            </p>
          </div>
        </el-col>
      </div>
    </el-col>
    <el-col
      class="d-flex align-item-center p-2"
      :span="
        $vuetify.display.width >= 1500
          ? 6
          : $vuetify.display.width >= 500
          ? 12
          : 24
      "
    >
      <div
        class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <el-col :span="24" class="px-3">
          <h5 class="text-white">
            {{ dashboardData.number_of_session.title }}
          </h5>
        </el-col>
        <el-col :span="6" class="x-y-center">
          <el-image
            :src="require('@/assets/images/dashboard/home/FuelAvoided.png')"
            fit="cover"
          />
        </el-col>
        <el-col :span="18" class="y-center">
          <div class="width-100-p p-2">
            <p class="font-20-b text-white mb-0">
              {{ dashboardData.number_of_session.value.toLocaleString() }}
            </p>
          </div>
        </el-col>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    dataProp: {
      type: Array,
      default(rawProps) {
        return rawProps;
      },
    },
  },
  components: {
    // ShowDetail,
  },
  data() {
    return {
      show: true,
      dashboardData: {
        fuel_avoided: { title: "", value: "0" },
        kilometer_driven: { title: "", value: "0" },
        charger_count: { title: "", value: "0" },
        number_of_session: { title: "", value: "0" },
      },
    };
  },
  watch: {
    dataProp: {
      handler: function (newValue) {
        this.dashboardData.fuel_avoided = newValue.fuel_avoided;
        this.dashboardData.kilometer_driven = newValue.distance_traveled;
        this.dashboardData.charger_count = newValue.charger_count; //missing
        this.dashboardData.number_of_session = newValue.session_count;
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
