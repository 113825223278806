<template>
  <div>
    <div class="container-fluid p-0">
      <!-- <div class="comingsoon auth-bg-video pa-0"> -->
      <!-- <video
          class="bgvideo-comingsoon"
          id="bgvid"
          poster="@/assets/images/other-images/coming-soon-bg-1.jpg"
          playsinline=""
          autoplay=""
          muted=""
          loop=""
        >
          <source src="@/assets/video/auth-bg-1.mp4" type="video/mp4" />
        </video> -->
      <div class="comingsoon-inner text-center">
        <div class="row">
          <div
            class="col-6 p-0 bg-me-primary"
            :class="$vuetify.display.width >= 1100 ? 'col-6' : 'col-12'"
          >
            <div class="login-card">
              <div>
                <div class="d-flex justify-content-center">
                  <a class="logo" style="height: 70px; width: 115px">
                    <img
                      class="img-fluid for-light"
                      src="../assets/images/logo/logo-light.png"
                      alt="looginpage"
                    />
                    <img
                      class="img-fluid for-dark"
                      src="../assets/images/logo/logo-dark.png"
                      alt="looginpage"
                    />
                  </a>
                </div>
                <div
                  class="login-main"
                  style="background-color: unset !important"
                >
                  <el-form
                    ref="ruleFormRef"
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    class="theme-form me-primary-light"
                    style="color: white !important"
                  >
                    <h4 class="mb-9">Reset Your Password</h4>
                    <el-form-item prop="email">
                      <label class="col-form-label">Email Address</label>
                      <el-input
                        size="large"
                        v-model="ruleForm.email"
                        placeholder="test@gmail.com"
                      >
                      </el-input>
                    </el-form-item>
                    <div class="form-group mb-0">
                      <div class="text-end mt-6">
                        <el-button
                          color="#0d3937"
                          class="btn-block w-100 btn-auth-primary-light"
                          @click.prevent="submit('ruleFormRef')"
                          :loading="loading"
                        >
                          Send
                        </el-button>
                      </div>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 p-0" v-if="$vuetify.display.width >= 1100">
            <!-- <el-image
              class="width-100-p height-100-p"
              src="@/assets/images/login-bg-2.jpg"
              fit="cover"
            /> -->
            <img
              class="img-fluid for-light width-100-p height-100-p"
              src="../assets/images/login-bg-1.jpg"
              alt="loginpage"
            />
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "forgetPass",
  components() {},
  data() {
    return {
      loading: false,
      snackbar: false,
      showPass: false,
      ruleForm: {
        email: "",
      },
      rules: {
        email: [
          {
            type: "email",
            message: "Please enter valid email.",
            trigger: "blur",
          },
          {
            required: true,
            message: "Please enter your email.",
            trigger: "blur",
          },
        ],
      },
    };
  },

  created() {},

  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch("auth/forgetPass", this.ruleForm).then(
            (resp) => {
              console.log(resp);
              this.loading = false;
              this.$router.push({ path: "/" });
            },
            (error) => {
              console.log(error);
              this.loading = false;
              this.snackbar = true;
              useToast().error("An error has occurred.", {
                icon: "fa fa-times",
                position: "bottom-right",
                timeout: 2000,
              });
            }
          );
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
.el-input__wrapper {
  padding: 1px !important;
  border-radius: 6px !important;
}

.el-input__wrapper > input {
  border-radius: 6px !important;
}

.el-input__suffix {
  position: absolute;
  right: 8px;
  /* top: 3px; */
}

.show-pass {
  position: absolute;
  top: 36px;
  right: 25px;
  color: var(--theme-deafult);
  cursor: pointer;
}

.login-card {
  background-image: none !important;
  min-height: none !important;
}

.login-main {
  background-color: #00000078 !important;
}
</style>
