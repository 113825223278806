<template>
  <div>
    <el-upload
      class="upload-demo width-100-p"
      ref="simple_upload"
      action="#"
      :limit="1"
      :auto-upload="false"
      drag
      :on-change="onChange"
      :on-remove="onRemove"
    >
      <i class="fa fa-cloud-upload f-46"></i>
      <div class="el-upload__text text-white">
        Drop file here or <em>click to upload</em>
      </div>
      <template #tip>
        <div class="el-upload__tip text-white">
          <span> xlsx files with a size less than 1MB </span>
          <div v-if="!hasSelectedFile" class="font-10" style="color: #f56c6c">
            Please select xlsx file.
          </div>
        </div>
      </template>
    </el-upload>
    <!-- <form class="theme-form mt-3">
      <div class="mb-3">
        <label for="exampleInputPassword1"> Subject: </label>
        <input class="form-control" v-model="subject" />
      </div>
    </form>
    <form class="theme-form">
      <div class="mb-3">
        <label for="exampleInputPassword1"> Comment: </label>
        <textarea class="form-control" v-model="comment" rows="3"></textarea>
      </div>
    </form> -->
    <el-form
      ref="ruleFormRef"
      class="frm-upload"
      :rules="rules"
      :model="simpleCharger"
      :label-position="`top`"
    >
      <el-form-item prop="subject" label="Subject: ">
        <el-input v-model="simpleCharger.subject" />
      </el-form-item>
      <el-form-item prop="comment" label="Comment: ">
        <el-input v-model="simpleCharger.comment" :rows="3" type="textarea" />
      </el-form-item>
    </el-form>
    <div class="email-top compose-border">
      <div class="row">
        <div class="col-sm-12 btn-middle float-right">
          <el-button
            class="width-120 me-primary-light float-right"
            @click="handleSend"
            color="#0D3937"
          >
            <i class="fa fa-paper-plane me-2"></i> Send
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CKEditor from "@ckeditor/ckeditor5-vue";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UserService from "../../services/user.service";
import { useToast } from "vue-toastification";
export default {
  components: {
    // ckeditor: CKEditor.component,
  },
  data() {
    return {
      selectedFile: null,
      simpleCharger: {
        subject: "",
        comment: "",
      },
      loading: false,
      rules: {
        subject: {
          required: true,
          message: "Please enter subject.",
          trigger: "blur",
        },
        comment: {
          required: true,
          message: "Please enter comment.",
          trigger: "blur",
        },
      },
      hasSelectedFile: true,
    };
  },
  methods: {
    onChange(event, fileList) {
      const file = event.raw ? event.raw : null;
      if (
        file.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        file.type !== "application/vnd.ms-excel"
      ) {
        this.$message.error(`select an Excel format!`);
        fileList.pop();
      } else if (file.size / 1024 / 1024 > 1) {
        this.$message.error(`file can not be greater than 1MB!`);
        fileList.pop();
      } else {
        if (file) {
          this.selectedFile = file;
          this.hasSelectedFile = true;
        }
      }
    },
    onRemove(event, fileList) {
      console.log(event, fileList);
      this.selectedFile = null;
    },

    async handleSend() {
      if (this.selectedFile == null) this.hasSelectedFile = false;
      else this.hasSelectedFile = true;
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid && this.selectedFile != null) {
          this.loading = true;
          let formData = new FormData();
          formData.append("excel_file", this.selectedFile);
          formData.append("subject", this.simpleCharger.subject);
          formData.append("message", this.simpleCharger.comment);
          UserService.setSimpleChargerUpload(formData)
            .then(
              (response) => {
                console.log(response);
                this.simpleCharger.subject = "";
                this.simpleCharger.comment = "";
                this.selectedFile = null;
                this.$refs.simple_upload.clearFiles();
                useToast().success(" Send session file successfully ", {
                  icon: "fa fa-check",
                  position: "bottom-right",
                  timeout: 2000,
                });
              },
              (error) => {
                this.loading = false;
                console.log(error);
                if (error.response.status == 401) {
                  this.$router.push({
                    path: "/",
                  });
                }
                useToast().error(" There was an error sending the file.", {
                  icon: "fa fa-times",
                  position: "bottom-right",
                  timeout: 2000,
                });
              }
            )
            .catch((error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
              useToast().error(" There was an error sending the file.", {
                icon: "fa fa-times",
                position: "bottom-right",
                timeout: 2000,
              });
            })
            .finally(() => (this.loading = false));
        } else {
          console.log("error submit!", fields);
        }
      });
    },
  },
};
</script>

<style>
.ck-content {
  height: 300px;
}

.frm-upload > .el-form-item > label {
  color: white !important;
}
</style>
