<!-- eslint-disable vue/no-unused-vars -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-fluid">
    <Breadcrumbs main="Charging Site" :path="breadcrumbPath" />
    <div class="row starter-main">
      <!-- <div class="col-xl-12 xl-100 col-lg-12 box-col-12">
        <div class="card">
          <div
            class="card-header bg-me-primary py-3"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <h6 class="pull-left me-primary-light mb-0">Charging Site</h6>
          </div>
          <div class="card-body" :style="[filterOpen ? '' : 'height:50px;']">
            <div class="ma-0" v-loading="loading">
              <div class="d-flex justify-center"> -->
      <div
        class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
      >
        <el-col :span="24">
          <h5 class="text-white mt-3 ml-1">Charging Site</h5>
        </el-col>
        <el-form
          ref="ruleFormRef"
          :rules="rules"
          :model="chargingSiteData"
          :label-position="`top`"
          :style="[filterOpen ? '' : 'display:none;']"
        >
          <el-space fill style="width: 100%">
            <el-form-item class="label-style" prop="title" label="Site Name: ">
              <el-input v-model="chargingSiteData.title" />
            </el-form-item>

            <el-row :gutter="20">
              <el-col :span="$vuetify.display.width > 650 ? 8 : 24">
                <el-form-item
                  class="label-style"
                  prop="country"
                  label="Country: "
                >
                  <el-input v-model="chargingSiteData.country" />
                </el-form-item>
              </el-col>
              <el-col :span="$vuetify.display.width > 650 ? 8 : 24">
                <el-form-item class="label-style" prop="state" label="State: ">
                  <el-input v-model="chargingSiteData.state" />
                </el-form-item>
              </el-col>
              <el-col :span="$vuetify.display.width > 650 ? 8 : 24">
                <el-form-item class="label-style" prop="city" label="City: ">
                  <el-input v-model="chargingSiteData.city" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="$vuetify.display.width > 650 ? 12 : 24">
                <el-form-item
                  class="label-style"
                  prop="suburb"
                  label="Suburb: "
                >
                  <el-input v-model="chargingSiteData.suburb" />
                </el-form-item>
              </el-col>
              <el-col :span="$vuetify.display.width > 650 ? 12 : 24">
                <el-form-item
                  class="label-style"
                  prop="street"
                  label="Street: "
                >
                  <el-input v-model="chargingSiteData.street" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                  class="label-style"
                  prop="address_comment"
                  label="Address Comment: "
                >
                  <el-input
                    v-model="chargingSiteData.address_comment"
                    type="textarea"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item class="label-style" prop="lat" label="Lat: ">
                  <el-input v-model="chargingSiteData.lat" readonly />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="label-style" prop="lng" label="Lng: ">
                  <el-input v-model="chargingSiteData.lng" readonly />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  class="label-style"
                  prop="map"
                  label="Map: "
                  style="position: relative"
                >
                  <el-button
                    type="success"
                    link
                    style="
                      position: absolute;
                      right: 0;
                      top: -20px;
                      font-size: 11px;
                    "
                    @click="showMap = !showMap"
                  >
                    <!-- <i v-if="!showMap" class="fa fa-caret-up"></i> -->
                    <i v-if="!showMap" class="fa fa-caret-up"></i>
                    <i v-else class="fa fa-caret-down"></i>
                    <span class="pl-2"> Show Map </span>
                  </el-button>
                  <l-map
                    v-if="showMap"
                    ref="map"
                    style="height: 300px"
                    v-model:zoom="map.zoom"
                    :center="map.center"
                  >
                    <l-tile-layer
                      :url="map.url"
                      :attribution="map.attribution"
                      layer-type="base"
                      name="OpenStreetMap"
                    ></l-tile-layer>
                    <l-marker
                      :lat-lng="map.markerLatLng"
                      draggable
                      @dragend="updateMarker"
                    ></l-marker>
                  </l-map>
                </el-form-item>
              </el-col>
            </el-row>
          </el-space>
        </el-form>
      </div>
      <!-- </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-xl-12 xl-100 col-lg-12 box-col-12"> -->
      <!-- <div class="card"> -->
      <div
        class="row mt-2 m-0 bg-me-primary border-color-light width-100-p rounded-4"
      >
        <el-col :span="24">
          <h5 class="text-white mt-3 ml-1">Chargers & Ports</h5>
        </el-col>
        <!-- <div
            class="card-header bg-me-primary py-3"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <h6 class="pull-left me-primary-light mb-0">Chargers & Ports</h6>
            <el-row class="action-pos">
              <el-button-group>
                <el-button
                  size="small"
                  class="me-primary"
                  @click="handleReload"
                  color="#ddf0ac"
                >
                  Save
                </el-button>
              </el-button-group>
              <el-button
                class="ml-1 me-primary"
                size="small"
                @click="handleChargerExpand"
                color="#ddf0ac"
              >
                <i
                  v-if="filterChargerOpen"
                  class="fa fa-minus rotate-scale-up me-primary"
                >
                </i>
                <i v-else class="fa fa-plus rotate-scale-up me-primary"></i>
              </el-button>
            </el-row>
          </div> -->
        <div class="card-body">
          <div class="ma-0" v-loading="loading">
            <div class="d-flex justify-center">
              <el-table
                ref="chargerTableRef"
                :data="filter_charger_data"
                :fit="true"
                height="300"
                class="my-2 data-table"
                @row-click="handleRowClick"
                row-key="id"
                scrollbar-always-on
              >
                <!-- <el-table-column type="index" width="50" /> -->
                <el-table-column
                  v-if="$vuetify.display.width >= 500"
                  type="expand"
                  class="coloo"
                >
                  <template #default="props">
                    <div class="pa-5">
                      <el-table
                        border
                        :data="props.row.charger_ports"
                        class="port-tbl data-table"
                        scrollbar-always-on
                      >
                        <el-table-column
                          class="port-cell"
                          prop="port_id"
                          label="Port ID"
                          width="150"
                        />
                        <el-table-column
                          prop="port_type"
                          label="Port Type"
                          width="150"
                        />
                        <el-table-column
                          prop="decommissioning_date"
                          label="Decommissioning Date"
                          width="200"
                        >
                          <template #default="scope">
                            {{
                              formatDate(
                                scope.row.decommissioning_date,
                                "YYYY/MM/DD"
                              )
                            }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          v-if="
                            $store.state.auth.user != null &&
                            !$store.state.auth.user.is_auditor
                          "
                          prop="operation"
                          label="Operation"
                          width="250"
                        >
                          <template #default="scope">
                            <el-button
                              color="#0d3937"
                              @click.stop="
                                handlePortUpdate(
                                  props.row,
                                  scope.$index,
                                  scope.row
                                )
                              "
                              size="small"
                              class="me-primary-light"
                            >
                              <i class="fa fa-edit"></i>
                              <span class="pl-1">Update</span>
                            </el-button>
                            <el-button
                              color="#0d3937"
                              @click.stop="
                                handlePortDelete(props, scope.row, scope.$index)
                              "
                              size="small"
                              class="me-primary-light"
                            >
                              <i class="fa fa-trash"></i>
                              <span class="pl-1">Delete</span>
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Charger ID'"
                  :prop="'charger_name'"
                  :width="$vuetify.display.width <= 1800 ? 200 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Year Added'"
                  :prop="'year_added'"
                  :width="$vuetify.display.width <= 1800 ? 120 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Install Date'"
                  :prop="'install_date'"
                  :width="$vuetify.display.width <= 1800 ? 150 : ''"
                  sortable
                >
                  <template #default="scope">
                    {{ formatDate(scope.row.install_date, "YYYY/MM/DD") }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Operational Date'"
                  :prop="'operational_date'"
                  :width="$vuetify.display.width <= 1800 ? 150 : ''"
                  sortable
                >
                  <template #default="scope">
                    {{ formatDate(scope.row.operational_date, "YYYY/MM/DD") }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Decommissioning Date'"
                  :prop="'decommissioning_date'"
                  :width="$vuetify.display.width <= 1800 ? 180 : ''"
                  sortable
                >
                  <template #default="scope">
                    {{
                      formatDate(scope.row.decommissioning_date, "YYYY/MM/DD")
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Certificate'"
                  :prop="'certificate_number'"
                  :width="$vuetify.display.width <= 1800 ? 150 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Make'"
                  :prop="'make'"
                  :width="$vuetify.display.width <= 1800 ? 150 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Model'"
                  :prop="'model'"
                  :width="$vuetify.display.width <= 1800 ? 160 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Charger Type'"
                  :prop="'charger_type'"
                  :width="$vuetify.display.width <= 1800 ? 140 : ''"
                  sortable
                >
                  <template #default="scope">
                    <el-tag
                      :type="
                        scope.row.charger_type.toString().toUpperCase() === 'AC'
                          ? 'success'
                          : 'danger'
                      "
                      disable-transitions
                    >
                      {{ scope.row.charger_type.toString().toUpperCase() }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'On-Site Renewable'"
                  :prop="'onsite_renewable'"
                  :width="$vuetify.display.width <= 1800 ? 180 : ''"
                  sortable
                >
                  <template #default="scope">
                    <i
                      v-if="
                        scope.row.onsite_renewable.toString().toUpperCase() ==
                        'TRUE'
                      "
                      class="fa fa-check-square"
                    ></i>
                    <i v-else class="fa fa-square-o"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Battery'"
                  :prop="'onsite_battery'"
                  :width="$vuetify.display.width <= 1800 ? 170 : ''"
                  sortable
                >
                  <template #default="scope">
                    <i
                      v-if="
                        scope.row.onsite_battery.toString().toUpperCase() ==
                        'TRUE'
                      "
                      class="fa fa-check-square"
                    ></i>
                    <i v-else class="fa fa-square-o"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Included'"
                  :prop="'is_included'"
                  :width="$vuetify.display.width <= 1800 ? 170 : ''"
                  sortable
                >
                  <template #default="scope">
                    <i
                      v-if="
                        scope.row.is_included.toString().toUpperCase() == 'TRUE'
                      "
                      class="fa fa-check-square"
                    ></i>
                    <i v-else class="fa fa-square-o"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Charger Capacity (kW)'"
                  :prop="'charger_capacity_kW'"
                  :width="$vuetify.display.width <= 1800 ? 190 : ''"
                  sortable
                />
                <el-table-column
                  v-if="
                    $vuetify.display.width < 500 &&
                    $store.state.auth.user != null &&
                    !$store.state.auth.user.is_auditor
                  "
                  :label="'Operations'"
                  :prop="'operations'"
                  align="center"
                  width="220"
                >
                  <template #default="scope">
                    <el-button
                      color="#0d3937"
                      @click.stop="handleChargerUpdate(scope.$index, scope.row)"
                      size="small"
                      class="me-primary-light"
                    >
                      <i class="fa fa-edit"></i>
                      <span class="pl-1">Update</span>
                    </el-button>
                    <el-button
                      color="#0d3937"
                      @click.stop="handleChargerDelete(scope.$index, scope.row)"
                      size="small"
                      class="me-primary-light"
                    >
                      <i class="fa fa-trash"></i>
                      <span class="pl-1"> Delete</span>
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  v-else-if="
                    $store.state.auth.user != null &&
                    !$store.state.auth.user.is_auditor
                  "
                  fixed="right"
                  :label="'Operations'"
                  :prop="'operations'"
                  align="center"
                  width="220"
                >
                  <template #default="scope">
                    <el-button
                      color="#0d3937"
                      @click.stop="handleChargerUpdate(scope.$index, scope.row)"
                      size="small"
                      class="me-primary-light"
                    >
                      <i class="fa fa-edit"></i>
                      <span class="pl-1">Update</span>
                    </el-button>
                    <el-button
                      color="#0d3937"
                      @click.stop="handleChargerDelete(scope.$index, scope.row)"
                      size="small"
                      class="me-primary-light"
                    >
                      <i class="fa fa-trash"></i>
                      <span class="pl-1">Delete</span>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="d-flex justify-center">
            <el-pagination
              small
              background
              layout="prev, pager, next"
              :total="total_items"
              v-model:page-size="page_size"
              v-model:current-page="current_page"
              class="my-4"
            />
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <ShowDetail
      :open_modal="show_modal"
      :data_modal="data_modal"
      @close_modal="close_modal"
      @confirm_modal="confirm_modal"
    />
    <ShowPortDetail
      :open_modal="show_port_modal"
      :data_modal="data_port_modal"
      @close_modal="close_port_modal"
      @confirm_modal="confirm_port_modal"
    />
  </div>
</template>

<script>
import Breadcrumbs from "../../components/bread_crumbs.vue";
import XLSX from "xlsx";
import UserService from "../../services/user.service";
// import ShowDetail from "./dialogDetail.vue";
import { ElMessageBox } from "element-plus";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import { useToast } from "vue-toastification";
import moment from "moment";
import ShowDetail from "./dialogDetail.vue";
import ShowPortDetail from "./dialogPortDetail.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "users",
  components: {
    Breadcrumbs,
    LMap,
    LTileLayer,
    LMarker,
    ShowDetail,
    ShowPortDetail,
  },
  data() {
    return {
      breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "Charging Site",
          disabled: false,
          href: "/charging-sites",
        },
        {
          title: "Charging Site Detail",
          disabled: false,
          href: "/charging-sites-detail",
          active: true,
        },
      ],
      map: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 16,
        center: [-37.8136, 144.9631],
        markerLatLng: [-37.8136, 144.9631],
      },
      show: true,
      chargingSiteData: {
        customer_id: 0,
        full_name: "",
        phone: "",
        title: "",
        description: "",
        lat: 0,
        lng: 0,
        postcode: "",
        country: "",
        state: "",
        city: "",
        suburb: "",
        street: "",
        address_comment: "",
        elec_region: "",
        chargers: [],
      },
      search: "",
      current_page: 1,
      page_size: 20,
      total_items: 100,
      loading: false,
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      current_charger: null,
      show_port_modal: false,
      data_port_modal: null,
      current_port_row_index: null,
      filterOpen: true,
      rules: {
        title: {
          required: true,
          message: "Please enter site title.",
          trigger: "blur",
        },
        lat: {
          type: "number",
          //   message: "lat must be digit",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
              if (!this.isNumeric(value)) {
                reject("lat must be digit"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
        lng: {
          type: "number",
          //   message: "lng must be digit",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
              if (!this.isNumeric(value)) {
                reject("lng must be digit"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
      },
      tableData: [
        {
          id: 1,
          date: "2016-05-02",
          name: "wangxiaohu",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          id: 2,
          date: "2016-05-04",
          name: "wangxiaohu",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          id: 3,
          date: "2016-05-01",
          name: "wangxiaohu",
          address: "No. 189, Grove St, Los Angeles",
          children: [
            {
              id: 31,
              date: "2016-05-01",
              name: "wangxiaohu",
              address: "No. 189, Grove St, Los Angeles",
            },
            {
              id: 32,
              date: "2016-05-01",
              name: "wangxiaohu",
              address: "No. 189, Grove St, Los Angeles",
            },
          ],
        },
        {
          id: 4,
          date: "2016-05-03",
          name: "wangxiaohu",
          address: "No. 189, Grove St, Los Angeles",
        },
      ],
      showMap: false,
    };
  },
  watch: {},
  computed: {
    filter_charger_data() {
      let list = this.chargingSiteData.chargers.filter((item) => {
        return (
          (item.charger_name === undefined ? "" : item.charger_name)
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1 ||
          (item.certificate_number === undefined ? "" : item.certificate_number)
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1 ||
          (item.model === undefined ? "" : item.model)
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.total_items = list.length;
      let paginated = this.paginate(list, this.page_size, this.current_page);
      return paginated;
    },
  },
  mounted() {
    this.fetchChargingData();
  },
  methods: {
    fetchChargingData() {
      let body = {
        site_id: this.$route.query.charging_site_id
      };
      UserService.getChargingSites(body)
        .then(
          (response) => {
            this.chargingSiteData = response.data.charging_site;
            this.map.center[0] = this.chargingSiteData.lat;
            this.map.center[1] = this.chargingSiteData.lng;
            this.map.markerLatLng = this.map.center;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    updateMarker(event) {
      this.chargingSiteData.lat = event.target._latlng.lat;
      this.chargingSiteData.lng = event.target._latlng.lng;
    },

    async handleSave() {
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid) {
          UserService.updateChargingSite(this.chargingSiteData)
            .then(
              (response) => {
                this.chargingSiteData = response.data.charging_site;
                this.map.center[0] = this.chargingSiteData.lat;
                this.map.center[1] = this.chargingSiteData.lng;
                this.map.markerLatLng = this.map.center;
                useToast().success(
                  " The charging site has been updated successfully ",
                  {
                    icon: "fa fa-check",
                    position: "bottom-right",
                    timeout: 2000,
                  }
                );
              },
              (error) => {
                this.loading = false;
                console.log(error);
                if (error.response.status == 401) {
                  this.$router.push({
                    path: "/",
                  });
                }
                useToast().error(
                  " An error occurred while updating the charging site.",
                  {
                    icon: "fa fa-times",
                    position: "bottom-right",
                    timeout: 2000,
                  }
                );
              }
            )
            .catch((error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
              useToast().error(
                " An error occurred while updating the charging site.",
                {
                  icon: "fa fa-times",
                  position: "bottom-right",
                  timeout: 2000,
                }
              );
            })
            .finally(() => (this.loading = false));
        } else {
          console.log("error submit!", fields);
        }
      });
    },

    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    formatDate(date, format) {
      if (date != null && date != "") return moment(date).format(format);
      else return null;
    },
    handleExpand() {
      this.filterOpen = !this.filterOpen;
    },

    paginate(array, page_size, page_number) {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },

    handleChargerUpdate(index, data) {
      this.current_row_index = index;
      data.customer_id = this.chargingSiteData.customer_id;
      this.data_modal = JSON.stringify(data);
      this.show_modal = true;
    },

    // eslint-disable-next-line no-unused-vars
    close_modal(event) {
      this.show_modal = false;
    },

    confirm_modal(event) {
      if (this.chargingSiteData.chargers.length == this.current_row_index)
        this.chargingSiteData.chargers.push(event);
      else this.chargingSiteData.chargers[this.current_row_index] = event;
      this.show_modal = false;
    },

    handlePortUpdate(charger, index, data) {
      this.current_port_row_index = index;
      this.current_charger = charger;
      this.data_port_modal = JSON.stringify(data);
      this.show_port_modal = true;
      // console.log(charger, index, data);
    },

    // eslint-disable-next-line no-unused-vars
    close_port_modal(event) {
      this.show_port_modal = false;
    },

    confirm_port_modal(event) {
      if (
        this.current_charger.charger_ports.length == this.current_port_row_index
      )
        this.current_charger.charger_ports.push(event);
      else
        this.current_charger.charger_ports[this.current_port_row_index] = event;
      this.show_port_modal = false;
    },

    handleExportFile() {
      if (this.filter_user.length > 0) {
        const myHeader = [];
        const ws = XLSX.utils.json_to_sheet(this.filter_user, {
          header: myHeader,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "ChargingSiteList.xlsx");
      }
    },

    reset_filter(event) {
      this.current_page = 1;
      this.search = "";
      this.filter = event.filter;
    },

    filterAction(event) {
      if (event.kind == "excel") {
        this.handleExportFile();
      } else if (event.kind == "reload") {
        this.handleReload();
      } else if (event.kind == "new") {
        this.handleNew();
      }
    },

    handleReload() {
      this.fetchChargingData();
    },

    // eslint-disable-next-line no-unused-vars
    handleRowClick(row, column, event) {
      this.$refs.chargerTableRef.setCurrentRow(row);
    },
    handleNew() {
      let data = {
        first_name: "",
        last_name: "",
        email: "",
        is_staff: false,
        is_superuser: false,
        is_active: true,
        customer_id: null,
        id: 0,
      };
      this.current_row_index = this.chargingSiteData.length;
      this.data_modal = JSON.stringify(data);
      this.show_modal = true;
    },
    filterSearch(event) {
      this.search = event.search;
    },

    handleChargerDelete(index, event) {
      ElMessageBox.confirm("Are you sure to delete this?", "info", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "Confirm Delete",
      })
        .then(() => {
          this.DeleteChargers(index, event);
        })
        .catch(() => {});
    },
    DeleteChargers(index, event) {
      UserService.deleteCharger(event.id)
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.chargingSiteData.chargers.splice(index, 1);
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    handlePortDelete(charger, event, index) {
      ElMessageBox.confirm("Are you sure to delete this?", "info", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "Confirm Delete",
      })
        .then(() => {
          this.DeleteChargerPorts(charger, event, index);
        })
        .catch(() => {});
    },
    DeleteChargerPorts(charger, event, index) {
      UserService.deleteChargerPort(event.id)
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.chargingSiteData.chargers[charger.$index].charger_ports.splice(
              index,
              1
            );
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-8);
}
.el-table .error-row {
  --el-table-tr-bg-color: var(--el-color-error-light-8);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.cell {
  text-align: center !important;
}

.starter-main .card-body ul {
  list-style-type: none !important;
}

.el-pager {
  -webkit-user-select: none !important;
  user-select: none !important;
  list-style: none !important;
  font-size: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}

.el-upload-list {
  margin: 10px 0 0 !important;
  padding: 0 !important;
  list-style: none !important;
  position: relative !important;
}

.action-pos {
  position: absolute !important;
  top: 10px !important;
  right: 20px !important;
}

/* .port-tbl
  > .el-table__inner-wrapper
  > .el-table__header-wrapper
  > .el-table__header
  > thead
  > tr
  > th {
  background-color: #ddf0ac !important;
  color: #0d3937 !important;
} */
</style>
