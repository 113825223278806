<template>
  <div class="container-fluid p-0">
    <div class="row widget-grid">
      <!-- form start -->
      <form>
        <div>
          <div
            class="mt-3 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <!-- filter of location -->
            <el-row class="m-0 width-100-p mt-3" gutter="20">
              <el-col :span="6">
                <div>
                  <Filter
                    id="location filter"
                    :stateProp="stateList"
                    @filterHandle="filterHandle"
                    :hasLocation="true"
                    :hasCustomDate="false"
                  />
                </div>
              </el-col>
            </el-row>

            <!-- checkbox for charging type -->
            <el-row class="m-0 width-100-p mt-3" gutter="20">
              <el-col :span="6">
                <div>
                  <input
                    type="checkbox"
                    id="type_ac"
                    name="type_ac"
                    v-model="form.showAC"
                  />
                  <label for="type_ac">AC</label><br />
                  <input
                    type="checkbox"
                    id="type_dc"
                    name="type_dc"
                    v-model="form.showDC"
                  />
                  <label for="type_dc">DC</label><br />
                </div>
              </el-col>
            </el-row>

            <!-- basic parameter -->
            <el-row class="m-0 width-100-p mt-3" gutter="20">
              <!-- Grid Emission Intensity (tCO2-e/MWh) -->
              <el-col :span="12">
                <div>
                  <label>Grid emission intensity (tCO2-e/MWh)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.b_gei"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.b_gei" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>

              <!-- Annual Emissions Intensity Improvement (%) -->
              <el-col :span="12">
                <div>
                  <label>Annual emissions intensity improvement (%)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.b_aeii"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.b_aeii" class="form-control digits" type="number" placeholder="e.g. 10" step="0.1" required> -->
                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row class="m-0 width-100-p mt-3" gutter="20">
              <!-- Percentage of Onsite Renewables (%) -->
              <el-col :span="12">
                <div>
                  <label>Percentage of onsite renewables (%)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.b_por"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.b_por" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>

              <!-- Fuel Emission Intensity (tCO2-e/gallon) -->
              <el-col :span="12">
                <div>
                  <label>Fuel emission intensity (tCO2-e/gallon)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.b_fei"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.b_fei" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>
            </el-row>
            <!-- basic parameter END-->
          </div>
        </div>

        <div>
          <div
            v-if="form.showAC"
            class="mt-3 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <el-row class="m-0 width-100-p mt-3">
              <el-col class="d-flex align-item-center px-2" :span="12">
                <h5 class="text-white mt-3 ml-1">AC charging parameters</h5>
              </el-col>
            </el-row>

            <!-- AC charging parameter -->
            <el-row class="m-0 width-100-p mt-3" gutter="20">
              <!-- Annual usage (MWh) -->
              <el-col :span="12">
                <div>
                  <label>Annual usage (MWh)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.ac_au"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.ac_au" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>

              <!-- Annual Growth in Usage (%) -->
              <el-col :span="12">
                <div>
                  <label>Annual growth in usage (%)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.ac_agu"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.ac_agu" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row class="m-0 width-100-p mt-3" gutter="20">
              <!-- MPG (miles/gallon) -->
              <el-col :span="12">
                <div>
                  <label>MPG (miles/gallon)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.ac_mpg"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.ac_mpg" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>

              <!-- AFEC (kWh/100 miles) -->
              <el-col :span="12">
                <div>
                  <label>AFEC (kWh/100 miles)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.ac_afec"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.ac_afec" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row class="m-0 width-100-p mt-3" gutter="20">
              <!-- Technology improvement rate -->
              <el-col :span="12">
                <div>
                  <label>Technology improvement rate</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.ac_tir"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.ac_tir" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>

              <!-- Charger efficiency -->
              <el-col :span="12">
                <div>
                  <label>Charger efficiency</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.ac_ce"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.ac_ce" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>
            </el-row>
            <!-- AC charging parameter END -->
          </div>
        </div>

        <div>
          <div
            v-if="form.showDC"
            class="mt-3 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <el-row class="m-0 width-100-p mt-3">
              <el-col class="d-flex align-item-center px-2" :span="12">
                <h5 class="text-white mt-3 ml-1">DC charging parameters</h5>
              </el-col>
            </el-row>

            <!-- DC charging parameter -->
            <el-row class="m-0 width-100-p mt-3" gutter="20">
              <!-- Annual usage (MWh) -->
              <el-col :span="12">
                <div>
                  <label>Annual usage (MWh)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.dc_au"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.dc_au" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>

              <!-- Annual Growth in Usage (%) -->
              <el-col :span="12">
                <div>
                  <label>Annual growth in usage (%)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.dc_agu"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.dc_agu" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row class="m-0 width-100-p mt-3" gutter="20">
              <!-- MPG (miles/gallon) -->
              <el-col :span="12">
                <div>
                  <label>MPG (miles/gallon)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.dc_mpg"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.dc_mpg" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>

              <!-- AFEC (kWh/100 miles) -->
              <el-col :span="12">
                <div>
                  <label>AFEC (kWh/100 miles)</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.dc_afec"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.dc_afec" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row class="m-0 width-100-p mt-3" gutter="20">
              <!-- Technology improvement rate -->
              <el-col :span="12">
                <div>
                  <label>Technology improvement rate</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.dc_tir"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.dc_tir" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>

              <!-- Charger efficiency -->
              <el-col :span="12">
                <div>
                  <label>Charger efficiency</label>
                  <div>
                    <el-input
                      size="large"
                      v-model.number="form.dc_ce"
                      style="width: 100%"
                      placeholder="e.g. 10"
                      class="mr-2 table-search"
                      required
                    />
                    <!-- <input v-model="form.dc_ce" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                  </div>
                </div>
              </el-col>
            </el-row>
            <!-- DC charging parameter END -->
          </div>
        </div>

        <!-- Add another scenario -->
        <!-- <div>


                        <div class="m-2 mt-3 bg-me-primary border-color-light rounded-4">

                            <el-row class="m-0 width-100-p mt-3">
                                <el-col class="d-flex align-item-center px-2" :span="12">
                                    <el-col class="d-flex align-item-center px-2" :span="3">
                                        <svg id="add-scenario" >
                                            <use href="@/assets/svg/icon-sprite.svg#add-scenario"></use>     
                                        </svg>
                                    </el-col>
                                    <el-col class="d-flex align-item-center px-2" :span="6">
                                        <label>Add another scenario</label>
                                    </el-col>
                                </el-col>
                            </el-row>
                        </div>

                </div> -->

        <div>
          <div>
            <el-row class="m-0 width-100-p mt-3">
              <el-col class="d-flex align-item-center px-2" :span="2">
                <!-- <button><span>Apply</span></button> -->
                <el-button
                  color="#ddf0ac"
                  @click="submitForm"
                  class="height-40 rounded-3 mr-1"
                >
                  <svg class="stroke-icon width-20 height-20">
                    <use
                      href="@/assets/svg/icon-sprite.svg#stroke-refresh"
                    ></use>
                  </svg>
                  <span class="me-primary pl-3"> Apply </span>
                </el-button>
              </el-col>

              <el-col class="d-flex align-item-center px-2" :span="2">
                <!-- <button><span>Reset</span></button> -->
                <el-button
                  color="#0D3937"
                  class="height-40 border-color-light rounded-3 ml-1"
                >
                  <svg class="stroke-icon width-20 height-20">
                    <use
                      href="@/assets/svg/icon-sprite.svg#estimator_reset_btn"
                    ></use>
                  </svg>
                  <span class="text-white pl-3"> Reset </span>
                </el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Filter from "../common/filter.vue";

export default {
  data() {
    return {
      form: {
        // AC / DC form control
        showAC: false,
        showDC: false,

        // basic parameter
        b_gei: "", // Grid Emission Intensity (tCO2-e/MWh)
        b_aeii: "", // Annual  Emissions Intensity Improvement in (%)
        b_por: "", //  Percentage of Onsite Renewables (%)
        b_fei: "", // Fuel Emission Intensity (tCO2-e/gallon)

        // AC charging parameters
        ac_au: "", // Annual usage (MWh)
        ac_agu: "", // Annual Growth in Usage (%)
        ac_mpg: "", // MPG (miles/gallon)
        ac_afec: "", // AFEC (kWh/100 miles)
        ac_tir: "", // Technology Improvement Rate
        ac_ce: "", // Charger Efficiency

        // DC charging parameters
        dc_au: "", // Annual usage (MWh)
        dc_agu: "", // Annual Growth in Usage (%)
        dc_mpg: "", // MPG (miles/gallon)
        dc_afec: "", // AFEC (kWh/100 miles)
        dc_tir: "", // Technology Improvement Rate
        dc_ce: "", // Charger Efficiency
      },

      result: 0,
      history: [],
    };
  },

  components: {
    Filter,
  },

  methods: {
    submitForm() {
      this.$emit("submitForm");
    },
  },
};
</script>

<style>
/* h2 {
    color: white;
} */
</style>
