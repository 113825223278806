<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-dialog
    v-model="show"
    width="600"
    :fullscreen="$vuetify.display.width <= 600 ? true : false"
    class="public-style"
    destroy-on-close
    align-center
    @close="close"
  >
    <el-form
      ref="ruleFormRef"
      :rules="rules"
      :model="participant_data"
      :style="[$vuetify.display.width < 600 ? 'height: 550;' : '']"
      :label-position="`top`"
    >
      <el-space fill style="width: 100%">
        <el-alert type="info" class="alert-style" show-icon :closable="false">
          <p class="ma-0 text-white">Participant :</p>
        </el-alert>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 16 : 24">
            <el-form-item
              class="label-style"
              prop="station_name"
              label="Station Name: "
            >
              <el-input v-model="participant_data.station_name" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="pct_aux_load"
              label="Pct Aux Load: "
            >
              <el-input v-model="participant_data.pct_aux_load" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 16 : 24">
            <el-form-item
              class="label-style"
              prop="participant"
              label="Participant: "
            >
              <el-input v-model="participant_data.participant" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="unit_size"
              label="Unit Size: "
            >
              <el-input v-model="participant_data.unit_size" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style" prop="duid" label="Duid: ">
              <el-input v-model="participant_data.duid" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="gensetid"
              label="Gensetid: "
            >
              <el-input v-model="participant_data.gensetid" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style" prop="region" label="Region: ">
              <el-input v-model="participant_data.region" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style" prop="network" label="Network : ">
              <el-input v-model="participant_data.network" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="dispatch_type"
              label="Dispatch Type: "
            >
              <el-input v-model="participant_data.dispatch_type" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="classification"
              label="Classification: "
            >
              <el-input v-model="participant_data.classification" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="fuel_source_primary"
              label="Fuel Source Primary: "
            >
              <el-input v-model="participant_data.fuel_source_primary" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="fuel_source_descriptor"
              label="Fuel Source Descriptor : "
            >
              <el-input v-model="participant_data.fuel_source_descriptor" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="technology_type_primary"
              label="Technology Type Primary: "
            >
              <el-input v-model="participant_data.technology_type_primary" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="technology_type_descriptor"
              label="Technology Type Descriptor: "
            >
              <el-input v-model="participant_data.technology_type_descriptor" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="co2_factor"
              label="CO2 Factor: "
            >
              <el-input v-model="participant_data.co2_factor" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="co2_data_source"
              label="CO2 Data Source: "
            >
              <el-input v-model="participant_data.co2_data_source" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="co2_data_source"
              label="CO2 Data Source: "
            >
              <el-input v-model="participant_data.co2_data_source" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-space>
    </el-form>
    <!-- </el-row> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button
          size="medium"
          class="me-primary-light"
          @click.stop="confirm"
          color="#234B49"
        >
          <i class="fa fa-check me-2 me-primary-light"></i> Confirm
        </el-button>
        <el-button
          size="medium"
          class="me-primary"
          @click.stop="close"
          color="#ddf0ac"
        >
          <i class="fa fa-times me-2 me-primary"></i> Cancel
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// import "animate.css";
import UserService from "../../services/user.service";
// import moment from "moment";
export default {
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    data_modal: {
      type: Object,
      // eslint-disable-next-line no-unused-vars
      default(rawProps) {
        return rawProps;
      },
    },
  },
  data() {
    return {
      show: this.open_modal,
      rules: {
        duid: {
          required: true,
          message: "Please enter duid.",
          trigger: "blur",
        },
        gensetid: {
          required: true,
          message: "Please enter gensetid.",
          trigger: "blur",
        },
        station_name: {
          required: true,
          message: "Please enter station_name.",
          trigger: "blur",
        },
        participant: {
          required: true,
          message: "Please enter participant.",
          trigger: "blur",
        },
        region: {
          required: true,
          message: "Please enter region.",
          trigger: "blur",
        },
        fuel_source_primary: {
          required: true,
          message: "Please enter fuel_source_primary.",
          trigger: "blur",
        },
        fuel_source_descriptor: {
          required: true,
          message: "Please enter fuel_source_descriptor.",
          trigger: "blur",
        },
        technology_type_primary: {
          required: true,
          message: "Please enter technology_type_primary.",
          trigger: "blur",
        },
        technology_type_descriptor: {
          required: true,
          message: "Please enter technology_type_descriptor.",
          trigger: "blur",
        },
        co2_factor: [
          {
            required: true,
            message: "Please enter co2_factor.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("co2_factor must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        pct_aux_load: [
          {
            required: true,
            message: "Please enter pct_aux_load.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("pct_aux_load must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
      participant_data: {
        duid: "",
        gensetid: "",
        station_name: "",
        participant: "",
        region: "",
        network: null,
        dispatch_type: "",
        classification: "",
        fuel_source_primary: "",
        fuel_source_descriptor: "",
        technology_type_primary: "",
        technology_type_descriptor: "",
        unit_size: 0.0,
        co2_factor: 0.0,
        co2_data_source: "",
        co2_energy_source: "",
        pct_aux_load: 0.0,
      },
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    open_modal: function (newVal, oldVal) {
      this.show = newVal;
      if (newVal == true) {
        this.participant_data = JSON.parse(this.data_modal);
      }
    },
  },
  methods: {
    isNewUser() {
      if (this.participant_data.id == 0) return true;
      else return false;
    },
    close() {
      this.show = false;
      this.$emit("close_modal", false);
    },
    async confirm() {
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid) {
          UserService.SetParticipant(this.participant_data)
            .then(
              (response) => {
                this.$emit("confirm_modal", response.data.participant);
              },
              (error) => {
                this.loading = false;
                console.log(error);
                if (error.response.status == 401) {
                  this.$router.push({
                    path: "/",
                  });
                }
              }
            )
            .catch((error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
            })
            .finally(() => (this.loading = false));
        } else {
          console.log("error submit!", fields);
        }
      });
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 30px 10px;
}
.el-input__inner {
  padding: 0 10px !important;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.el-form-item__label {
  font-size: 11px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.el-form-item__content > .el-checkbox {
  margin-top: 16px;
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
