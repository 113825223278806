<template>
  <el-col
    :span="$vuetify.display.width >= 1500 ? 16 : 24"
    class="width-100-p p-2"
  >
    <div class="row m-0 p-2 bg-me-primary border-color-light rounded-4">
      <el-col :span="24">
        <h5 class="text-white mt-3 ml-1">Chargers Distribution</h5>
      </el-col>
      <el-col
        :span="24"
        class="mt-3 px-2"
        v-loading="loading"
        :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        element-loading-background="rgba(122, 122, 122, 0.8)"
      >
        <div
          id="map"
          style="z-index: 1"
          :style="[
            $vuetify.display.width >= 1500
              ? { height: 790 + 'px' }
              : { height: 600 + 'px' },
          ]"
        ></div>
        <div ref="popupContainer"></div>
      </el-col>
    </div>
  </el-col>
</template>

<script>
import UserService from "../../../services/user.service";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import PopupContent from "./popup";
import { createApp } from "vue";
import ElementPlus from "element-plus";

export default {
  props: {
    chargerDistributionProp: {
      type: Array,
      default: null,
    },
    loadingProp: {
      type: Boolean,
      default: false,
    },
    stateProp: {
      type: String,
      default: null,
    },
    filterProp: {
      type: Object,
      default: null,
    },
    // heightProp: {
    //   type: Number,
    //   default: 400,
    // },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PopupContent,
  },
  watch: {
    // stateProp: function (newVal) {
    //   this.state = newVal;
    //   this.fetchAllChargingSite();
    // },
    filterProp: {
      handler: function (newVal) {
        this.customer_id = newVal.customer_id;
        this.state = newVal.state == "All" ? "" : newVal.state;
        // console.log(newVal);
        this.fetchAllChargingSite();
      },
      deep: true,
    },
    // heightProp: {
    //   handler: function (newVal) {
    //     // console.log(this.$refs.performingTbl);
    //     console.log(88888, newVal);
    //     this.mapHeight = newVal;
    //   },
    //   deep: true,
    // },
    // "$vuetify.display.width"() {
    //   this.mapHeight = this.heightProp;
    //   console.log(88888, this.heightProp);
    // },
  },
  data() {
    return {
      loading: false,
      state: null,
      map: null,
      markerClusterGroup: null,
      mapOption: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 8,
        center: [],
      },
      mapOptions: {
        scrollWheelZoom: false,
      },
      SiteDataList: [],
      popup: null,
      popupData: {
        site_name: "",
        state: "",
        chargers_count: 0,
        chargers_capacity_sum: 0,
      },
      customer_id: null,
      // mapHeight: 400,
    };
  },
  mounted() {
    this.fetchAllChargingSite();
  },
  methods: {
    initMap() {
      if (this.map) {
        this.map.remove();
      }
      this.map = L.map("map", {
        zoomControl: true,
        zoom: 1,
        zoomAnimation: false,
        fadeAnimation: true,
        markerZoomAnimation: true,
      }).setView(this.mapOption.center, this.mapOption.zoom);
      L.tileLayer(this.mapOption.url, {
        attribution: this.mapOption.attribution,
      }).addTo(this.map);
      this.markerClusterGroup = L.markerClusterGroup();
      this.addMarkers();
      this.map.addLayer(this.markerClusterGroup);
    },
    addMarkers() {
      this.SiteDataList.forEach((item) => {
        L.marker([item.lat, item.lng])
          .on("click", (e) => this.showPopup(e, item))
          .addTo(this.markerClusterGroup);
      });
    },

    showPopup(e, marker) {
      if (this.popup) {
        this.popup.remove();
      }
      this.popupData = marker;
      const popupContainer = document.createElement("div");
      const appInstance = createApp(PopupContent, {
        popupData: this.popupData,
      }).use(ElementPlus);
      appInstance.mount(popupContainer);
      this.map.flyTo(e.latlng, this.map.getZoom());
      this.popup = L.popup()
        .setLatLng(e.latlng)
        .setContent(popupContainer)
        .openOn(this.map);
    },

    fetchAllChargingSite() {
      let filter = {
        state: this.state,
      };
      if (this.customer_id != null) {
        filter = {
          customer_id: this.customer_id,
          state: this.state,
        };
      }
      this.loading = true;
      UserService.ChargersDistributions(filter)
        .then(
          (response) => {
            this.SiteDataList = response.data.data.charger_distribution;
            this.mapOption.center = [
              this.SiteDataList.length > 0 ? this.SiteDataList[0].lat : 0,
              this.SiteDataList.length > 0 ? this.SiteDataList[0].lng : 0,
            ];
            this.initMap();
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
#map {
  height: 100%;
}
</style>
