<template>
  <div class="container-fluid">
    <Breadcrumbs main="Profile" :path="breadcrumbPath" />
    <div class="row starter-main">
      <el-row gutter="10" v-loading="loading">
        <!-- <el-col :span="8">
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span class="f-20"> My Profile </span>
              </div>
            </template>

            <el-form
              ref="ruleFormRef"
              :rules="rules"
              :model="profile_data"
              :label-position="`top`"
              class="px-3"
            >
              <el-space fill style="width: 100%">
                <el-row>
                  <el-avatar :size="70" src="https://picsum.photos/200/300" />
                  <span class="f-20 font-weight-bold ml-3 mt-5 font-primary">
                    {{ profile_data.full_name }}
                  </span>
                </el-row>

                <el-form-item
                  class="mt-10 f-15 font-weight-bold"
                  prop="email"
                  label="Email Address : "
                >
                  <el-input v-model="profile_data.email" />
                </el-form-item>
                <el-form-item
                  class="f-15 font-weight-bold"
                  prop="password"
                  label="Password : "
                >
                  <el-input v-model="profile_data.password" />
                </el-form-item>
              </el-space>
              <el-button class="mt-3 w-70" type="primary"> Save </el-button>
            </el-form>
          </el-card>
        </el-col> -->
        <el-col :span="24">
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span class="f-20"> Edit Profile </span>
              </div>
            </template>
            <el-form
              ref="ruleFormRef"
              :rules="rules"
              :model="profile_data"
              :label-position="`top`"
              class="px-3"
            >
              <el-space fill style="width: 100%">
                <el-row>
                  <el-col
                    class="d-flex justify-content-center align-content-center"
                    :span="24"
                  >
                    <el-avatar :size="70" src="https://picsum.photos/200/300" />
                  </el-col>
                </el-row>
                <p
                  class="f-20 text-uppercase text-center font-weight-bold ml-3 mt-5 font-primary"
                >
                  {{ profile_data.full_name }}
                </p>
                <el-row gutter="10">
                  <el-col :span="12">
                    <el-form-item
                      class="f-15 font-weight-bold"
                      prop="email"
                      label="Email Address : "
                    >
                      <el-input v-model="profile_data.email" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      class="f-15 font-weight-bold"
                      prop="full_name"
                      label="Full Name : "
                    >
                      <el-input v-model="profile_data.full_name" readonly />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row gutter="10">
                  <el-col :span="12">
                    <el-form-item
                      class="f-15 font-weight-bold"
                      prop="first_name"
                      label="First Name : "
                    >
                      <el-input
                        v-model="profile_data.first_name"
                        @change="handleChangeFullName"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      class="f-15 font-weight-bold"
                      prop="last_name"
                      label="Last Name : "
                    >
                      <el-input
                        v-model="profile_data.last_name"
                        @change="handleChangeFullName"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row
                  gutter="10"
                  v-if="
                    $store.state.auth.user != null &&
                    $store.state.auth.user.customer_id != null
                  "
                >
                  <el-col :span="12">
                    <el-form-item
                      class="f-15 font-weight-bold"
                      prop="phone"
                      label="Phone : "
                    >
                      <el-input v-model="profile_data.phone" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      class="f-15 font-weight-bold"
                      prop="country"
                      label="Country : "
                    >
                      <el-input v-model="profile_data.country" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-space>
              <el-button @click="handleSave" class="mt-3 w-70" type="primary">
                Save
              </el-button>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/bread_crumbs.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "UserProfile",
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "Profile",
          disabled: false,
          href: "/profile",
          active: true,
        },
      ],
      rules: {
        title: {
          required: true,
          message: "Please enter title.",
          trigger: "blur",
        },
      },
      profile_data: {
        email:
          this.$store.state.auth.user == null
            ? ""
            : this.$store.state.auth.user.email,
        password:
          this.$store.state.auth.user == null
            ? ""
            : this.$store.state.auth.user.password,
        first_name:
          this.$store.state.auth.user == null
            ? ""
            : this.$store.state.auth.user.first_name,
        last_name:
          this.$store.state.auth.user == null
            ? ""
            : this.$store.state.auth.user.last_name,
        full_name:
          this.$store.state.auth.user == null
            ? ""
            : this.$store.state.auth.user.full_name,
        phone:
          this.$store.state.auth.user == null
            ? ""
            : this.$store.state.auth.user.phone,
        country:
          this.$store.state.auth.user == null
            ? ""
            : this.$store.state.auth.user.country,
      },
      loading: false,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "profile_data.first_name": function (newVal, oldVal) {
      if (this.first_name != "") {
        this.profile_data.full_name =
          newVal + " " + this.profile_data.last_name;
      } else {
        this.profile_data.full_name = this.profile_data.last_name;
      }
    },
    // eslint-disable-next-line no-unused-vars
    "profile_data.last_name": function (newVal, oldVal) {
      if (this.first_name != "") {
        this.profile_data.full_name =
          this.profile_data.first_name + " " + newVal;
      } else {
        this.profile_data.full_name = newVal;
      }
    },
  },
  methods: {
    handleSave() {
      this.loading = true;
      this.$store.dispatch("auth/update_profile", this.profile_data).then(
        (resp) => {
          console.log(resp);
          // this.$router.push({ path: "/dashboard" });
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.snackbar = true;
        }
      );
    },
    handleChangeFullName() {
      // if (this.first_name != "") {
      //   this.profile_data.full_name =
      //     this.profile_data.first_name + " " + this.profile_data.last_name;
      // } else {
      //   this.profile_data.full_name = this.profile_data.last_name;
      // }
    },
  },
};
</script>

<style>
.w-70 {
  width: 70px !important;
}
</style>
