<template>
  <el-row class="m-0 width-100-p height-100-p">
    <el-col
      class="d-flex align-item-center p-2"
      :span="
        $vuetify.display.width >= 1500
          ? 24
          : $vuetify.display.width >= 700
          ? 8
          : 24
      "
    >
      <div
        class="row m-0 py-3 width-100-p height-100-p bg-me-primary border-color-light rounded-4"
      >
        <el-col :span="24" class="px-3 y-center">
          <h5 class="text-white">{{ dashboardData.number_of_sites.title }}</h5>
        </el-col>
        <el-col :span="6" class="x-y-center">
          <el-image
            :src="require('@/assets/images/dashboard/home/KilometerDriven.png')"
            fit="cover"
          />
        </el-col>
        <el-col :span="18" class="y-center">
          <div class="width-100-p p-2">
            <p class="font-20-b text-white mb-0">
              {{ dashboardData.number_of_sites.value.toLocaleString() }}
            </p>
          </div>
        </el-col>
      </div>
    </el-col>
    <el-col
      class="d-flex align-item-center p-2"
      :span="
        $vuetify.display.width >= 1500
          ? 24
          : $vuetify.display.width >= 700
          ? 8
          : 24
      "
    >
      <div
        class="row m-0 py-4 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <el-col :span="24" class="px-3 y-center">
          <h5 class="text-white">
            {{ dashboardData.number_of_chargers.title }}
          </h5>
        </el-col>
        <el-col :span="6" class="x-y-center">
          <el-image
            :src="require('@/assets/images/dashboard/home/NoxSox.png')"
            fit="cover"
          />
        </el-col>
        <el-col :span="18" class="y-center">
          <div class="width-100-p p-2">
            <p class="font-20-b text-white mb-0">
              {{ dashboardData.number_of_chargers.value.toLocaleString() }}
            </p>
          </div>
        </el-col>
      </div>
    </el-col>
    <el-col
      class="d-flex align-item-center p-2"
      :span="
        $vuetify.display.width >= 1500
          ? 24
          : $vuetify.display.width >= 700
          ? 8
          : 24
      "
    >
      <div
        class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <el-col :span="24" class="px-3 y-center">
          <h5 class="text-white">
            {{ dashboardData.number_of_ports.title }}
          </h5>
        </el-col>
        <el-col :span="6" class="x-y-center">
          <el-image
            :src="require('@/assets/images/dashboard/home/FuelAvoided.png')"
            fit="cover"
          />
        </el-col>
        <el-col :span="18" class="y-center">
          <div class="width-100-p p-2">
            <p class="font-20-b text-white mb-0">
              {{ dashboardData.number_of_ports.value.toLocaleString() }}
            </p>
          </div>
        </el-col>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    dataProp: {
      type: Array,
      default(rawProps) {
        return rawProps;
      },
    },
  },
  components: {
    // ShowDetail,
  },
  data() {
    return {
      show: true,
      dashboardData: {
        number_of_sites: { title: "Number of sites", value: "0" },
        number_of_chargers: { title: "Number of chargers", value: "0" },
        number_of_ports: { title: "Number of ports", value: "0" },
      },
    };
  },
  watch: {
    dataProp: {
      handler: function (newValue) {
        console.log(newValue);
        this.dashboardData.number_of_sites.value = newValue.site_count;
        this.dashboardData.number_of_chargers.value = newValue.charger_count;
        this.dashboardData.number_of_ports.value = newValue.port_count;
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
