<template>
  <router-link to="/dashboard">
    <img
      class="img-fluid for-light"
      src="../../assets/images/logo/logo-light.png"
      alt=""
      height="35"
      width="80"
    /><img
      class="img-fluid for-dark"
      src="../../assets/images/logo/logo_dark.png"
      alt=""
    />
  </router-link>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Logo",
};
</script>
