<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="loader-wrapper" v-if="loading">
      <!-- <div class="loader-index"><span></span></div> -->
      <div class="loader-dot"><span></span></div>
      <img
        class="loader-logo"
        :src="require('@/assets/images/logo/logo-circle.png')"
        alt=""
      />
      <!-- <el-image
        class="loader-logo"
        :src="require('@/assets/images/logo/logo-circle.png')"
        fit="cover"
      /> -->
    </div>
    <div v-else>
      <!-- <div class="page-wrapper" id="pageWrapper" :class="{'horizontal-wrapper':sidebar=='horizontal-wrapper','compact-wrapper':sidebar=='compact-sidebar1'}"> -->
      <div class="page-wrapper" id="pageWrapper" :class="layoutobj">
        <div class="page-header" :class="{ close_icon: !togglesidebar }">
          <Header @clicked="sidebar_toggle" @filterConfirm="filterConfirm" />
        </div>

        <div class="page-body-wrapper bg-me-primary-1">
          <!-- <div class="bg-overlay" :class="{active: activeoverlay }" @click="removeoverlay()"></div> -->
          <div
            class="sidebar-wrapper bg-me-primary text-white"
            :class="[{ close_icon: !togglesidebar }]"
            :sidebar-layout="svg == 'stroke-svg' ? 'stroke-svg' : 'fill-svg'"
          >
            <Sidebar @clicked="sidebar_toggle" />
          </div>
          <div class="page-body bg-me-primary-1" @click="hidesecondmenu()">
            <transition name="fadeIn" enter-active-class="animated fadeIn">
              <router-view class="view"></router-view>
            </transition>
          </div>
          <!-- <Footer /> -->
        </div>
        <!-- <Customizer/> -->
        <TapTop />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { layoutClasses } from "../constants/layout";
import Header from "./header";
import Sidebar from "./sidebar";
// import Footer from "./footer.vue";
// import Customizer from './customizer';
import TapTop from "./tapTop.vue";

export default {
  //   name: 'mainPage',
  props: ["sidebar_toggle_var"],
  components: {
    Header,
    Sidebar,
    // Footer,
    // Customizer,
    TapTop,
  },
  data() {
    return {
      loading: true,
      mobileheader_toggle_var: false,
      // eslint-disable-next-line vue/no-dupe-keys
      sidebar_toggle_var: false,
      horizontal_Sidebar: true,
      resized: false,
      layoutobj: {},
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      togglesidebar: (state) => state.menu.togglesidebar,
      activeoverlay: (state) => state.menu.activeoverlay,
      svg: (state) => state.layout.svg,
    }),
    ...mapGetters({
      sidebar: "layout/sidebar",
    }),
    layoutobject: {
      get: function () {
        // return 'hello'
        return JSON.parse(
          JSON.stringify(
            layoutClasses.find(
              (item) => Object.keys(item).pop() === this.layout.settings.layout
            )
          )
        )[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find(
          (item) => Object.keys(item).pop() === this.layout.settings.layout
        );
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
        return this.layoutobj;
      },
    },
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.loading = !this.loading;
      }, 250);
      this.loading = !this.loading;
      this.menuItems.filter((items) => {
        if (items.path === this.$route.path)
          this.$store.dispatch("menu/setActiveRoute", items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch("menu/setActiveRoute", subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch("menu/setActiveRoute", subSubItems);
          });
        });
      });
      this.layoutobj = layoutClasses.find(
        (item) => Object.keys(item).pop() === this.layout.settings.layout
      );
      // console.log("layobj==>", this.layoutobj);

      if (
        (window.innerWidth < 991 &&
          this.layout.settings.layout === "LosAngeles") ||
        (window.innerWidth < 991 &&
          this.layout.settings.layout === "Singapore") ||
        (window.innerWidth < 991 && this.layout.settings.layout === "Barcelona")
      ) {
        const newlayout = JSON.parse(
          JSON.stringify(this.layoutobj).replace(
            "horizontal-wrapper",
            "compact-wrapper"
          )
        );
        // console.log("newlayobj==>",newlayout)

        this.layoutobj = JSON.parse(JSON.stringify(newlayout))[
          this.layout.settings.layout
        ];
      } else {
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
      }
    },
    sidebar_toggle_var: function () {
      this.resized =
        this.width <= 991 ? !this.sidebar_toggle_var : this.sidebar_toggle_var;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.resized = this.sidebar_toggle_var;
    this.$store.dispatch("layout/set");
    // this.$router.replace({ 'query': null }).catch(err => err);
    this.layout.settings.layout = this.$route.query.layout
      ? this.$route.query.layout
      : "Dubai";
    this.layoutobj = layoutClasses.find(
      (item) => Object.keys(item).pop() === this.layout.settings.layout
    );
    this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
      this.layout.settings.layout
    ];
  },
  methods: {
    sidebar_toggle(value) {
      // eslint-disable-next-line vue/no-mutating-props
      this.sidebar_toggle_var = !value;
    },
    mobiletoggle_toggle(value) {
      this.mobileheader_toggle_var = value;
    },
    handleResize() {
      this.$store.dispatch("menu/resizetoggle");
    },
    removeoverlay() {
      this.$store.state.menu.activeoverlay = false;
      if (window.innerWidth < 991) {
        this.$store.state.menu.togglesidebar = false;
      }
      this.menuItems.filter((menuItem) => {
        menuItem.active = false;
      });
    },
    hidesecondmenu() {
      if (this.layoutobject.split(" ").includes("compact-sidebar")) {
        this.menuItems.filter((menuItem) => {
          menuItem.active = false;
        });
      }
      if (window.innerWidth < 991) {
        this.$store.state.menu.togglesidebar = false;
      }
    },
    filterConfirm(event) {
      console.log(event);
      this.$store.state.menu.filterOpen = event;
    },
  },
  mounted() {
    // setTimeout(()=>{
    this.loading = false;

    // },2000)
  },
};
</script>

<style>
@keyframes grow-logo {
  0% {
    transform: scale(0.5);
    /* background: #16C7F9; */
  }
  50% {
    transform: scale(1);
    /* background: var(--theme-secondary); */
  }
}
.loader-logo {
  width: 7em;
  height: 7em;
  background: white;
  border-radius: 50%;
  position: absolute;
  transform: scale(0.5);
  animation: grow-logo 2s cubic-bezier(0.14, 0.05, 0.55, 0.5) infinite alternate;
}

.loader-wrapper .loader-dot {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  filter: url("#goo");
}

.loader-wrapper .loader-dot span {
  width: 2em;
  height: 2em;
  background: #78be20;
  border-radius: 50%;
  margin-right: 2em;
  position: relative;
  transform: translateX(7em);
  animation: move 4s ease-in-out infinite;
}

.loader-wrapper .loader-dot span:before {
  content: "";
  width: 2em;
  height: 2em;
  background: #547477;
  border-radius: 50%;
  position: absolute;
  left: 3em;
  transform: translateX(0em);
  animation: shrink 2s ease-in-out infinite;
}

.loader-wrapper .loader-dot span:after {
  content: "";
  width: 2em;
  height: 2em;
  background: #234b49;
  border-radius: 50%;
  position: absolute;
  right: 3em;
  transform: translateX(0em);
  animation: shrink 2s ease-in-out infinite;
}
</style>
