<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <Breadcrumbs main="Estimator" title="default" />
  <div class="container-fluid p-0">
    <userInputs v-if="!isSubmitted" @submitForm="handleSubmitted" />

    <e_output v-if="isSubmitted" @resetForm="handleReset" />
  </div>
</template>

<script>
// import filter for location filter
// import Filter from "../common/filter.vue";
//import api for estimator
// import UserService from '../../../services/user.service';

// import inputForm from "./inputForm.vue";

import userInputs from "./userInputs.vue";

import e_output from "./estima_output.vue";

export default {
  props: {},
  components: {
    // Filter,
    // inputForm,
    userInputs,
    e_output,
  },
  watch: {},
  computed: {
    stateList() {
      return this.states;
    },
  },
  data() {
    return {
      isSubmitted: false, // control component switch

      states: [],
      filter: {
        state: "",
        startDate: null,
        endDate: null,
        customer_id: null,
      },
    };
  },

  methods: {
    handleSubmitted() {
      this.isSubmitted = true;
    },

    handleReset() {
      this.isSubmitted = false;
    },
  },
};
</script>

<style>
/* span {
    color: white;
}
label{
    color: white;
} */
</style>
