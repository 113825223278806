<!-- eslint-disable no-unused-vars -->
<template>
  <div class="container-fluid">
    <Breadcrumbs main="Sessions" :path="breadcrumbPath" />
    <!-- tab Session -->
    <div class="row mb-3 px-0 m-0">
      <el-col
        :span="
          $vuetify.display.width >= 1300
            ? 12
            : $vuetify.display.width < 750
            ? 24
            : 16
        "
        class="p-0"
        :class="$vuetify.display.width < 750 ? 'x-center' : ''"
      >
        <el-radio-group
          v-model="uploadFilter"
          text-color="#ffff"
          fill="#234B49"
          :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
        >
          <el-radio-button
            class="start-period all-group-by text-center"
            label="1"
            :class="$vuetify.display.width < 750 ? 'width-50-p' : ''"
          >
            {{
              "Upload Sessions" +
              ($vuetify.display.width > 500 ? " (Recommended)" : "")
            }}
          </el-radio-button>
          <el-radio-button
            class="site-group-by text-center end-period"
            label="2"
            :class="$vuetify.display.width < 750 ? 'width-50-p' : ''"
          >
            Simple Upload
          </el-radio-button>
        </el-radio-group>
      </el-col>
    </div>

    <div class="row mb-3 px-0 m-0" v-if="uploadFilter == `1`">
      <!-- alert -->
      <el-row class="m-0 mb-3 px-0">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-alert
            v-if="missingColumns.length > 0"
            @close="missingAlertCloseHandle"
            class="ma-2"
            title="file has a wrong structure"
            type="error"
            show-icon
          >
            <p class="mb-0" style="color: #f56c6c">
              {{ "To send a session, these columns are needed in the file:" }}
            </p>
            <ul>
              <li v-for="(miss, i) in missingColumns" :key="i">
                {{ i + 1 + ". " + miss }}
              </li>
            </ul>
          </el-alert>
        </el-col>
      </el-row>
      <!-- uploader -->
      <el-row class="m-0 mb-3 px-0">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-upload
            ref="upload"
            class="upload-demo width-100-p"
            action="#"
            :limit="1"
            :auto-upload="false"
            drag
            :on-change="onChange"
            :on-remove="onRemove"
          >
            <i class="fa fa-cloud-upload f-46"></i>
            <div class="el-upload__text text-white">
              Drop file here or <em>click to upload</em>
            </div>
            <template #tip>
              <div class="el-upload__tip text-white">
                There is a maximum limit of 100MB for the upload process
              </div>
            </template>
          </el-upload>
        </el-col>
      </el-row>
      <!-- progress -->
      <el-row class="m-0 mb-3 px-0">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-progress
            v-if="hasLargeFile"
            :percentage="sendingFilePercent"
            stroke-width="10"
            :format="progressFormat"
            :status="sendingFilePercent == 100 ? 'success' : ''"
            class="progress-style"
          />
          <el-alert
            v-if="hasLargeFile && sendingFilePercent == 100"
            title="Send Success"
            :type="`success`"
            class="mt-5"
            show-icon
          >
            <p class="mb-0" style="color: #67c23a">
              {{
                "The file with a size of " +
                sessionsFileSize +
                " MB is received successfully. The result of this transaction, including valid and invalid sessions will be emailed to you shortly."
              }}
            </p>
          </el-alert>
        </el-col>
      </el-row>
      <!-- stepper -->
      <el-row class="m-0 mb-3 px-0" v-if="!hasLargeFile">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-steps
            :active="activeStep"
            finish-status="success"
            :process-status="successStep"
            simple
            class="step-style width-100-p"
          >
            <el-step title="Load Data" :description="stepLoadDesc" />
            <el-step
              @click="stepperChange(2)"
              style="cursor: pointer"
              title="Check Validate"
              :description="stepValidDesc"
            />
            <el-step
              @click="stepperChange(3)"
              style="cursor: pointer"
              title="Send Data"
              :description="stepSendDesc"
            />
          </el-steps>
        </el-col>
      </el-row>
      <!-- table -->
      <el-row class="m-0 mb-3 px-0" v-if="!hasLargeFile">
        <el-col
          :span="24"
          class="y-center"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <div class="row m-0 width-100-p">
            <div
              class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
            >
              <el-col :span="24" class="mb-2">
                <div class="row m-0">
                  <!-- <el-col
                    class="pl-0"
                    :span="$vuetify.display.width >= 750 ? 14 : 24"
                    :class="
                      $vuetify.display.width < 750
                        ? 'x-center p-0 order-3 mt-2'
                        : ''
                    "
                  >
                    <el-input
                      size="large"
                      v-model="search"
                      placeholder="Type to search by site name, charger ID, port ..."
                      :prefix-icon="Search"
                      class="table-search"
                    />
                  </el-col> -->
                  <el-col
                    class="p-0"
                    :span="24"
                    :class="
                      $vuetify.display.width < 750
                        ? 'x-center width-100-p'
                        : 'd-flex justify-content-start'
                    "
                  >
                    <el-button
                      @click="handleExportFile"
                      color="#ddf0ac"
                      class="height-40 rounded-3"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <i class="fa fa-file-excel-o"></i>
                    </el-button>
                    <el-button
                      @click="handlePerv"
                      color="#ddf0ac"
                      class="height-40 rounded-3"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <i class="fa fa-angle-left"></i>
                      <span class="me-primary pl-3"> Prev </span>
                    </el-button>
                    <el-button
                      @click="handleNext"
                      color="#ddf0ac"
                      class="height-40 rounded-3"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <span class="me-primary pr-3"> Next </span>
                      <i class="fa fa-angle-right"></i>
                    </el-button>
                  </el-col>
                  <!-- <el-col
                    class="p-0"
                    :span="$vuetify.display.width >= 750 ? 5 : 12"
                    :class="
                      $vuetify.display.width < 750
                        ? 'x-center pr-1 order-1 width-100-p'
                        : 'd-flex justify-content-end'
                    "
                  >
                    <el-button
                      color="#0D3937"
                      class="height-40 border-color-light rounded-3 ml-1"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <svg class="stroke-icon width-20 height-20">
                        <use
                          href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
                        ></use>
                      </svg>
                      <span class="text-white pl-3"> Filter </span>
                    </el-button>
                  </el-col> -->
                </div>
              </el-col>
              <el-col :span="24" class="p-0">
                <div
                  class="ma-0 width-100-p"
                  v-loading="loading"
                  :element-loading-spinner="svg"
                  element-loading-svg-view-box="-10, -10, 50, 50"
                  element-loading-background="rgba(122, 122, 122, 0.8)"
                >
                  <el-alert
                    v-if="responseAlert.visible"
                    :title="responseAlert.title"
                    :type="responseAlert.type"
                    show-icon
                  >
                    <p
                      class="mb-0"
                      style="color: #67c23a"
                      v-if="response_alert.success_count > 0"
                    >
                      {{ responseAlert.success_desc }}
                    </p>
                    <p
                      class="mb-0"
                      style="color: #f56c6c"
                      v-if="response_alert.pending_count > 0"
                    >
                      {{ responseAlert.pending_desc }}
                    </p>
                    <p
                      class="mb-0"
                      style="color: #e6a23c"
                      v-if="response_alert.duplicate_count > 0"
                    >
                      {{ responseAlert.duplicate_desc }}
                    </p>
                  </el-alert>
                  <el-table
                    :data="filter_excel_data"
                    :fit="true"
                    height="50vh"
                    class="my-2 data-table"
                    :row-class-name="tableRowClassName"
                    @row-click="handleRowClick"
                    scrollbar-always-on
                  >
                    <el-table-column type="expand">
                      <template #default="props">
                        <div
                          class="pa-5"
                          v-if="
                            props.row.error_message != null &&
                            props.row.error_message.length > 0
                          "
                        >
                          <span class="text-red"> Attention : </span>
                          <p
                            m="t-0 b-2"
                            class="mb-0"
                            v-for="(error, i) in props.row.error_message"
                            :key="i"
                          >
                            {{ i + 1 + " : " + error }}
                          </p>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="'Site Name'"
                      :prop="'site_name'"
                      :width="$vuetify.display.width <= 1810 ? 200 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Postcode'"
                      :prop="'postcode'"
                      :width="$vuetify.display.width <= 1810 ? 150 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Charger ID'"
                      :prop="'charger_id'"
                      :width="$vuetify.display.width <= 1810 ? 180 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Charger Capacity (kW)'"
                      :prop="'charger_capacity_kW'"
                      :width="$vuetify.display.width <= 1810 ? 190 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Port ID'"
                      :prop="'port_id'"
                      :width="$vuetify.display.width <= 1810 ? 150 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Start Time'"
                      :prop="'start_time'"
                      :width="$vuetify.display.width <= 1810 ? 180 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'End Time'"
                      :prop="'end_time'"
                      :width="$vuetify.display.width <= 1810 ? 180 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Usage (kWh)'"
                      :prop="'usage_kWh'"
                      :width="$vuetify.display.width <= 1810 ? 150 : ''"
                      sortable
                    />
                  </el-table>
                  <div class="d-flex justify-center">
                    <el-pagination
                      small
                      background
                      layout="prev, pager, next"
                      :total="total_items"
                      v-model:page-size="page_size"
                      v-model:current-page="current_page"
                      class="my-4"
                    />
                  </div>
                </div>
              </el-col>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row mb-3 px-0 m-0" v-else>
      <SimpleUpload />
    </div>

    <div class="row starter-main" v-if="1 == 2">
      <div class="col-xl-12 xl-100 col-lg-12 box-col-12">
        <el-alert
          v-if="missingColumns.length > 0"
          @close="missingAlertCloseHandle"
          class="ma-2"
          title="file has a wrong structure"
          type="error"
          show-icon
        >
          <p class="mb-0" style="color: #f56c6c">
            {{ "To send a session, these columns are needed in the file:" }}
          </p>
          <ul>
            <li v-for="(miss, i) in missingColumns" :key="i">
              {{ i + 1 + ". " + miss }}
            </li>
          </ul>
        </el-alert>
        <div class="card">
          <div
            class="card-header bg-me-primary py-3"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <h5 class="pull-left me-primary-light">Any title here?</h5>
            <ul
              class="pull-right nav nav-pills nav-success"
              id="pills-clrtabsuccess"
              role="tablist"
              style="display: flex; align-items: center"
            >
              <li class="nav-item" role="presentation">
                <el-popover
                  placement="top-start"
                  title="Upload Sessions data with the requested format"
                  :width="400"
                  trigger="hover"
                >
                  <template #reference>
                    <a
                      class="nav-link show active"
                      id="pills-clrhome-tabsuccess"
                      data-bs-toggle="pill"
                      href="#pills-clrhomesuccess"
                      role="tab"
                      aria-controls="pills-clrhome"
                      aria-selected="true"
                    >
                      <i class="fa fa-cloud-upload"></i> Upload Sessions
                      (Recommended)
                    </a>
                  </template>
                  <div>
                    <p>
                      Please upload the session schedule data following <br />
                      the required format by Motion Energy. Using this <br />
                      option is recommended as it is 100% automated.
                    </p>
                  </div>
                </el-popover>
              </li>
              <li class="nav-item" role="presentation">
                <el-popover
                  placement="top-start"
                  title="Simple Upload"
                  :width="400"
                  trigger="hover"
                >
                  <template #reference>
                    <a
                      class="nav-link"
                      id="pills-clrprofile-tabsuccess"
                      data-bs-toggle="pill"
                      href="#pills-clrprofilesuccess"
                      role="tab"
                      aria-controls="pills-clrprofile"
                      aria-selected="false"
                      tabindex="-1"
                    >
                      <i class="icofont icofont-upload-alt"></i> Simple Upload
                    </a>
                  </template>
                  <div>
                    <p>
                      If you cannot provide the session schedule data <br />
                      with the requested format, please upload the file <br />
                      with its current format. Our team will take care of <br />
                      the rest. Please note that we might contact you and <br />
                      ask for more information.
                    </p>
                  </div>
                </el-popover>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="tabbed-card session-tab">
              <div class="tab-content" id="pills-clrtabContentsuccess">
                <div
                  class="tab-pane fade show active"
                  id="pills-clrhomesuccess"
                  role="tabpanel"
                  aria-labelledby="pills-clrhome-tabsuccess"
                >
                  <el-upload
                    ref="upload"
                    class="upload-demo"
                    action="#"
                    :limit="1"
                    :auto-upload="false"
                    drag
                    :on-change="onChange"
                    :on-remove="onRemove"
                  >
                    <i class="fa fa-cloud-upload f-46"></i>
                    <div class="el-upload__text">
                      Drop file here or <em>click to upload</em>
                    </div>
                    <template #tip>
                      <div class="el-upload__tip">
                        There is a maximum limit of 100MB for the upload process
                      </div>
                    </template>
                  </el-upload>
                  <!-- <el-alert
                    title="success alert"
                    type="success"
                    description="more text description
                    more text description"
                    show-icon
                    v-if="hasResponse"
                  /> -->
                  <!-- <el-row class="ma-0">
                    <el-col
                      :span="24"
                      class="el-alert el-alert--success is-light"
                    >
                      <i class="el-icon el-alert__icon is-big">
                        <i class="fa fa-check-circle f-30"></i>
                      </i>
                      <div class="el-alert__content">
                        <span class="el-alert__title is-bold">
                          success alert
                        </span>
                        <p class="el-alert__description">
                          more text description
                        </p>
                        <i class="el-icon el-alert__close-btn">
                          <li class="fa fa-times"></li>
                        </i>
                      </div>
                    </el-col>
                  </el-row> -->
                  <el-progress
                    v-if="hasLargeFile"
                    :percentage="sendingFilePercent"
                    stroke-width="10"
                    :format="progressFormat"
                    :status="sendingFilePercent == 100 ? 'success' : ''"
                  />
                  <el-alert
                    v-if="hasLargeFile && sendingFilePercent == 100"
                    title="Send Success"
                    :type="`success`"
                    class="mt-5"
                    show-icon
                  >
                    <p class="mb-0" style="color: #67c23a">
                      {{
                        "The file with a size of " +
                        sessionsFileSize +
                        " MB is received successfully. The result of this transaction, including valid and invalid sessions will be emailed to you shortly."
                      }}
                    </p>
                  </el-alert>

                  <el-steps
                    v-if="!hasLargeFile"
                    :active="activeStep"
                    finish-status="success"
                    :process-status="successStep"
                    simple
                    class="mb-4 mt-4"
                  >
                    <el-step title="Load Data" :description="stepLoadDesc" />
                    <el-step
                      @click="stepperChange(2)"
                      style="cursor: pointer"
                      title="Check Validate"
                      :description="stepValidDesc"
                    />
                    <el-step
                      @click="stepperChange(3)"
                      style="cursor: pointer"
                      title="Send Data"
                      :description="stepSendDesc"
                    />
                  </el-steps>

                  <Filter
                    v-if="!hasLargeFile"
                    @filterAction="filterAction"
                    @filterSearch="filterSearch"
                  />

                  <!-- <Stepper /> -->
                  <div class="ma-0" v-loading="loading" v-if="!hasLargeFile">
                    <el-alert
                      v-if="responseAlert.visible"
                      :title="responseAlert.title"
                      :type="responseAlert.type"
                      show-icon
                    >
                      <p
                        class="mb-0"
                        style="color: #67c23a"
                        v-if="response_alert.success_count > 0"
                      >
                        {{ responseAlert.success_desc }}
                      </p>
                      <p
                        class="mb-0"
                        style="color: #f56c6c"
                        v-if="response_alert.pending_count > 0"
                      >
                        {{ responseAlert.pending_desc }}
                      </p>
                      <p
                        class="mb-0"
                        style="color: #e6a23c"
                        v-if="response_alert.duplicate_count > 0"
                      >
                        {{ responseAlert.duplicate_desc }}
                      </p>
                    </el-alert>

                    <el-table
                      :data="filter_excel_data"
                      :fit="true"
                      height="50vh"
                      class="my-2"
                      :row-class-name="tableRowClassName"
                      @row-click="handleRowClick"
                      scrollbar-always-on
                    >
                      <el-table-column type="expand">
                        <template #default="props">
                          <div
                            class="pa-5"
                            v-if="
                              props.row.error_message != null &&
                              props.row.error_message.length > 0
                            "
                          >
                            <span class="text-red"> Attention : </span>
                            <p
                              m="t-0 b-2"
                              class="mb-0"
                              v-for="(error, i) in props.row.error_message"
                              :key="i"
                            >
                              {{ i + 1 + " : " + error }}
                            </p>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="'Site Name'"
                        :prop="'site_name'"
                        :width="$vuetify.display.width <= 1810 ? 200 : ''"
                        sortable
                      />
                      <el-table-column
                        :label="'Postcode'"
                        :prop="'postcode'"
                        :width="$vuetify.display.width <= 1810 ? 150 : ''"
                        sortable
                      />
                      <el-table-column
                        :label="'Charger ID'"
                        :prop="'charger_id'"
                        :width="$vuetify.display.width <= 1810 ? 180 : ''"
                        sortable
                      />
                      <el-table-column
                        :label="'Charger Capacity (kW)'"
                        :prop="'charger_capacity_kW'"
                        :width="$vuetify.display.width <= 1810 ? 190 : ''"
                        sortable
                      />
                      <el-table-column
                        :label="'Port ID'"
                        :prop="'port_id'"
                        :width="$vuetify.display.width <= 1810 ? 150 : ''"
                        sortable
                      />
                      <el-table-column
                        :label="'Start Time'"
                        :prop="'start_time'"
                        :width="$vuetify.display.width <= 1810 ? 180 : ''"
                        sortable
                      />
                      <el-table-column
                        :label="'End Time'"
                        :prop="'end_time'"
                        :width="$vuetify.display.width <= 1810 ? 180 : ''"
                        sortable
                      />
                      <el-table-column
                        :label="'Usage (kWh)'"
                        :prop="'usage_kWh'"
                        :width="$vuetify.display.width <= 1810 ? 150 : ''"
                        sortable
                      />
                    </el-table>
                    <div class="d-flex justify-center">
                      <el-pagination
                        small
                        background
                        layout="prev, pager, next"
                        :total="total_items"
                        v-model:page-size="page_size"
                        v-model:current-page="current_page"
                        class="my-4"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-clrprofilesuccess"
                  role="tabpanel"
                  aria-labelledby="pills-clrprofile-tabsuccess"
                >
                  <SimpleUpload />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ShowDetail
      :open_modal="show_modal"
      :data_modal="data_modal"
      @close_modal="close_modal"
      @confirm_modal="confirm_modal"
    />
  </div>
</template>

<script>
import Breadcrumbs from "../../components/bread_crumbs.vue";
import XLSX from "xlsx";
import UserService from "../../services/user.service";
import ShowDetail from "./dialogDetail.vue";
import SimpleUpload from "./simpleUpload.vue";
import Filter from "./filter.vue";
import { useToast } from "vue-toastification";
import { ElMessageBox } from "element-plus";
import moment from "moment";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "sessions",
  components: {
    Breadcrumbs,
    ShowDetail,
    SimpleUpload,
    Filter,
  },
  data() {
    return {
      breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "Sessions",
          disabled: false,
          href: "/sessions",
          active: true,
        },
      ],
      uploadFilter: "1",
      show: true,
      selectedFile: null,
      excelDataList: [],
      search: "",
      current_page: 1,
      page_size: 100,
      total_items: 100,
      excelData: [],
      beforeUploadValid: true,
      dupRowIndex: [],
      invalidRowIndex: [],
      data: null,
      activeStep: 0,
      successStep: "wait",
      loading: false,
      register_serial_id: "",
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      fromDate: "",
      toDate: "",
      statusRow: 1,
      filter: {
        fromDate: "",
        toDate: "",
        statusRow: 1,
      },
      hasResponse: true,
      response_alert: {
        all_rows_count: 0,
        success_count: 0,
        success_sum_kwh: 0,
        pending_count: 0,
        pending_sum_kwh: 0,
        error_count: 0,
        error_sum_kwh: 0,
        duplicate_count: 0,
      },
      correctFile: true,
      alertWrongFile: false,
      responseAlert: {
        visible: false,
        title: "",
        type: "info",
        success_desc: "",
        pending_desc: "",
        duplicate_desc: "",
      },
      removeInvalidRow: false,
      missingColumns: [],
      fileUpload: null,
      rows: [],
      isLoading: false,
      dataLoaded: false,
      currentRow: 0,
      sessionsFileSize: 0,
      hasLargeFile: false,
      sendingFilePercent: 0,
    };
  },
  created() {},
  watch: {
    search() {
      this.current_page = 1;
    },
    correctFile(new_val) {
      if (!new_val) {
        this.alertWrongFile = true;
        this.onRemove();
        this.$refs.upload.clearFiles();
      } else {
        this.alertWrongFile = false;
      }
    },
  },
  computed: {
    filter_excel_data() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-unused-vars
      let list = this.excelDataList.filter((item, index) => {
        var validSDate = this.isValidDate(item.start_time);
        var validEDate = this.isValidDate(item.end_time);
        if (!validSDate && item.start_time != null) {
          let start_milliseconds = (item.start_time - 25569) * 86400 * 1000;
          if (start_milliseconds != null && start_milliseconds != undefined) {
            item.start_time = moment
              .utc(start_milliseconds)
              .format("YYYY/MM/DD HH:mm:ss");
          }
        }
        if (!validEDate && item.end_time != null) {
          let end_milliseconds = (item.end_time - 25569) * 86400 * 1000;
          if (end_milliseconds != null && end_milliseconds != undefined) {
            item.end_time = moment
              .utc(end_milliseconds)
              .format("YYYY/MM/DD HH:mm:ss");
          }
        }
        //item.register_id = this.register_serial_id;
        //item.customer_id = this.$store.state.auth.user.id;
        return (
          ((item.site_name === undefined || item.site_name == null
            ? ""
            : item.site_name
          )
            .toString()
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1 ||
            (item.charger_id === undefined || item.charger_id == null
              ? ""
              : item.charger_id
            )
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1 ||
            (item.port_id === undefined || item.port_id == null
              ? ""
              : item.port_id
            )
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1) &&
          (validSDate &&
          validEDate &&
          this.isValidDate(this.fromDate) &&
          this.isValidDate(this.toDate)
            ? new Date(item.start_time) >= new Date(this.fromDate) &&
              new Date(item.start_time) <= new Date(this.toDate) &&
              new Date(item.end_time) >= new Date(this.fromDate) &&
              new Date(item.end_time) <= new Date(this.toDate)
            : true) &&
          (this.statusRow == 1 ||
            (this.statusRow == 2 &&
              item.is_duplicate) /*this.dupRowIndex.includes(index)) ||*/ ||
            (this.statusRow == 3 &&
              Object.keys(item).includes("error_message") &&
              item.error_message.length > 0))
        );
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.total_items = list.length;
      let paginated = this.paginate(list, this.page_size, this.current_page);
      return paginated;
    },
  },
  methods: {
    onChange(event, fileList) {
      this.loading = true;
      this.successStep = "process";
      const file = event.raw ? event.raw : null;
      this.fileUpload = file;
      this.sessionsFileSize = file.size / 1024 / 1024;
      if (
        file.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        file.type !== "application/vnd.ms-excel"
      ) {
        this.loading = false;
        this.successStep = "wait";
        this.$message.error(`select an Excel format!`);
        fileList.pop();
      } else if (file.size / 1024 / 1024 > 100) {
        this.loading = false;
        this.successStep = "wait";
        this.$message.error(`file can not be greater than 100MB!`);
        fileList.pop();
      } else if (file) {
        this.selectedFile = event.raw;

        this.importExcel(this.sessionsFileSize);
      }
    },
    // eslint-disable-next-line no-unused-vars
    onRemove(event, fileList) {
      this.selectedFile = null;
      this.excelDataList = [];
      // this.primaryExcelDataList = [];
      this.excelData = [];
      this.removeInvalidRow = false;
      this.activeStep = 0;
      this.successStep = "wait";
      this.loading = false;
      this.hasLargeFile = false;
      this.sendingFilePercent = 0;
      this.sessionsFileSize = 0;
      this.response_alert = {
        all_rows_count: 0,
        success_count: 0,
        success_sum_kwh: 0,
        pending_count: 0,
        pending_sum_kwh: 0,
        error_count: 0,
        error_sum_kwh: 0,
        duplicate_count: 0,
      };
      this.correctFile = true;
      this.responseAlert = {
        visible: false,
        title: "",
        type: "info",
        success_desc: "",
        pending_desc: "",
        duplicate_desc: "",
      };
    },

    async reader() {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        // console.log(moment(new Date()).format("HH:mm:ss"));
        const workbook = XLSX.read(data, { type: "array" });
        // console.log(moment(new Date()).format("HH:mm:ss"));
        const sheetName = workbook.SheetNames[0];
        // console.log(moment(new Date()).format("HH:mm:ss"));
        const worksheet = workbook.Sheets[sheetName];
        // console.log(moment(new Date()).format("HH:mm:ss"));
        // console.log(worksheet);

        this.excelData = XLSX.utils.sheet_to_json(worksheet, {
          defval: null,
        });
        const headers = [];
        for (let cell in worksheet) {
          if (cell !== "!ref" && cell[1] === "1") {
            headers.push(worksheet[cell].v);
          }
        }
        const requiredColumns = [
          "site_name",
          "start_time",
          "end_time",
          "port_id",
          "usage_kWh",
          "charger_id",
          // "postcode",
          "charger_capacity_kW",
        ];
        this.missingColumns = requiredColumns.filter(
          (column) => !headers.includes(column)
        );
        if (this.missingColumns.length > 0) {
          this.correctFile = false;
        } else {
          this.correctFile = true;
        }
        this.excelDataList = this.excelData;
        this.register_serial_id = btoa(
          this.$store.state.auth.user.id.toString() +
            "-" +
            this.excelDataList.length.toString() +
            "-" +
            Date.now().toString()
        );
        this.loading = false;
      };
      reader.readAsArrayBuffer(this.selectedFile);
      // console.log(4, Date.now().toString());
      this.activeStep = 1;
    },

    handleUpload() {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        // console.log(moment(new Date()).format("HH:mm:ss"));
        const workbook = XLSX.read(data, { type: "array" });
        // console.log(moment(new Date()).format("HH:mm:ss"));
        const sheetName = workbook.SheetNames[0];
        // console.log(moment(new Date()).format("HH:mm:ss"));
        const sheet = workbook.Sheets[sheetName];
        // console.log(moment(new Date()).format("HH:mm:ss"));
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        // console.log(moment(new Date()).format("HH:mm:ss"));
        // let dd = XLSX.utils.sheet_to_json(sheet, {
        //   defval: null,
        // });
        console.log(range);

        for (
          let i = this.currentRow;
          i < Math.min(this.currentRow + 10, range.e.r + 1);
          i++
        ) {
          const row = {};
          for (let j = range.s.c; j <= range.e.c; j++) {
            const cellAddress = { c: j, r: i };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            const cellValue = sheet[cellRef] ? sheet[cellRef].v : null;
            row[`col_${j}`] = cellValue;
          }
          this.rows.push(row);
        }
        console.log(this.rows);
        this.currentRow += 10;
      };
      // console.log(this.rows);
      reader.readAsArrayBuffer(this.fileUpload);
    },

    async importExcel(fileSize) {
      if (this.selectedFile) {
        // await this.handleUpload();
        // console.log(fileSize);
        if (fileSize <= 5) {
          this.hasLargeFile = false;
          await this.reader();
        } else {
          this.hasLargeFile = true;
          await this.handleFileUpload(this.fileUpload);
        }
      } else {
        this.successStep = "error";
        this.$message.error("Please select an Excel file first.");
        this.loading = false;
      }
      this.response_alert = {
        success_count: 0,
        success_sum_kwh: 0,
        pending_count: 0,
        pending_sum_kwh: 0,
        error_count: 0,
        error_sum_kwh: 0,
        duplicate_count: 0,
      };
    },

    progressFormat() {
      return this.sendingFilePercent == 100
        ? "Sent"
        : this.sendingFilePercent.toString() + "%";
    },

    async handleFileUpload(event) {
      // console.log(event);
      const file = event; //event.target.files[0];
      const chunkSize = 10 * 1024 * 1024; // 10MB
      let start = 0;
      let end = Math.min(chunkSize, file.size);
      let chunkNumber = 0;
      let totalChunk = Math.ceil(file.size / chunkSize);
      while (start < file.size) {
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("chunkNumber", chunkNumber);
        formData.append("totalChunks", Math.ceil(file.size / chunkSize));

        await UserService.uploadSessionFile(formData)
          .then(
            (response) => {
              console.log(response);
            },
            (error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
              useToast().error(
                " Please check the details for each item sent.",
                {
                  icon: "fa fa-times",
                  position: "bottom-right",
                  timeout: 2000,
                }
              );
            }
          )
          .catch((error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
            useToast().error(" Please check the details for each item sent.", {
              icon: "fa fa-times",
              position: "bottom-right",
              timeout: 2000,
            });
          })
          .finally(() => (this.loading = false));
        start = end;
        end = Math.min(start + chunkSize, file.size);
        chunkNumber++;
        this.sendingFilePercent = Math.round((chunkNumber * 100) / totalChunk);
      }
    },

    checkDuplicateRow(arrayData) {
      let user = this.$store.state.auth.user;
      this.successStep = "process";
      let uniqueObjects = new Map();
      let ctx = this;
      let validateStatus = true;
      this.dupRowIndex = [];
      this.invalidRowIndex = [];
      arrayData.forEach((obj, i) => {
        var key = `${obj.site_name}-${obj.charger_id}-${obj.start_time}-${obj.end_time}-${obj.usage_kWh}-${obj.port_id}`;
        obj.error_message = null;
        if (uniqueObjects.has(key)) {
          ctx.dupRowIndex.push(i);
          obj.is_duplicate = true;
        } else {
          uniqueObjects.set(key, obj);
        }
        obj.register_id = this.register_serial_id;
        obj.customer_id = user.id;
        obj.error_message = ctx.validateData(obj);
        if (validateStatus && obj.error_message.length > 0) {
          validateStatus = false;
        }
      });
      if (validateStatus) {
        this.activeStep = 2;
        this.successStep = "process";
      } else {
        this.successStep = "error";
        this.removeInvalidRow = false;
        ElMessageBox.confirm(
          "Do you want to send rows without errors?",
          "confirm",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "Confirm Delete",
          }
        )
          .then(() => {
            this.removeInvalidRow = true;
            this.activeStep = 2;
            this.stepperChange(3);
          })
          .catch(() => {
            this.successStep = "error";
            this.removeInvalidRow = false;
          });
      }
    },

    validateData(item) {
      var error = [];
      // title
      if (item.site_name == null) {
        error.push("Site Name can not be null or empty.");
      }
      // // postcode
      // if (item.postcode == null) {
      //   error.push("Postcode can not be null or empty.");
      // }
      // charger_id
      if (item.charger_id == null) {
        error.push("Charger ID can not be null or empty.");
      }
      // port_id
      if (item.port_id == null) {
        error.push("port id can not be null or empty.");
      }
      // start_time
      if (item.start_time == null || item.start_time == "") {
        error.push("Start Date can not be null or empty.");
      }
      if (typeof item.start_time !== "string") {
        error.push("Start Date be of type string.");
      }
      if (
        item.start_time != null &&
        item.start_time != "" &&
        !this.isValidDate(item.start_time)
      ) {
        error.push("Start Date is invalid.");
      }
      // end_time
      if (item.end_time == null || item.end_time == "") {
        error.push("End Date can not be null or empty.");
      }
      if (typeof item.end_time !== "string") {
        error.push("End Date be of type string.");
      }
      if (
        item.end_time != null &&
        item.end_time != "" &&
        !this.isValidDate(item.end_time)
      ) {
        error.push("End Date is invalid.");
      }
      // if (
      //   item.start_time != null &&
      //   item.end_time != null &&
      //   this.isValidDate(item.start_time) &&
      //   this.isValidDate(item.end_time) &&
      //   moment(item.start_time) >= moment(item.end_time)
      // ) {
      //   error.push("Start Time is after the End Time");
      // }
      // if (
      //   item.start_time != null &&
      //   item.end_time != null &&
      //   this.isValidDate(item.start_time) &&
      //   this.isValidDate(item.end_time) &&
      //   moment(item.start_time) < moment(item.end_time) &&
      //   moment(item.end_time).diff(moment(item.start_time), "hours") > 48
      // ) {
      //   error.push("Long sessions (longer than 48 hours)");
      // }

      // usage_kWh
      if (item.usage_kWh == null) {
        error.push("Usage kWh can not be null or empty.");
      }
      if (!this.isNumeric(item.usage_kWh)) {
        error.push("Usage kWh be of type number.");
      }
      // if (item.usage_kWh != null && item.usage_kWh <= 0) {
      //   error.push("Usage kWh is invalid value.");
      // }
      // if (item.usage_kWh != null && item.usage_kWh > 300) {
      //   error.push("Sessions with more than 300 kWh usage.");
      // }
      // charger_capacity_kW
      //if (item.charger_capacity_kW == null) {
      //  error.push("Charger Capacity kW can not be null or empty.");
      //}

      return error;
    },

    validateAfterSend(
      keyDup,
      keyInvalidSite,
      keyInvalidCharger,
      keyInvalidPort,
      keyInvalidCapacity,
      keyInvalidPostcode,
      keyInvalidAboveCapacity
    ) {
      let validateStatus = true;
      this.dupRowIndex = [];
      this.response_alert.all_rows_count = this.excelDataList.length;
      this.response_alert.success_count = 0;
      this.response_alert.success_sum_kwh = 0;
      this.response_alert.pending_count = 0;
      this.response_alert.pending_sum_kwh = 0;
      this.response_alert.error_count = 0;
      this.response_alert.error_sum_kwh = 0;
      this.response_alert.duplicate_count = 0;
      this.excelDataList.forEach((obj) => {
        let obj_site_name =
          obj.site_name == null || obj.site_name == undefined
            ? ""
            : obj.site_name.toString().toUpperCase();
        let obj_charger_id =
          obj.charger_id == null || obj.charger_id == undefined
            ? ""
            : obj.charger_id.toString().toUpperCase();
        let obj_port_id =
          obj.port_id == null || obj.port_id == undefined
            ? ""
            : obj.port_id.toString().toUpperCase();
        // console.log(obj);
        // console.log(`${obj_site_name};${obj_charger_id};${obj.postcode}`);
        // console.log(
        //   `${obj_site_name};${obj_charger_id};${obj.charger_capacity_kW}`
        // );
        // console.log(
        //   `${obj_site_name};${obj_charger_id};${obj_port_id};${obj.usage_kWh}`
        // );
        // keyDup
        if (
          keyDup != "" &&
          keyDup
            .split(",")
            .includes(
              `${obj_site_name};${obj_charger_id};${
                obj_port_id === undefined ? "null" : obj_port_id
              };${obj.usage_kWh}`
            )
        ) {
          this.response_alert.duplicate_count += 1;
          obj.error_message.push("dup");
          // obj.error_message.push(
          //   "The session with this information already exist in the database"
          // );
        }
        // keyInvalidSite
        if (
          keyInvalidSite != "" &&
          keyInvalidSite.split(",").includes(`${obj_site_name}`)
        ) {
          obj.error_message.push("invalid site");
        }
        // keyInvalidCharger
        if (
          keyInvalidCharger != "" &&
          keyInvalidCharger
            .split(",")
            .includes(`${obj_site_name};${obj_charger_id}`)
        ) {
          obj.error_message.push("invalid charger");
        }
        // keyInvalidPort
        if (
          keyInvalidPort != "" &&
          keyInvalidPort
            .split(",")
            .includes(`${obj_site_name};${obj_charger_id};${obj_port_id}`)
        ) {
          obj.error_message.push("invalid port id");
        }
        // keyInvalidCapacity
        if (
          keyInvalidCapacity != "" &&
          keyInvalidCapacity
            .split(",")
            .includes(
              `${obj_site_name};${obj_charger_id};${obj.charger_capacity_kW}`
            )
        ) {
          obj.error_message.push("charger capacity mismatch");
        }
        // keyInvalidPostcode
        if (
          keyInvalidPostcode != "" &&
          keyInvalidPostcode
            .split(",")
            .includes(`${obj_site_name};${obj_charger_id};${obj.postcode}`)
        ) {
          obj.error_message.push("charger postcode mismatch");
        }
        // keyInvalidAboveCapacity
        if (
          keyInvalidAboveCapacity != "" &&
          keyInvalidAboveCapacity
            .split(",")
            .includes(
              `${obj_site_name};${obj_charger_id};${obj_port_id};${obj.usage_kWh}`
            )
        ) {
          obj.error_message.push(
            "the power drown during the charging session is above 1.05 * charger capacity."
          );
        }
        if (validateStatus && obj.error_message.length > 0) {
          validateStatus = false;
        }
      });

      // this.excelDataList = this.excelDataList.filter(
      //   (item) => item.error_message.length != 0
      // );

      let successCount = this.excelDataList.filter(
        (item) => item.error_message.length == 0
      );
      for (const item of successCount) {
        this.response_alert.success_count += 1;
        this.response_alert.success_sum_kwh += item.usage_kWh;
      }
      this.excelDataList = this.excelDataList.filter(
        (item) =>
          item.error_message.length != 0 && item.error_message[0] != "dup"
      );
      for (const item of this.excelDataList) {
        this.response_alert.error_count += 1;
        this.response_alert.error_sum_kwh += item.usage_kWh;
      }
      return validateStatus;
    },

    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      // if (this.dupRowIndex.includes(rowIndex)) {
      if (row.is_duplicate) {
        return "warning-row";
      } else if (
        Object.keys(row).includes("error_message") &&
        row.error_message.length > 0
      ) {
        return "error-row";
      }
      return "";
    },

    isValidDate(dateString) {
      const dateRegex = /^(\d{4})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
      if (dateRegex.test(dateString)) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },

    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },

    stepperChange(num) {
      if (num == 2 && this.excelDataList.length > 0 && this.activeStep == 1) {
        // console.log(this.excelDataList);
        this.checkDuplicateRow(this.excelDataList);
      } else if (
        num == 3 &&
        this.excelDataList.length > 0 &&
        this.activeStep == 2
      ) {
        //var jsonDate = JSON.stringify(this.excelDataList);
        //console.log(jsonDate);
        console.log(moment(new Date()).format("HH:mm:ss"));
        this.sendDate(this.excelDataList);
      }
    },

    sendDate(jsonData) {
      this.loading = true;
      // let duplicate = "";
      // let invalid_charger_data = "";
      // let invalid_port_data = "";
      // let invalid_site_data = "";
      // let invalid_capacity_data = "";
      // let invalid_postcode_data = "";
      // let invalid_above_capacity_data = "";
      jsonData = jsonData.filter((item) => {
        // if (this.removeInvalidRow) {
        return (
          !item.is_duplicate &&
          (item.error_message == null || item.error_message.length == 0)
        );
        // }
      });
      if (jsonData.length == 0) {
        this.loading = false;
        useToast().error(" All rows have errors. There is no row to send.", {
          icon: "fa fa-times",
          position: "bottom-right",
          timeout: 2000,
        });
      } else {
        // console.log(jsonData);
        UserService.setSessions(jsonData)
          .then(
            // eslint-disable-next-line no-unused-vars
            (response) => {
              console.log(moment(new Date()).format("HH:mm:ss"));
              // console.log(response);
              this.excelDataList = [];
              this.activeStep = 3;
              useToast().success(
                "The submitted file has been received successfully. The result will be emailed to you in a few minutes ",
                {
                  icon: "fa fa-check",
                  position: "bottom-right",
                  timeout: 2000,
                }
              );
              this.onRemove();
              this.$refs.upload.clearFiles();
              // if (response.data.data.duplicate != null) {
              //   duplicate = response.data.data.duplicate;
              // }
              // if (response.data.data.invalid_charger_id != null) {
              //   invalid_charger_data = response.data.data.invalid_charger_id;
              // }
              // if (response.data.data.invalid_port_id != null) {
              //   invalid_port_data = response.data.data.invalid_port_id;
              // }
              // if (response.data.data.invalid_site_title != null) {
              //   invalid_site_data = response.data.data.invalid_site_title;
              // }
              // if (response.data.data.invalid_capacity != null) {
              //   invalid_capacity_data = response.data.data.invalid_capacity;
              // }
              // if (response.data.data.invalid_postcode != null) {
              //   invalid_postcode_data = response.data.data.invalid_postcode;
              // }
              // if (response.data.data.invalid_above_capacity != null) {
              //   invalid_above_capacity_data =
              //     response.data.data.invalid_above_capacity;
              // }

              // this.response_alert.pending_count =
              //   response.data.data.pending_count;
              // this.response_alert.pending_sum_kwh =
              //   response.data.data.pending_sum_kwh;
              // let isValid = this.validateAfterSend(
              //   duplicate,
              //   invalid_site_data,
              //   invalid_charger_data,
              //   invalid_port_data,
              //   invalid_capacity_data,
              //   invalid_postcode_data,
              //   invalid_above_capacity_data
              // );
              // // console.log(this.response_alert);
              // if (isValid) {
              //   this.activeStep = 3;
              //   useToast().success(" Send session file successfully ", {
              //     icon: "fa fa-check",
              //     position: "bottom-right",
              //     timeout: 2000,
              //   });
              // } else {
              //   this.activeStep = 1;
              //   this.successStep = "error";
              //   useToast().error(
              //     " Please check the details for each item sent.",
              //     {
              //       icon: "fa fa-times",
              //       position: "bottom-right",
              //       timeout: 2000,
              //     }
              //   );
              // }

              // this.responseAlert.visible = true;
              // // this.responseAlert.type = "info";
              // this.responseAlert.title = `From the ${this.response_alert.all_rows_count} rows sent in the Excel file`;
              // this.responseAlert.success_desc =
              //   `Success : ` +
              //   `${this.response_alert.success_count} records with ${this.response_alert.success_sum_kwh} kWh were registered successfully.`;
              // this.responseAlert.pending_desc =
              //   `Pending : ` +
              //   `${this.response_alert.pending_count} records with ${this.response_alert.pending_sum_kwh} kwh have errors and were considered pending.`;
              // this.responseAlert.duplicate_desc =
              //   `Duplicate : ` +
              //   `${this.response_alert.duplicate_count} records were already in the database, and are not inserted again.`;
              // // console.log(this.response_alert);
              // // console.log(this.responseAlert);
            },
            (error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
              useToast().error(
                " Please check the details for each item sent.",
                {
                  icon: "fa fa-times",
                  position: "bottom-right",
                  timeout: 2000,
                }
              );
            }
          )
          .catch((error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
            useToast().error(" Please check the details for each item sent.", {
              icon: "fa fa-times",
              position: "bottom-right",
              timeout: 2000,
            });
          })
          .finally(() => (this.loading = false));
      }
    },

    // eslint-disable-next-line no-unused-vars
    handleRowClick(row, column, event) {
      console.log(row, column, event);
      // this.$notify.error({
      //   title: "Validate Error",
      //   message: "",
      //   showClose: false,
      // });
    },
    // eslint-disable-next-line no-unused-vars
    close_modal(event) {
      this.show_modal = false;
    },

    confirm_modal(event) {
      this.excelDataList[this.current_row_index] = event;
      this.dupRowIndex.splice(this.current_row_index, 1);
      event.is_duplicate = false;
      this.show_modal = false;
      this.activeStep = 1;
      this.successStep = "process";
    },

    handleShow(index, data) {
      this.current_row_index = index;
      this.data_modal = JSON.stringify(data);
      this.show_modal = true;
    },

    handlePerv() {
      if (this.activeStep == 1 && this.successStep == "error") {
        this.activeStep = 1;
        // this.successStep = "process";
        this.stepperChange(2);
      } else if (this.activeStep == 2) {
        this.activeStep = 1;
        this.successStep = "process";
      } else if (this.activeStep == 3) {
        this.stepperChange(2);
      }
    },

    handleNext() {
      if (this.activeStep == 1) {
        this.stepperChange(2);
      } else if (this.activeStep == 2 && this.successStep == "process") {
        this.stepperChange(3);
      }
    },

    current_change_click() {},

    paginate(array, page_size, page_number) {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },

    filterAction(event) {
      if (event.kind == "refresh") {
        this.refresh_filter(event);
      } else if (event.kind == "reset") {
        this.reset_filter(event);
      } else if (event.kind == "excel") {
        this.handleExportFile();
      } else if (event.kind == "prev") {
        this.handlePerv();
      } else if (event.kind == "next") {
        this.handleNext();
      }
    },

    filterSearch(event) {
      this.search = event.search;
    },

    refresh_filter(event) {
      this.filter = event.filter;
      this.current_page = 1;
      this.fromDate = this.filter.fromDate;
      this.toDate = this.filter.toDate;
      this.search = this.filter.search;
      this.statusRow = this.filter.statusRow;
      console.log(this.filter);
    },

    reset_filter(event) {
      this.current_page = 1;
      this.fromDate = "";
      this.toDate = "";
      this.search = "";
      this.statusRow = 1;
      this.filter = event.filter;
    },

    handleExportFile() {
      if (this.excelDataList.length > 0) {
        let modifiedArr = this.excelDataList.map((item) => {
          let {
            // eslint-disable-next-line no-unused-vars
            error_message,
            // eslint-disable-next-line no-unused-vars
            register_id,
            // eslint-disable-next-line no-unused-vars
            customer_id,
            // eslint-disable-next-line no-unused-vars
            is_duplicate,
            ...rest
          } = item;
          return rest;
        });
        const ws = XLSX.utils.json_to_sheet(modifiedArr, {
          header: Object.keys(modifiedArr[0]),
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "SessionList.xlsx");
      }
    },

    missingAlertCloseHandle() {
      this.alertWrongFile = false;
      this.missingColumns = [];
    },
  },
};
</script>

<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-8);
}
.el-table .error-row {
  --el-table-tr-bg-color: var(--el-color-error-light-8);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.w-80 {
  width: 80px !important;
}
.el-button--primary {
  color: white !important;
}
.el-button--primary :hover {
  color: black !important;
}
.cell {
  text-align: center !important;
}

.upload-demo > ul {
  top: 0 !important;
  left: 0 !important;
}

.session-tab > ul {
  top: 18px !important;
}

.progress-style > .el-progress__text {
  color: white;
  text-align: center;
}
</style>
