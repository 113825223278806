<template>
  <el-col
    class="d-flex align-item-center"
    :span="$vuetify.display.width >= 1500 ? 8 : 24"
    :class="$vuetify.display.width < 1500 ? 'mt-2' : ''"
  >
    <div class="width-100-p px-2">
      <el-col :span="24" class="width-100-p p-2 height-100-p">
        <div
          class="row m-0 p-2 bg-me-primary border-color-light rounded-4 height-100-p"
        >
          <el-col :span="24">
            <el-row class="m-0">
              <el-col
                :span="
                  $vuetify.display.width >= 1500 || $vuetify.display.width < 750
                    ? 24
                    : 16
                "
              >
                <h5 class="text-white mt-3 ml-1">Site performance</h5>
              </el-col>
              <el-col
                :span="
                  $vuetify.display.width >= 1500 || $vuetify.display.width < 750
                    ? 24
                    : 8
                "
                :class="
                  $vuetify.display.width >= 1500 || $vuetify.display.width < 750
                    ? 'mt-2'
                    : 'y-center x-end'
                "
              >
                <el-button
                  @click="customDate = !customDate"
                  color="#0D3937"
                  class="height-40 border-color-light rounded-3 mr-1"
                >
                  <svg class="stroke-icon width-20 height-20">
                    <use href="@/assets/svg/icon-sprite.svg#stroke-date"></use>
                  </svg>
                  <span class="text-white pl-3"> Custom date </span>
                </el-button>
              </el-col>
            </el-row>
          </el-col>
          <!--  -->
          <el-row v-if="customDate" class="y-center m-0 p-2">
            <div
              class="row y-center m-0 mt-2 p-2 bg-me-primary border-color-light rounded-4 width-100-p"
              :class="$vuetify.display.width >= 700 ? 'height1-100' : ''"
            >
              <el-form :label-position="`top`" class="text-center">
                <el-space fill style="width: 100%">
                  <el-row gutter="20">
                    <el-col
                      :span="
                        $vuetify.display.width >= 1500 ||
                        $vuetify.display.width <= 500
                          ? 24
                          : 12
                      "
                    >
                      <el-form-item
                        class="label-style mb-1"
                        label="Start Date : "
                      >
                        <el-date-picker
                          v-model="filter.start_date"
                          type="date"
                          placeholder="Pick a day"
                          size="default"
                          class="w-100"
                          format="DD/MM/YYYY"
                          value-format="YYYY-MM-DD"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="
                        $vuetify.display.width >= 1500 ||
                        $vuetify.display.width <= 500
                          ? 24
                          : 12
                      "
                    >
                      <el-form-item
                        class="label-style mb-1"
                        label="End Date : "
                      >
                        <el-date-picker
                          v-model="filter.end_date"
                          type="date"
                          placeholder="Pick a day"
                          size="default"
                          class="w-100"
                          format="DD/MM/YYYY"
                          value-format="YYYY-MM-DD"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-space>
              </el-form>
              <el-row gutter="20" class="float-right mt-5">
                <el-col
                  :span="
                    $vuetify.display.width > 500 &&
                    $vuetify.display.width < 1500
                      ? 16
                      : 24
                  "
                  class="px-1 y-center height-50"
                  :class="
                    $vuetify.display.width > 500 &&
                    $vuetify.display.width < 1500
                      ? 'py-2 x-start'
                      : 'height-100-p'
                  "
                >
                  <el-button
                    color="#ddf0ac"
                    @click="handleCustomDate"
                    class="height-40 rounded-3 width-100 ml-2"
                    :class="
                      $vuetify.display.width > 500 &&
                      $vuetify.display.width < 1500
                        ? 'x-start'
                        : 'x-y-center'
                    "
                  >
                    <svg class="stroke-icon width-20 height-20">
                      <use
                        href="@/assets/svg/icon-sprite.svg#stroke-refresh"
                      ></use>
                    </svg>
                    <span class="me-primary pl-3"> Apply </span>
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </el-row>

          <!--  -->
          <el-col :span="24" class="height-100-p p-2">
            <div class="ma-0 dddfdd" v-loading="loading" ref="performingTbl">
              <el-table
                :data="dataList"
                :fit="true"
                class="my-2 width-100-p data-table"
                stripe
                scrollbar-always-on
                :height="
                  $vuetify.display.width >= 1500
                    ? customDate
                      ? 422
                      : 640
                    : 400
                "
              >
                <el-table-column
                  v-if="
                    $store.state.auth.user != null &&
                    $store.state.auth.user.is_admin
                  "
                  :label="'Customer'"
                  :prop="'customer__full_name'"
                  :width="performTblWidth < 1010 ? 200 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Site Name'"
                  :prop="'title'"
                  :width="performTblWidth < 1010 ? 150 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Location'"
                  :prop="'state'"
                  :width="performTblWidth < 1010 ? 120 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Abatement (tCO2-e)'"
                  :prop="'total_abatement_tonne'"
                  :width="performTblWidth < 1010 ? 200 : ''"
                  sortable
                >
                  <template #default="scope">
                    {{
                      scope.row.total_abatement_tonne == null
                        ? 0
                        : scope.row.total_abatement_tonne.toLocaleString()
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Usage (MWh)'"
                  :prop="'total_usage_MWh'"
                  :width="performTblWidth < 1010 ? 140 : ''"
                  sortable
                >
                  <template #default="scope">
                    {{
                      scope.row.total_usage_MWh == null
                        ? 0
                        : scope.row.total_usage_MWh.toLocaleString()
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Abatement per MWh'"
                  :prop="'abatement_per_MWh'"
                  :width="performTblWidth < 1010 ? 200 : ''"
                  sortable
                >
                  <template #default="scope">
                    {{
                      scope.row.abatement_per_MWh == null
                        ? 0
                        : scope.row.abatement_per_MWh.toLocaleString()
                    }}
                  </template>
                </el-table-column>
              </el-table>
              <el-row class="row-bg" justify="space-between">
                <el-col
                  :span="paginateWidth > 190 ? 12 : 5"
                  :class="paginateWidth > 190 ? 'order-2' : ''"
                  class="d-flex justify-content-start align-items-center"
                >
                  <el-select
                    v-model="perPageCount"
                    placeholder="Select"
                    size="small"
                    style="width: 70px"
                  >
                    <el-option label="15" :value="'15'" />
                    <el-option label="25" :value="'25'" />
                    <el-option label="50" :value="'50'" />
                    <el-option label="100" :value="'100'" />
                  </el-select>
                </el-col>
                <el-col
                  ref="paginate"
                  :span="paginateWidth > 190 ? 24 : 14"
                  :class="paginateWidth > 190 ? 'order-1' : ''"
                  class="d-flex align-items-center justify-content-center"
                >
                  <el-pagination
                    small
                    background
                    layout="prev, pager, next"
                    :total="total_items"
                    v-model:page-size="page_size"
                    v-model:current-page="current_page"
                    @current-change="changePage"
                    class="my-4"
                  />
                </el-col>
                <el-col
                  :span="paginateWidth > 190 ? 12 : 5"
                  :class="paginateWidth > 190 ? 'order-3' : ''"
                  class="d-flex justify-content-end align-items-center"
                >
                  <span class="text-white">
                    {{ (current_page - 1) * perPageCount + 1 }}-{{
                      current_page * perPageCount > total_items
                        ? total_items
                        : current_page * perPageCount
                    }}
                    of {{ total_items }}
                  </span>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </div>
      </el-col>
    </div>
  </el-col>
</template>

<script>
import UserService from "../../../services/user.service";
export default {
  props: {
    filterProp: {
      type: Object,
      default: null,
    },
  },
  components: {},
  watch: {
    filterProp: {
      handler: function (newVal) {
        this.filter.customer_id = newVal.customer_id;
        this.filter.state = newVal.state;
        this.fetchData();
      },
      deep: true,
    },
    current_page: function (newVal) {
      this.current_page = newVal;
      this.fetchData();
    },
    perPageCount: function (newVal) {
      this.current_page = 1;
      this.page_size = parseInt(newVal);
      this.fetchData();
    },
    "$vuetify.display.width"() {
      this.paginateWidth = this.$refs.paginate.$el.clientWidth;
      this.performTblWidth = this.$refs.performingTbl.clientWidth;
      //console.log(this.$refs.performingTbl.clientWidth);
    },
    // "$refs.performingTbl.clientWidth"(val) {
    //   console.log(val);
    // },
  },
  data() {
    return {
      customDate: false,
      search: "",
      current_page: 1,
      page_size: 15,
      total_items: 100,
      loading: false,
      chargerFilter: 1,
      perPageCount: 15,
      paginateWidth: 0,
      performTblWidth: 0,
      dataList: [],
      filter: {
        customer_id: null,
        state: "",
        start_date: null,
        end_date: null,
      },
    };
  },
  mounted() {
    this.fetchData();
    // console.log(this.$refs.paginate);
  },
  methods: {
    fetchData() {
      // console.log(this.filterProp);
      // this.filter = this.filterProp;
      this.filter.state =
        this.filterProp.state == "All" ? "" : this.filterProp.state;
      this.loading = true;
      UserService.GetTopPerformingSites(
        this.filter,
        this.current_page,
        this.perPageCount
      )
        .then(
          (response) => {
            this.total_items = response.data.total_record;
            this.dataList = response.data.data;
            if (this.$refs.paginate != null && this.$refs.paginate.$el != null)
              this.paginateWidth = this.$refs.paginate.$el.clientWidth;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    handleCustomDate() {
      this.fetchData();
    },
  },
};
</script>
