<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img
        class="b-r-10"
        src="@/assets/images/dashboard/profile.png"
        width="36"
        height="36"
        alt=""
      />
      <div class="media-body">
        <span class="text-white">
          {{
            $store.state.auth.user == null
              ? ""
              : $store.state.auth.user.full_name
          }}
        </span>
        <p class="mb-0 font-roboto text-white">
          {{
            $store.state.auth.user == null ? "" : $store.state.auth.user.email
          }}
          <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div bg-me-primary text-white">
      <li @click="frmAccount">
        <vue-feather class="text-white" type="user"></vue-feather
        ><span class="text-white"> Account </span>
      </li>
      <li @click="logout">
        <vue-feather class="text-white" type="log-in"></vue-feather
        ><span class="text-white">Log out</span>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Profile",
  methods: {
    logout: function () {
      this.$router.replace("/");
      this.$store.dispatch("auth/logout");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
    frmAccount() {
      this.$router.replace("/account");
    },
  },
};
</script>
