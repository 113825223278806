export const numberSessionChart = {
    series: [{
        name: "Number of sessions",
        type: "column",
        // data: [440, 505, 414, 671, 227, 413, 201, 352, 452, 320, 257, 160],
        data: [],
        color: "#78BE20",
    }, ],
    chartOptions: {
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        labels: [],
        xaxis: {
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            decimalsInFloat: 0,
            title: {
                text: "Number of sessions",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
            axisBorder: {
                show: false,
                color: "#FFFFFF",
            },
            axisTicks: {
                show: false,
                color: "#FFFFFF",
            },
        },
        legend: {
            position: "top",
            labels: {
                colors: "#FFFFFF",
            },
            markers: {
                fillColors: ["#78BE20"],
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        }
    },
}

export const usageSessionChart = {
    series: [{
        name: "Hourly usage (MWh)",
        type: "column",
        // data: [440, 505, 414, 671, 227, 413, 201, 352, 452, 320, 257, 160],
        data: [],
        color: "#78BE20",
    }, ],
    chartOptions: {
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        labels: [],
        xaxis: {
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            decimalsInFloat: 0,
            title: {
                text: "Usage (MWh)",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
            axisBorder: {
                show: false,
                color: "#FFFFFF",
            },
            axisTicks: {
                show: false,
                color: "#FFFFFF",
            },
        },
        legend: {
            position: "top",
            labels: {
                colors: "#FFFFFF",
            },
            markers: {
                fillColors: ["#DDF0AC"],
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        }
    },
}