<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-dialog
    v-model="show"
    width="600"
    :fullscreen="$vuetify.display.width <= 600 ? true : false"
    class="public-style"
    destroy-on-close
    align-center
    @close="close"
  >
    <el-form
      ref="ruleFormRef"
      :rules="rules"
      :model="customer_data"
      :style="[$vuetify.display.width < 600 ? 'height: 550;' : '']"
      :label-position="`top`"
    >
      <el-space fill style="width: 100%">
        <el-alert type="info" class="alert-style" show-icon :closable="false">
          <p class="ma-0 text-white">Customer :</p>
        </el-alert>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="full_name"
              label="Full Name: "
            >
              <el-input v-model="customer_data.full_name" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 6 : 24">
            <el-form-item label="">
              <el-checkbox
                class="text-white"
                v-model="customer_data.private_fleet"
                label="Private Fleet"
                size="large"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item class="label-style" prop="phone" label="Phone: ">
              <el-input v-model="customer_data.phone" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item class="label-style" prop="country" label="Country: ">
              <el-input v-model="customer_data.country" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="effective_from"
              label="Effective From: "
            >
              <!-- <el-input v-model="customer_data.effective_from" /> -->
              <el-date-picker
                v-model="customer_data.effective_from"
                type="date"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
                class="dtp-customer w-100"
                prefix-icon=""
              />
            </el-form-item>
          </el-col>

          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="max_usage"
              label="Max Usage: "
            >
              <el-input v-model="customer_data.max_usage" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-space>
    </el-form>
    <!-- </el-row> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button
          size="medium"
          class="me-primary-light"
          @click.stop="confirm"
          color="#234B49"
        >
          <i class="fa fa-check me-2 me-primary-light"></i> Confirm
        </el-button>
        <el-button
          size="medium"
          class="me-primary"
          @click.stop="close"
          color="#ddf0ac"
        >
          <i class="fa fa-times me-2 me-primary"></i> Cancel
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// import "animate.css";
import UserService from "../../services/user.service";
import moment from "moment";
export default {
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    data_modal: {
      type: Object,
      // eslint-disable-next-line no-unused-vars
      default(rawProps) {
        return rawProps;
      },
    },
  },
  data() {
    return {
      show: this.open_modal,
      rules: {
        full_name: {
          required: true,
          message: "Please enter full name.",
          trigger: "blur",
        },
        country: {
          required: true,
          message: "Please enter country.",
          trigger: "blur",
        },
        phone: {
          type: "number",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
              if (
                value != null &&
                (value != "") & (value != undefined) & !this.isNumeric(value)
              ) {
                reject("phone must be digit");
              } else {
                resolve();
              }
            });
          },
        },
        effective_from: {
          type: "date",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
              if (!this.isValidDate(value)) {
                reject("effective from must be valid date"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
        max_usage: [
          {
            required: true,
            message: "Please enter max_usage.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("max_usage must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
      customer_data: {
        full_name: "",
        phone: "",
        effective_from: "",
        private_fleet: false,
        country: true,
        id: 0,
      },
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    open_modal: function (newVal, oldVal) {
      this.show = newVal;
      if (newVal == true) {
        this.customer_data = JSON.parse(this.data_modal);
        if (this.customer_data.effective_from != "")
          this.customer_data.effective_from = moment(
            this.customer_data.effective_from
          ).format("yyyy-MM-DD");
      }
      console.log(this.customer_data);
    },
  },
  methods: {
    isNewUser() {
      if (this.customer_data.id == 0) return true;
      else return false;
    },
    close() {
      this.show = false;
      this.$emit("close_modal", false);
    },
    async confirm() {
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid) {
          console.log(this.customer_data);
          UserService.SetCustomer(this.customer_data)
            .then(
              (response) => {
                // this.userDataList.splice(index, 1);
                this.$emit("confirm_modal", response.data.customer);
              },
              (error) => {
                this.loading = false;
                console.log(error);
                if (error.response.status == 401) {
                  this.$router.push({
                    path: "/",
                  });
                }
              }
            )
            .catch((error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
            })
            .finally(() => (this.loading = false));
        } else {
          console.log("error submit!", fields);
        }
      });
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isValidDate(dateString) {
      const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
      if (dateRegex.test(dateString)) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 30px 10px;
}
.el-input__inner {
  padding: 0 10px !important;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.el-form-item__label {
  font-size: 11px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.el-form-item__content > .el-checkbox {
  margin-top: 16px;
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.dtp-customer > .el-input__wrapper {
  padding: 0 10px !important;
  height: 32px !important;
}
</style>
