export const sessionChart = {
    series: [{
            name: "Number of sessions",
            type: "column",
            // data: [440, 505, 414, 671, 227, 413, 201, 352, 452, 320],
            data: [],
            color: "#78BE20",
        },
        {
            name: "Total usage (MWh)",
            type: "column",
            // data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
            data: [],
            color: "#DDF0AC",
        },
    ],
    chartOptions: {
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        // labels: [
        //     "1",
        //     "2",
        //     "3",
        //     "4",
        //     "5",
        //     "6",
        //     "7",
        //     "8",
        //     "9",
        //     "10",
        // ],
        labels: [],
        xaxis: {
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        yaxis: [{
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                title: {
                    text: "Number of Session",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
            {
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                opposite: true,
                title: {
                    text: "Total Usage (MWh)",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
        ],
        legend: {
            position: "top",
            labels: {
                colors: "#FFFFFF",
            },
            markers: {
                fillColors: ["#78BE20", "#DDF0AC"],
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: [{
                    formatter: function(val) {
                        return val
                    }
                },
                {
                    formatter: function(val) {
                        return val + " MWh"
                    }
                }
            ]
        }
    },
}