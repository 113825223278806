<template>
  <el-col :span="24">
    <div
      class="ma-0"
      v-loading="loading"
      :element-loading-spinner="svg"
      element-loading-svg-view-box="-10, -10, 50, 50"
      element-loading-background="rgba(122, 122, 122, 0.8)"
    >
      <el-table
        :data="userDataList"
        :fit="true"
        height="300"
        :row-class-name="tableRowClassName"
        @row-click="handleRowClick"
        class="my-2 data-table"
        scrollbar-always-on
      >
        <el-table-column
          :label="'Customer Name'"
          :prop="'customer_full_name'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        >
          <template #default="scope">
            <span class="line-clamp-2">
              {{ scope.row.customer_full_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="'Subject'"
          :prop="'subject'"
          :width="$vuetify.display.width <= 1600 ? 250 : ''"
          sortable
        >
          <template #default="scope">
            <span class="line-clamp-2"> {{ scope.row.subject }} </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="'Date'"
          :prop="'support_date'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        >
          <template #default="scope">
            <i class="fa fa-calendar f-14 me-primary mr-2"></i>
            {{ scope.row.support_date.replace("T", " ").substring(0, 19) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="'Status'"
          :prop="'status_id'"
          :width="$vuetify.display.width <= 1600 ? 170 : ''"
          sortable
        >
          <template #default="scope">
            <h6 class="mb-0">
              <span
                class="badge width-120"
                :class="
                  scope.row.status_id === '1'
                    ? 'badge-primary'
                    : scope.row.status_id === '2'
                    ? 'badge-warning'
                    : scope.row.status_id === '3'
                    ? 'badge-secondary'
                    : scope.row.status_id === '4'
                    ? 'badge-info'
                    : scope.row.status_id === '5'
                    ? 'badge-success'
                    : 'badge-dark'
                "
              >
                {{
                  scope.row.status_id === "1"
                    ? "Sent"
                    : scope.row.status_id === "2"
                    ? "Processing"
                    : scope.row.status_id === "3"
                    ? "Admin responded"
                    : scope.row.status_id === "4"
                    ? "User responded"
                    : scope.row.status_id === "5"
                    ? "Finalized"
                    : "Closed"
                }}
              </span>
            </h6>
          </template>
        </el-table-column>
        <el-table-column
          :label="'Last follow up'"
          :prop="'last_follow_up'"
          :width="$vuetify.display.width <= 1600 ? 160 : ''"
          sortable
        >
          <template #default="scope">
            {{
              scope.row.support_details_count == 0
                ? formattedDate(scope.row.created_at)
                : formattedDate(scope.row.last_follow_up)
            }}
          </template>
        </el-table-column>
        <el-table-column
          :label="'#Messages'"
          :prop="'conversations_count'"
          :width="$vuetify.display.width <= 1600 ? 160 : ''"
          sortable
        >
          <template #default="scope">
            <!-- <span class="badge bg-me-primary f-14">
                      <i class="fa fa-comments mr-1"></i>
                      {{ scope.row.support_details.length + 1 }}
                    </span> -->
            <el-tag class="ml-2" type="info" effect="dark">
              <i class="fa fa-comments mr-1"></i>
              {{ scope.row.support_details_count }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="$vuetify.display.width < 500"
          :label="'Actions'"
          :prop="'operations'"
          align="center"
          width="150"
        >
          <template #default="scope">
            <el-button
              color="#0d3937"
              @click.stop="handleShow(scope.$index, scope.row)"
              size="small"
              class="me-primary-light"
            >
              <i class="fa fa-eye"></i>
              <span class="pl-1">Show</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          fixed="right"
          :label="'Status'"
          :prop="'operations'"
          align="center"
          width="150"
        >
          <template #default="scope">
            <el-button
              color="#0d3937"
              @click.stop="handleShow(scope.$index, scope.row)"
              size="small"
              class="me-primary-light"
            >
              <i class="fa fa-eye"></i>
              <span class="pl-1"> Show </span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-row class="row-bg" justify="space-between">
        <el-col
          :span="5"
          class="d-flex justify-content-start align-items-center"
        >
          <el-select
            v-model="perPageCount"
            placeholder="Select"
            size="small"
            style="width: 70px"
          >
            <el-option label="10" :value="'10'" />
            <el-option label="25" :value="'25'" />
            <el-option label="50" :value="'50'" />
            <el-option label="100" :value="'100'" />
          </el-select>
        </el-col>
        <el-col
          :span="8"
          class="d-flex align-items-center justify-content-center"
        >
          <el-pagination
            small
            background
            layout="prev, pager, next"
            :total="total_items"
            v-model:page-size="page_size"
            v-model:current-page="current_page"
            @current-change="changePage"
            class="my-4"
          />
        </el-col>
        <el-col :span="5" class="d-flex justify-content-end align-items-center">
          <span class="text-white">
            {{ (current_page - 1) * perPageCount + 1 }}-{{
              current_page * perPageCount > total_items
                ? total_items
                : current_page * perPageCount
            }}
            of {{ total_items }}
          </span>
        </el-col>
      </el-row>
    </div>
  </el-col>
</template>

<script>
// import { ElMessageBox } from "element-plus";
// import ShowDetail from "./dialogDetail.vue";
// import UserService from "../../services/user.service";
import moment from "moment";

export default {
  props: {
    loadingProp: {
      type: Boolean,
      default: false,
    },
    usersDataProp: {
      type: Array,
      default(rawProps) {
        return rawProps;
      },
    },
    totalRowProp: {
      type: Number,
    },
  },
  components: {
    // ShowDetail,
  },
  data() {
    return {
      show: true,
      userDataList: [],
      search: "",
      current_page: 1,
      page_size: 50,
      total_items: 100,
      perPageCount: 50,
      loading: false,
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      filterOpen: false,
    };
  },
  watch: {
    loadingProp: {
      handler: function (newValue) {
        this.loading = newValue;
      },
      deep: true,
    },
    usersDataProp: {
      handler: function (newValue) {
        this.userDataList = newValue;
      },
      deep: true,
    },
    totalRowProp: {
      handler: function (newValue) {
        this.total_items = newValue;
      },
      deep: true,
    },
    perPageCount: function (newVal) {
      this.current_page = 1;
      this.page_size = parseInt(newVal);
      this.$emit("tableAction", {
        index: this.page_size,
        data: null,
        kind: "perPageChange",
      });
    },
  },
  methods: {
    handleRowClick(row, column, event) {
      console.log(row, column, event);
    },
    handleShow(index, data) {
      this.$emit("tableAction", { index: index, data: data, kind: "edit" });
    },
    deleteRow(event, index) {
      this.$emit("tableAction", { index: index, data: event, kind: "delete" });
    },
    changePage(page) {
      this.$emit("tableAction", {
        index: page,
        data: null,
        kind: "changePage",
      });
    },
    formattedDate(date) {
      return moment(date).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>
