<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Breadcrumbs main="Home" title="default" />

  <div class="container-fluid p-0">
    <div class="row widget-grid">
      <Filter
        :stateProp="stateList"
        @filterHandle="filterHandle"
        :hasCustomDate="true"
        :hasPeriod="true"
        :hasLocation="true"
        :periodNameProp="`2023 MP`"
      />
      <el-row class="m-0 width-100-p mt-3">
        <!-- Abatement -->
        <el-col
          class="d-flex align-item-center px-2"
          :span="$vuetify.display.width >= 1500 ? 16 : 24"
        >
          <div
            class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">Abatement (tCO2-e)</h5>
            </el-col>
            <!-- Abatement Chart -->
            <el-col
              v-loading="loadingChart"
              :span="
                $vuetify.display.width >= 1650
                  ? 16
                  : $vuetify.display.width >= 1500 &&
                    $vuetify.display.width < 1650
                  ? 24
                  : $vuetify.display.width >= 1300
                  ? 16
                  : 24
              "
            >
              <div id="chart" v-if="!loadingChart">
                <apexchart
                  type="line"
                  height="350"
                  :options="chartOptionsAbatement"
                  :series="seriesAbatement"
                ></apexchart>
              </div>
              <!-- <newChart /> -->
            </el-col>
            <!-- Abatement Card -->
            <AbatementCard :dataProp="abatementData" />
          </div>
        </el-col>
        <!-- History -->
        <el-col
          class="d-flex align-item-center px-2"
          :span="$vuetify.display.width >= 1500 ? 8 : 24"
          :class="$vuetify.display.width < 1500 ? 'mt-2' : ''"
        >
          <div
            class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">Abatement history</h5>
              <el-text tag="sub" size="small" class="pl-2 text-white">
                <li class="fa fa-refresh font-13 mr-2"></li>
                Last Update : {{ lastUpdateAbatement }}
              </el-text>
            </el-col>
            <el-col :span="24">
              <div id="chart" v-if="!loadingHistoryChart">
                <apexchart
                  type="bar"
                  height="350"
                  :options="chartOptionsHistory"
                  :series="seriesHistory"
                ></apexchart>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
      <DashboardCard :dataProp="abatementData" />
    </div>
  </div>
</template>

<script>
import Filter from "../common/filter.vue";
import { abatementChart } from "./config.js";
import AbatementCard from "./abatementCard.vue";
import DashboardCard from "./dashboardCard.vue";
// import newChart from "./chart.vue";
import UserService from "../../../services/user.service";
export default {
  props: {
    propCardData: {
      type: Object,
      default: null,
    },
    propCardDataList: {
      type: Array,
      default: null,
    },
  },
  components: {
    Filter,
    AbatementCard,
    DashboardCard,
    // newChart,
  },
  watch: {
    propCardData: {
      handler: function (newValue) {
        this.cardData = newValue;
      },
      deep: true,
    },
    propCardDataList: {
      handler: function (newValue) {
        this.cardDataList = newValue;
      },
      deep: true,
    },
  },
  computed: {
    stateList() {
      return this.states;
    },
  },
  data() {
    return {
      selectedPeriod: "All",
      abatementData: null,
      loadingChart: false,
      loadingHistoryChart: false,
      states: [],
      filter: {
        state: "",
        startDate: "2023-01-01",
        endDate: "2023-12-31",
        customer_id: null,
      },
      seriesAbatement: abatementChart.seriesAbatement,
      chartOptionsAbatement: abatementChart.chartOptionsAbatement,

      seriesHistory: abatementChart.seriesHistory,
      chartOptionsHistory: abatementChart.chartOptionsHistory,

      lastUpdateAbatement: null,
    };
  },
  async mounted() {
    await this.fetchData();
    await this.fetchAbatementHistory();
  },
  methods: {
    fillForm() {
      this.loadingChart = true;
      this.seriesAbatement[0].data = [];
      this.seriesAbatement[1].data = [];
      this.seriesAbatement[2].data = [];
      this.chartOptionsAbatement.labels = [];
      this.abatementData.trends.forEach((value) => {
        this.chartOptionsAbatement.labels.push(value.year + "-" + value.month);
        this.seriesAbatement[0].data.push(value.cumulative_abatement_ex_month);
        this.seriesAbatement[1].data.push(value.abatement_);
        this.seriesAbatement[2].data.push(value.abatement_per_MWh);
      });
      setTimeout(() => {
        this.loadingChart = false;
      }, 300);
    },

    async fetchAbatementHistory() {
      let body = {
        customer_id: this.filter.customer_id,
        start_date: this.filter.startDate,
        end_date: this.filter.endDate,
        state: this.filter.state == "All" ? "" : this.filter.state,
      };
      this.loadingHistoryChart = true;
      await UserService.GetAbatementHistory(body)
        .then(
          (response) => {
            this.seriesHistory[0].data = [];
            if (response.data.abatement_history.length > 0)
              this.seriesHistory[0].data.push(
                response.data.abatement_history[0].total_credits
              );
            if (response.data.abatement_history.length > 3)
              this.seriesHistory[0].data.push(
                response.data.abatement_history[3].total_credits
              );
            if (response.data.abatement_history.length > 1)
              this.seriesHistory[0].data.push(
                response.data.abatement_history[1].total_credits
              );
            if (response.data.abatement_history.length > 2)
              this.seriesHistory[0].data.push(
                response.data.abatement_history[2].total_credits
              );
            this.lastUpdateAbatement = response.data.updated_at;

            setTimeout(() => {
              this.loadingHistoryChart = false;
            }, 300);
          },
          (error) => {
            this.loadingHistoryChart = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loadingHistoryChart = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loadingHistoryChart = false));
    },

    async fetchData() {
      let body = null;
      body = {
        customer_id: this.filter.customer_id,
        start_date: this.filter.startDate,
        end_date: this.filter.endDate,
        state: this.filter.state == "All" ? "" : this.filter.state,
      };
      await UserService.DashboardHomeData(body)
        .then(
          (response) => {
            this.states = response.data.states.map((session) => session.state);
            this.abatementData = response.data.summary;
            this.fillForm();
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    filterHandle(event) {
      this.filter = event;
      this.fetchData();
      this.fetchAbatementHistory();
    },
  },
};
</script>

<style></style>
