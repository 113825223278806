<template>
  <el-col :span="24">
    <div
      class="ma-0"
      v-loading="loading"
      :element-loading-spinner="svg"
      element-loading-svg-view-box="-10, -10, 50, 50"
      element-loading-background="rgba(122, 122, 122, 0.8)"
    >
      <el-table
        :data="userDataList"
        :fit="true"
        height="300"
        :row-class-name="tableRowClassName"
        @row-click="handleRowClick"
        class="my-2 data-table"
        scrollbar-always-on
      >
        <el-table-column
          v-if="$vuetify.display.width >= 500"
          type="expand"
          class="coloo"
        >
          <template #default="props">
            <div class="pa-5">
              <p class="mb-1" v-if="$vuetify.display.width <= 1200">
                <b>State :</b>
                {{ props.row.state == null ? "-" : props.row.state }}
              </p>
              <p class="mb-1" v-if="$vuetify.display.width <= 1200">
                <b>City :</b>
                {{ props.row.city == null ? "-" : props.row.city }}
              </p>
              <p class="mb-1" v-if="$vuetify.display.width <= 1200">
                <b>Suburb :</b>
                {{ props.row.suburb == null ? "-" : props.row.suburb }}
              </p>
              <p class="mb-1" v-if="$vuetify.display.width <= 1200">
                <b>Street :</b>
                {{ props.row.street == null ? "-" : props.row.street }}
              </p>
              <p class="mb-1">
                <b>Address Comment :</b>
                {{
                  props.row.address_comment == null
                    ? "-"
                    : props.row.address_comment
                }}
              </p>
              <p class="mb-1">
                <b>Description :</b> {{ props.row.description }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="'Customer'"
          :prop="'full_name'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Site Name'"
          :prop="'title'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Country'"
          :prop="'country'"
          :width="$vuetify.display.width <= 1600 ? 180 : ''"
          sortable
        />
        <el-table-column
          v-if="$vuetify.display.width > 1200"
          :label="'State'"
          :prop="'state'"
          :width="$vuetify.display.width <= 1600 ? 180 : ''"
          sortable
        />
        <el-table-column
          v-if="$vuetify.display.width > 1200"
          :label="'City'"
          :prop="'city'"
          :width="$vuetify.display.width <= 1600 ? 180 : ''"
          sortable
        />
        <el-table-column
          v-if="$vuetify.display.width > 1200"
          :label="'Suburb'"
          :prop="'suburb'"
          :width="$vuetify.display.width <= 1600 ? 180 : ''"
          sortable
        />
        <el-table-column
          v-if="$vuetify.display.width > 1200"
          :label="'Street'"
          :prop="'street'"
          :width="$vuetify.display.width <= 1600 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Postcode'"
          :prop="'postcode'"
          :width="$vuetify.display.width <= 1600 ? 150 : ''"
          sortable
        />
        <el-table-column
          :label="'Elec Region'"
          :prop="'elec_region'"
          :width="$vuetify.display.width <= 1600 ? 160 : ''"
          sortable
        />
        <el-table-column
          v-if="$vuetify.display.width < 500"
          :label="'Operations'"
          :prop="'operations'"
          align="center"
          width="250"
        >
          <template #default="scope">
            <el-button
              color="#0d3937"
              @click.stop="handleUpdate(scope.$index, scope.row)"
              size="small"
              class="me-primary-light"
            >
              <i class="fa fa-edit"></i>
              <span class="pl-1">
                {{
                  $store.state.auth.user != null &&
                  !$store.state.auth.user.is_auditor
                    ? "Update"
                    : "Show"
                }}
              </span>
            </el-button>
            <el-button
              v-if="
                $store.state.auth.user != null &&
                !$store.state.auth.user.is_auditor
              "
              color="#0d3937"
              @click.stop="handleDelete(scope.$index, scope.row)"
              size="small"
              class="me-primary-light"
            >
              <i class="fa fa-trash"></i>
              <span class="pl-1">Delete</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          fixed="right"
          :label="'Operations'"
          :prop="'operations'"
          align="center"
          width="250"
        >
          <template #default="scope">
            <el-button
              color="#0d3937"
              @click.stop="handleUpdate(scope.$index, scope.row)"
              size="small"
              class="me-primary-light"
            >
              <i class="fa fa-edit"></i>
              <span class="pl-1">
                {{
                  $store.state.auth.user != null &&
                  !$store.state.auth.user.is_auditor
                    ? "Update"
                    : "Show"
                }}
              </span>
            </el-button>
            <el-button
              v-if="
                $store.state.auth.user != null &&
                !$store.state.auth.user.is_auditor
              "
              color="#0d3937"
              @click.stop="handleDelete(scope.$index, scope.row)"
              size="small"
              class="me-primary-light"
            >
              <i class="fa fa-trash"></i>
              <span class="pl-1">Delete</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-row class="row-bg" justify="space-between">
        <el-col
          :span="5"
          class="d-flex justify-content-start align-items-center"
        >
          <el-select
            v-model="perPageCount"
            placeholder="Select"
            size="small"
            style="width: 70px"
          >
            <el-option label="10" :value="'10'" />
            <el-option label="25" :value="'25'" />
            <el-option label="50" :value="'50'" />
            <el-option label="100" :value="'100'" />
          </el-select>
        </el-col>
        <el-col
          :span="8"
          class="d-flex align-items-center justify-content-center"
        >
          <el-pagination
            small
            background
            layout="prev, pager, next"
            :total="total_items"
            v-model:page-size="page_size"
            v-model:current-page="current_page"
            @current-change="changePage"
            class="my-4"
          />
        </el-col>
        <el-col :span="5" class="d-flex justify-content-end align-items-center">
          <span class="text-white">
            {{ (current_page - 1) * perPageCount + 1 }}-{{
              current_page * perPageCount > total_items
                ? total_items
                : current_page * perPageCount
            }}
            of {{ total_items }}
          </span>
        </el-col>
      </el-row>
    </div>
  </el-col>
</template>

<script>
// import { ElMessageBox } from "element-plus";
// import ShowDetail from "./dialogDetail.vue";
// import UserService from "../../services/user.service";
export default {
  props: {
    loadingProp: {
      type: Boolean,
      default: false,
    },
    usersDataProp: {
      type: Array,
      default(rawProps) {
        return rawProps;
      },
    },
    totalRowProp: {
      type: Number,
    },
  },
  components: {
    // ShowDetail,
  },
  data() {
    return {
      show: true,
      userDataList: [],
      search: "",
      current_page: 1,
      page_size: 50,
      total_items: 100,
      perPageCount: 50,
      loading: false,
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      filterOpen: false,
    };
  },
  watch: {
    loadingProp: {
      handler: function (newValue) {
        this.loading = newValue;
      },
      deep: true,
    },
    usersDataProp: {
      handler: function (newValue) {
        this.userDataList = newValue;
      },
      deep: true,
    },
    totalRowProp: {
      handler: function (newValue) {
        this.total_items = newValue;
        console.log(this.total_items);
      },
      deep: true,
    },
    perPageCount: function (newVal) {
      this.current_page = 1;
      this.page_size = parseInt(newVal);
      this.$emit("tableAction", {
        index: this.page_size,
        data: null,
        kind: "perPageChange",
      });
    },
  },
  methods: {
    handleRowClick(row, column, event) {
      console.log(row, column, event);
    },
    handleUpdate(index, data) {
      this.$emit("tableAction", { index: index, data: data, kind: "edit" });
    },
    handleDelete(index, event) {
      this.$emit("tableAction", { index: index, data: event, kind: "delete" });
    },
    changePage(page) {
      this.$emit("tableAction", {
        index: page,
        data: null,
        kind: "changePage",
      });
    },
  },
};
</script>
