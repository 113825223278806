<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-dialog
    v-model="show"
    width="600"
    :fullscreen="$vuetify.display.width <= 600 ? true : false"
    class="public-style"
    destroy-on-close
    align-center
    @close="close"
  >
    <el-form
      ref="ruleFormRef"
      :model="baseline_data"
      :style="[$vuetify.display.width < 600 ? 'height: 550;' : '']"
      :label-position="`top`"
    >
      <el-space fill style="width: 100%">
        <el-alert type="info" class="alert-style" show-icon :closable="false">
          <p class="ma-0 text-white">Baseline Param Before Change :</p>
        </el-alert>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item class="label-style" prop="country" label="Country: ">
              <el-input v-model="baseline_data.country" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="charger_type"
              label="Charger Type: "
            >
              <!-- <el-input v-model="baseline_data.charger_type" /> -->
              <el-select
                v-model="baseline_data.charger_type"
                placeholder="Select"
                class="w-100"
              >
                <el-option
                  v-for="item in chargerTypes"
                  :key="item.id"
                  :label="item.title"
                  :value="item.title"
                >
                  <span style="float: left">{{ item.title }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="dispatch_int"
              label="Dispatch Int: "
            >
              <el-input v-model="baseline_data.dispatch_int" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="fuel_emission_intensity"
              label="Fuel Emission Intensity: "
            >
              <el-input v-model="baseline_data.fuel_emission_intensity" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="charger_efficiency"
              label="Charger Efficiency: "
            >
              <el-input v-model="baseline_data.charger_efficiency" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style" prop="afec" label="Afec: ">
              <el-input v-model="baseline_data.afec" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style" prop="mpg" label="Mpg: ">
              <el-input v-model="baseline_data.mpg" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="tech_improvement_rate"
              label="Tech Improvement Rate: "
            >
              <el-input v-model="baseline_data.tech_improvement_rate" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="fuel_energy_density_MJ_gal"
              label="Fuel Energy Density MJ Gal: "
            >
              <el-input v-model="baseline_data.fuel_energy_density_MJ_gal" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="fuel_energy_density_MJ_kWh"
              label="Fuel Energy Density MJ kWh: "
            >
              <el-input v-model="baseline_data.fuel_energy_density_MJ_kWh" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-space>
    </el-form>
    <!-- </el-row> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button
          size="medium"
          class="me-primary"
          @click.stop="close"
          color="#ddf0ac"
        >
          <i class="fa fa-times me-2 me-primary"></i> Cancel
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// import "animate.css";
// import moment from "moment";
export default {
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    data_modal: {
      type: Object,
      // eslint-disable-next-line no-unused-vars
      default(rawProps) {
        return rawProps;
      },
    },
  },
  data() {
    return {
      show: this.open_modal,
      baseline_data: {
        id: 0,
        country: "",
        dispatch_int: 0,
        charger_type: "AC",
        fuel_emission_intensity: 0.0,
        afec: 0.0,
        mpg: 0.0,
        charger_efficiency: 0.0,
        tech_improvement_rate: 0.0,
        fuel_energy_density_MJ_gal: 0.0,
        fuel_energy_density_MJ_kWh: 0.0,
      },
      chargerTypes: [
        { id: 1, title: "AC" },
        { id: 2, title: "DC" },
      ],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    open_modal: function (newVal, oldVal) {
      this.show = newVal;
      if (newVal == true) {
        this.baseline_data = JSON.parse(this.data_modal);
      }
    },
  },
  methods: {
    close() {
      this.show = false;
      this.$emit("close_modal", false);
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 30px 10px;
}
.el-input__inner {
  padding: 0 10px !important;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.el-form-item__label {
  font-size: 11px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.el-form-item__content > .el-checkbox {
  margin-top: 16px;
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
