<template>
  <el-col :span="24">
    <div class="ma-0" v-loading="loading">
      <el-table
        :data="dataList"
        :fit="true"
        height="300"
        class="my-2 width-100-p data-table"
        stripe
        scrollbar-always-on
      >
        <el-table-column
          :label="'Charging Site'"
          :prop="'title'"
          :width="$vuetify.display.width <= 1750 ? 250 : ''"
          sortable
        />
        <!-- <el-table-column
          :label="'Live emission intensity AC (tCO2e/MWh)'"
          :prop="'emission_intensity_ac'"
          :width="$vuetify.display.width <= 1750 ? 350 : ''"
          sortable
        /> -->
        <el-table-column
          :label="'Live emission intensity (tCO2e/MWh)'"
          :prop="'emission_intensity'"
          :width="$vuetify.display.width <= 1750 ? 350 : ''"
          sortable
        />
        <el-table-column
          :label="'Live Abatement AC (tCO2e/MWh)'"
          :prop="'abatement_ac'"
          :width="$vuetify.display.width <= 1750 ? 350 : ''"
          sortable
        />
        <el-table-column
          :label="'Live Abatement DC (tCO2e/MWh)'"
          :prop="'abatement_dc'"
          :width="$vuetify.display.width <= 1750 ? 350 : ''"
          sortable
        />
        <el-table-column
          :label="'Electricity Region'"
          :prop="'elec_region'"
          :width="$vuetify.display.width <= 1750 ? 300 : ''"
          sortable
        />
      </el-table>
      <el-row class="row-bg" justify="space-between">
        <el-col
          :span="5"
          class="d-flex justify-content-start align-items-center"
        >
          <el-select
            v-model="perPageCount"
            placeholder="Select"
            size="small"
            style="width: 70px"
          >
            <el-option label="10" :value="'10'" />
            <el-option label="25" :value="'25'" />
            <el-option label="50" :value="'50'" />
            <el-option label="100" :value="'100'" />
          </el-select>
        </el-col>
        <el-col
          :span="8"
          class="d-flex align-items-center justify-content-center"
        >
          <el-pagination
            small
            background
            layout="prev, pager, next"
            :total="total_items"
            v-model:page-size="page_size"
            v-model:current-page="current_page"
            @current-change="changePage"
            class="my-4"
          />
        </el-col>
        <el-col :span="5" class="d-flex justify-content-end align-items-center">
          <span class="text-white">
            {{ (current_page - 1) * perPageCount + 1 }}-{{
              current_page * perPageCount > total_items
                ? total_items
                : current_page * perPageCount
            }}
            of {{ total_items }}
          </span>
        </el-col>
      </el-row>
    </div>
  </el-col>
</template>

<script>
export default {
  props: {
    loadingProp: {
      type: Boolean,
      default: false,
    },
    dataProp: {
      type: Array,
      default(rawProps) {
        return rawProps;
      },
    },
    totalRowProp: {
      type: Number,
    },
  },
  components: {
    // ShowDetail,
  },
  data() {
    return {
      show: true,
      dataList: [],
      search: "",
      current_page: 1,
      page_size: 50,
      total_items: 100,
      perPageCount: 50,
      loading: false,
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      filterOpen: false,
    };
  },
  watch: {
    loadingProp: {
      handler: function (newValue) {
        this.loading = newValue;
      },
      deep: true,
    },
    dataProp: {
      handler: function (newValue) {
        this.dataList = newValue;
      },
      deep: true,
    },
    totalRowProp: {
      handler: function (newValue) {
        this.total_items = newValue;
        console.log(this.total_items);
      },
      deep: true,
    },
    perPageCount: function (newVal) {
      this.current_page = 1;
      this.page_size = parseInt(newVal);
      this.$emit("tableAction", {
        index: this.page_size,
        data: null,
        kind: "perPageChange",
      });
    },
  },
  methods: {
    handleRowClick(row, column, event) {
      console.log(row, column, event);
    },
    changePage(page) {
      this.$emit("tableAction", {
        index: page,
        data: null,
        kind: "changePage",
      });
    },
  },
};
</script>
