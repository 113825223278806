<template>
  <div class="container-fluid">
    <Breadcrumbs main="Sessions List" :path="breadcrumbPath" />
    <el-row class="m-0 width-100-p mt-3">
      <el-col class="d-flex align-item-center px-2" :span="24">
        <div class="row m-0 width-100-p px-2">
          <div
            class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
          >
            <el-col class="mb-2" :span="24">
              <div class="row m-0">
                <el-col
                  v-if="$vuetify.display.width < 700"
                  :span="24"
                  class="d-flex align-item-center"
                >
                  <el-button
                    color="#ddf0ac"
                    @click.stop="handleReload"
                    size="small"
                    class="height-40 width-40-p rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-refresh pr-2"></i>
                    <span class="me-primary"> Apply </span>
                  </el-button>
                  <el-button
                    class="height-40 width-20-p rounded-2 m-0 ml-2"
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
                <el-col
                  v-if="$vuetify.display.width < 700"
                  :span="24"
                  class="d-flex align-item-center mt-3"
                >
                  <el-input
                    size="large"
                    v-model="search"
                    placeholder="Type to search by site name, charger ID, port ..."
                    :prefix-icon="Search"
                    class="table-search"
                  />
                </el-col>
                <el-col
                  v-if="$vuetify.display.width >= 700"
                  :span="$vuetify.display.width >= 1200 ? 14 : 24"
                  class="d-flex align-item-center"
                  :class="
                    $vuetify.display.width < 750
                      ? 'x-center mt-2'
                      : 'justify-start'
                  "
                >
                  <el-input
                    size="large"
                    v-model="search"
                    placeholder="Type to search by site name, charger ID, port ..."
                    :prefix-icon="Search"
                    class="table-search"
                    :class="$vuetify.display.width >= 1200 ? 'width-400' : ''"
                  />
                  <el-button
                    color="#ddf0ac"
                    @click.stop="handleReload"
                    size="small"
                    class="height-40 rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-refresh pr-2"></i>
                    <span class="me-primary"> Apply </span>
                  </el-button>
                  <el-button
                    v-if="$vuetify.display.width < 1200"
                    class="height-40 rounded-2 m-0 ml-2"
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
                <el-col
                  :span="10"
                  v-if="$vuetify.display.width >= 1200"
                  class="d-flex align-item-center justify-end"
                >
                  <el-button
                    class="height-40 rounded-2 m-0 ml-2"
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
              </div>
            </el-col>

            <DataTable
              :loadingProp="loading"
              :usersDataProp="filter_session"
              :totalRowProp="total_items"
              @tableAction="handleTableAction"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <ShowDetail
      :open_modal="show_modal"
      :data_modal="data_modal"
      @close_modal="close_modal"
      @confirm_modal="confirm_modal"
    />
  </div>
</template>

<script>
import Breadcrumbs from "../../components/bread_crumbs.vue";
import XLSX from "xlsx";
import UserService from "../../services/user.service";
import DataTable from "./dataTable.vue";
import ShowDetail from "./dialogDetail.vue";
// import moment from "moment";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "users",
  components: {
    Breadcrumbs,
    DataTable,
    ShowDetail,
  },
  data() {
    return {
      breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "Sessions",
          disabled: false,
          href: "/sessions",
          active: true,
        },
      ],

      show: true,
      sessionDataList: [],
      search: "",
      current_page: 1,
      page_size: 50,
      total_items: 100,
      loading: false,
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      filterOpen: false,
      customers: [],
      filter: {
        customer_id: [],
        usage_KWh_from: null,
        usage_KWh_to: null,
        search: "",
      },
    };
  },
  watch: {},
  computed: {
    filter_session() {
      return this.sessionDataList;
    },
  },
  mounted() {
    this.fetchSessionData(this.current_page);
    // this.fetchCustomerData();
  },
  methods: {
    changePage(page) {
      this.fetchSessionData(page);
    },
    fetchSessionData(page) {
      this.filter.search = this.search;
      UserService.getSessions(this.filter, page)
        .then(
          (response) => {
            this.sessionDataList = response.data.sessions;
            this.total_items = response.data.total_record;
            // console.log(response);
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    handleTableAction(event) {
      if (event.kind == "edit") {
        // this.handleShow(event.index, event.data);
        this.handleUpdate(event.index, event.data);
      } else if (event.kind == "delete") {
        this.deleteRow(event.data, event.index);
      } else if (event.kind == "changePage") {
        this.current_page = event.index;
        this.fetchChargingData(this.current_page);
      } else if (event.kind == "perPageChange") {
        this.current_page = 1;
        this.perPageCount = event.index;
        this.handleReload();
      }
    },
    handleReload() {
      // this.filter = event.filter;
      // console.log(event);
      this.fetchSessionData(this.current_page);
    },
    handleExportFile() {
      if (this.filter_session.length > 0) {
        const myHeader = [];
        const ws = XLSX.utils.json_to_sheet(this.filter_session, {
          header: myHeader,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "SessionsList.xlsx");
      }
    },
  },
};
</script>

<style></style>
