<template>
  <div>
    <div class="container-fluid p-0">
      <!-- <div class="comingsoon auth-bg-video pa-0"> -->
      <!-- <video
          class="bgvideo-comingsoon"
          id="bgvid"
          poster="@/assets/images/other-images/coming-soon-bg-1.jpg"
          playsinline=""
          autoplay=""
          muted=""
          loop=""
        >
          <source src="@/assets/video/auth-bg-1.mp4" type="video/mp4" />
        </video> -->
      <div class="comingsoon-inner text-center">
        <div class="row">
          <div
            class="p-0 bg-me-primary"
            :class="$vuetify.display.width >= 1100 ? 'col-6' : 'col-12'"
          >
            <div class="login-card">
              <div>
                <div class="d-flex justify-content-center">
                  <a class="logo" style="height: 70px; width: 115px">
                    <img
                      class="img-fluid for-light"
                      src="../assets/images/logo/logo-light.png"
                      alt="loginpage"
                    />
                    <img
                      class="img-fluid for-dark"
                      src="../assets/images/logo/logo-dark.png"
                      alt="loginpage"
                    />
                  </a>
                </div>
                <div
                  class="login-main"
                  style="background-color: unset !important"
                >
                  <el-form
                    ref="ruleFormRef"
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    class="theme-form me-primary-light"
                    style="color: white !important"
                  >
                    <h4 class="mb-9">Sign in to your account</h4>
                    <el-form-item prop="username">
                      <label class="col-form-label">Email Address</label>
                      <el-input
                        size="large"
                        v-model="ruleForm.username"
                        placeholder=""
                      >
                      </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                      <label class="col-form-label">Password</label>
                      <el-input
                        v-model="ruleForm.password"
                        :type="showPass ? 'text' : 'password'"
                        size="large"
                      >
                      </el-input>
                      <div class="show-pass" @click="showPass = !showPass">
                        <!-- <span> show </span> -->
                        <i
                          v-if="!showPass"
                          class="fa fa-eye font-15-b me-primary"
                        ></i>
                        <i
                          v-else
                          class="fa fa-eye-slash font-15-b me-primary"
                        ></i>
                      </div>
                    </el-form-item>
                    <div class="form-group mb-0">
                      <div class="checkbox p-0 float-left">
                        <input id="checkbox1" type="checkbox" />
                        <label
                          class="text-muted text-white check-color"
                          for="checkbox1"
                          style="color: #ffffff !important"
                        >
                          Remember password
                        </label>
                      </div>
                      <router-link class="link text-white" :to="'/forgetPass'">
                        Forgot password?
                      </router-link>
                      <vue-hcaptcha
                        ref="hCaptcha"
                        theme="dark"
                        :sitekey="env.H_CAPTCHA_SITE_KEY"
                        @verify="onVerify"
                        @error="onError"
                        @expire="Expired"
                      ></vue-hcaptcha>

                      <div class="text-end mt-3">
                        <el-button
                          color="#0d3937"
                          class="btn-block w-100 btn-auth-primary-light"
                          @click.prevent="login('ruleFormRef')"
                          :loading="loading"
                        >
                          Sign in
                        </el-button>
                      </div>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 p-0" v-if="$vuetify.display.width >= 1100">
            <!-- <el-image
              class="width-100-p height-100-p"
              src="@/assets/images/login-bg-2.jpg"
              fit="cover"
            /> -->
            <img
              class="img-fluid for-light width-100-p height-100-p"
              src="../assets/images/login-bg-2.jpg"
              alt="loginpage"
            />
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import { useToast } from "vue-toastification";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "login",
  components: {
    VueHcaptcha,
  },
  data() {
    return {
      loading: false,
      snackbar: false,
      showPass: false,
      recaptchaValid: false,
      response: null,
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            type: "email",
            message: "Please enter valid email.",
            trigger: "blur",
          },
          {
            required: true,
            message: "Please enter your email.",
            trigger: "blur",
          },
        ],
        password: [
          {
            min: 6,
            message: "Password must be more than 6 characters",
            trigger: "blur",
          },
          {
            required: true,
            message: "Please enter your password.",
            trigger: "blur",
          },
        ],
      },
    };
  },

  created() {},

  methods: {
    async login(formName) {
      // console.log(this.env.H_CAPTCHA_SITE_KEY);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.response) {
            this.loading = true;
            this.$store.dispatch("auth/login", this.ruleForm).then(
              (resp) => {
                console.log(resp);
                this.$router.push({ path: "/dashboard" });
                this.loading = false;
              },
              (error) => {
                console.log(error);
                if (
                  error.user !== undefined &&
                  error.user === null &&
                  error.message !== undefined
                ) {
                  useToast().error(error.message, {
                    icon: "fa fa-times",
                    position: "bottom-right",
                    timeout: 4000,
                  });
                } else {
                  useToast().error("username or password is incorrect.", {
                    icon: "fa fa-times",
                    position: "bottom-right",
                    timeout: 4000,
                  });
                }
                this.loading = false;
                this.snackbar = true;
              }
            );
          }
        } else {
          return false;
        }
      });
    },
    onVerify(response) {
      this.response = response;
      // console.log("hCAPTCHA verified:", response);
    },
    onError() {
      this.response = null;
      console.log("hCAPTCHA Error");
    },
    onExpired() {
      this.response = null;
      console.log("hCAPTCHA expired");
    },
  },
};
</script>

<style>
.el-input__wrapper {
  padding: 1px !important;
  border-radius: 6px !important;
}

.el-input__wrapper > input {
  border-radius: 6px !important;
}

.el-input__suffix {
  position: absolute;
  right: 8px;
  /* top: 3px; */
}

.show-pass {
  position: absolute;
  top: 36px;
  right: 25px;
  color: var(--theme-deafult);
  cursor: pointer;
}

.login-card {
  background-image: none !important;
  min-height: none !important;
}

.login-main {
  background-color: unset !important;
  padding-top: 0px !important;
}

.check-color::before {
  color: black !important;
}
</style>
