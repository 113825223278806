export const abatementChart = {
    seriesAbatement: [{
            name: "Abatement (tCO2-e) - total",
            type: "column",
            data: [],
            color: "#78BE20",
        }, {
            name: "Abatement (tCO2-e) - month",
            type: "column",
            data: [],
            color: "#DDF0AC",
        },

        {
            name: "Abatement per MWh",
            type: "line",
            data: [],
            color: "#E73B30",
        },
    ],
    chartOptionsAbatement: {
        chart: {
            height: 350,
            type: "line",
            stacked: true,
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    position: "top",
                },
            },
        },
        stroke: {
            width: [0, 0, 4],
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [2],
        },
        labels: [],
        xaxis: {
            // type: "datetime",
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
                rotate: -45,
                hideOverlappingLabels: false,
            },
            // labelPlacement: 'onTicks',
        },
        yaxis: [{
                seriesName: 'Abatement (tCO2-e) - month',
                show: false,
            }, {
                seriesName: 'Abatement (tCO2-e) - total',
                decimalsInFloat: 2,
                title: {
                    text: "Abatement (tCO2-e)",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },

            {
                seriesName: 'Abatement per MWh',
                opposite: true,
                decimalsInFloat: 4,
                title: {
                    text: "Abatement per MWh",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            }
        ],
        // yaxis: ,
        legend: {
            position: "top",
            labels: {
                colors: "#FFFFFF",
            },
            markers: {
                fillColors: ["#78BE20", "#DDF0AC", "#E73B30"],
            },
        },
    },


    seriesHistory: [{
        // data: [400, 83, 93, 156],
        name: 'Value ',
        data: [0, 0, 0, 0],
    }, ],
    chartOptionsHistory: {
        chart: {
            type: "bar",
            height: 350,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                borderRadiusApplication: "end",
                horizontal: true,
                distributed: true,
            },
        },
        colors: ["#78BE20", "#DDF0AC", "#A6BBC8", "#F0EFEB"],
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: [
                "Current",
                "Under audit",
                "Issue requested",
                "Issued",
            ],
            labels: {
                style: {
                    colors: "#ffffff",
                },
            },
        },
        yaxis: {
            title: {
                text: "Number",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#ffffff",
                },
            },
        },
        legend: {
            show: false,
            position: "top",
            labels: {
                colors: "#FFFFFF",
            },
        },
        // tooltip: {
        //     y: {
        //         formatter: function (value) {
        //             return `${value}`; // فقط مقدار نمایش داده شود
        //         },
        //     },
        //     custom: function ({
        //         series,
        //         seriesIndex,
        //         dataPointIndex,
        //         w
        //     }) {
        //         const category = w.globals.labels[dataPointIndex];
        //         const value = series[seriesIndex][dataPointIndex];
        //         return `<div style="padding:5px; color: white; background: rgba(0, 0, 0, 0.7); border-radius: 5px;">
        //                 ${category}: ${value}
        //             </div>`;
        //         // return `<div class="apexcharts-tooltip apexcharts-theme-light apexcharts-active" style="left: 50.0781px; top: 206.118px;">
        //         //             <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"> ${category} </div>
        //         //             <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
        //         //                 <span class="apexcharts-tooltip-marker" style="background-color: rgba(240, 239, 235, 0.85);"></span>
        //         //                 <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
        //         //                     <div class="apexcharts-tooltip-y-group">
        //         //                         <span class="apexcharts-tooltip-text-y-label"> </span>
        //         //                         <span class="apexcharts-tooltip-text-y-value"> ${value} </span>
        //         //                     </div>
        //         //                     <div class="apexcharts-tooltip-goals-group">
        //         //                         <span class="apexcharts-tooltip-text-goals-label"></span>
        //         //                         <span class="apexcharts-tooltip-text-goals-value"></span>
        //         //                     </div>
        //         //                     <div class="apexcharts-tooltip-z-group">
        //         //                         <span class="apexcharts-tooltip-text-z-label"></span>
        //         //                         <span class="apexcharts-tooltip-text-z-value"></span>
        //         //                     </div>
        //         //                 </div>
        //         //             </div>
        //         //         </div>`;
        //     }
        // },

        // tooltip: {
        //     y: {
        //         formatter: function (value) {
        //             return `${value}`; // فقط مقدار نمایش داده شود
        //         },
        //     },
        //     custom: function ({
        //         series,
        //         seriesIndex,
        //         dataPointIndex,
        //         w
        //     }) {
        //         const category = w.globals.labels[dataPointIndex];
        //         const value = series[seriesIndex][dataPointIndex];
        //         return `<div style="padding:5px; color: white; background: rgba(0, 0, 0, 0.7); border-radius: 5px;">
        //                 ${category}: ${value}
        //             </div>`;
        //     }
        // },
    },
}