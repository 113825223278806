<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="header-wrapper row m-0 bg-me-primary text-white">
    <Logo />
    <div
      class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto"
    >
      <ul class="nav-menus">
        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Autoplay } from "swiper";
import "swiper/css";
import Profile from "./profile";
import Logo from "./logo.vue";
import UserService from "../../services/user.service";
export default {
  components: {
    Profile,
    Logo,
  },
  data() {
    return {
      bookmark: false,
      loading: false,
      customers: [],
      showModal: false,
      customerSelected: [],
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      searchOpen: (state) => state.menu.searchOpen,
    }),
  },
  mounted() {},
  methods: {
    search_open() {
      this.$store.state.menu.searchOpen = true;
    },
    bookmark_open() {
      this.bookmark = !this.bookmark;
    },
    async fetchCustomerData() {
      await UserService.getCustomers(null)
        .then(
          (response) => {
            this.customers = response.data.customers;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    filterConfirm(event) {
      this.$emit("filterConfirm", event);
    },
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>
