<template>
  <div>
    <div class="container-fluid p-0">
      <div class="comingsoon auth-bg-video pa-0">
        <video
          class="bgvideo-comingsoon"
          id="bgvid"
          poster="@/assets/images/other-images/coming-soon-bg-1.jpg"
          playsinline=""
          autoplay=""
          muted=""
          loop=""
        >
          <source src="@/assets/video/auth-bg-1.mp4" type="video/mp4" />
        </video>
        <div class="comingsoon-inner text-center">
          <div class="row">
            <div class="col-12 p-0">
              <div class="login-card">
                <div>
                  <div class="d-flex justify-content-center">
                    <a class="logo" style="height: 70px; width: 115px">
                      <img
                        class="img-fluid for-light"
                        src="../assets/images/logo/logo-light.png"
                        alt="looginpage"
                      />
                      <img
                        class="img-fluid for-dark"
                        src="../assets/images/logo/logo-dark.png"
                        alt="looginpage"
                      />
                    </a>
                  </div>
                  <div
                    class="login-main"
                    v-loading="loading"
                    element-loading-background="rgba(0, 0, 0, 0.78)"
                  >
                    <el-form
                      ref="ruleFormRef"
                      :model="ruleForm"
                      status-icon
                      :rules="rules"
                      class="theme-form me-primary-light"
                    >
                      <h4 class="pt-5">Reset Password</h4>
                      <p class="me-primary-light">
                        Enter New Password to reset password
                      </p>
                      <el-form-item prop="password">
                        <label class="col-form-label"> New Password </label>
                        <el-input
                          v-model="ruleForm.password"
                          :type="showPass ? 'text' : 'password'"
                          size="large"
                        >
                        </el-input>
                        <div class="show-pass" @click="showPass = !showPass">
                          <span> show </span>
                        </div>
                      </el-form-item>
                      <el-form-item prop="confirm_password">
                        <label class="col-form-label"> Retype Password </label>
                        <el-input
                          v-model="ruleForm.confirm_password"
                          :type="showRePass ? 'text' : 'password'"
                          size="large"
                        >
                        </el-input>
                        <div
                          class="show-pass"
                          @click="showRePass = !showRePass"
                        >
                          <span> show </span>
                        </div>
                      </el-form-item>
                      <div class="form-group mb-0">
                        <div class="text-end mt-3">
                          <el-button
                            color="#0d3937"
                            class="btn-block w-100 me-primary-light"
                            @click.prevent="submit('ruleFormRef')"
                          >
                            Done
                          </el-button>
                        </div>
                      </div>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "resetPass",
  components() {},
  data() {
    return {
      loading: false,
      snackbar: false,
      showPass: false,
      showRePass: false,
      // uid: "",
      token: "",
      ruleForm: {
        password: "",
        confirm_password: "",
        // uid: "",
        token: "",
      },
      rules: {
        password: [
          {
            min: 6,
            message: "Password must be more than 6 characters",
            trigger: "blur",
          },
          {
            required: true,
            message: "Please enter your password.",
            trigger: "blur",
          },
        ],
        confirm_password: [
          {
            min: 6,
            message: "Password must be more than 6 characters",
            trigger: "blur",
          },
          {
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (value === "") {
                  reject("Please enter your password.");
                }
                // console.log(this.ruleForm.password);
                // console.log(value);
                if (value !== this.ruleForm.password) {
                  reject("Two inputs don't match!");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
    };
  },

  mounted() {
    // this.ruleForm.uid = this.$route.query.uid;
    this.ruleForm.token = this.$route.query.token;
    console.log(this.ruleForm);
  },

  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid && this.ruleForm.uid !== "" && this.ruleForm.token !== "") {
          console.log(this.ruleForm);
          this.loading = true;
          this.$store.dispatch("auth/resetPass", this.ruleForm).then(
            (resp) => {
              console.log(resp);
              this.$router.push({ path: "/" });
              this.loading = false;
            },
            (error) => {
              console.log(error);
              this.loading = false;
              this.snackbar = true;
              useToast().error("An error has occurred.", {
                icon: "fa fa-times",
                position: "bottom-right",
                timeout: 2000,
              });
            }
          );
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
.el-input__wrapper {
  padding: 1px !important;
  border-radius: 6px !important;
}

.el-input__wrapper > input {
  border-radius: 6px !important;
}

.el-input__suffix {
  position: absolute;
  right: 8px;
  /* top: 3px; */
}

.show-pass {
  position: absolute;
  top: 36px;
  right: 25px;
  color: var(--theme-deafult);
  cursor: pointer;
}

.login-card {
  background-image: none !important;
  min-height: none !important;
}

.login-main {
  background-color: #00000078 !important;
}
</style>
