// axios.js

import axios from 'axios';
import {
    API_BASE_URL,
    API_KEY
} from '../../env';

// Create an instance of Axios
const axiosInstance = axios.create({
    baseURL: API_BASE_URL, // Your API base URL
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        // Add custom headers to the request
        config.headers['Authorization'] = "JWT " + JSON.parse(localStorage.getItem("token"));
        config.headers['x-api-key'] = API_KEY;
        return config;
    },
    function (error) {
        // Handle request errors
        return Promise.reject(error);
    }
);

export default axiosInstance;